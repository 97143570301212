import React from 'react';

/**
 * ImageContainer is a functional React component that renders an image inside a div container.
 * @param {Object} props - The component props.
 * @param {string} props.imageSrc - The source URL of the image to be displayed.
 * @returns {JSX.Element} The rendered image container component.
 */
const ImageContainer = ({ imageSrc }) => {
  return (
    <div className="feedback-img-container-comp">
        <img src={imageSrc } alt="Reviews" />
    </div>
  );
};

export default ImageContainer;