import React from "react";
import "../Styles/AskStaffy/AskStaffy.css";
import ChatComponent from "./ChatComponent";

const AskStaffy = () => {
  return (
    <div className="ask-staffy-container">
      <div className="ask-staffy-main">
        {/* Chat Component */}
        <ChatComponent />
      </div>
    </div>
  );
};

export default AskStaffy;