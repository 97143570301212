import React, { useState, useEffect } from "react";
import "../Styles/OnBoarding.css";
import Icon from "./Icon.jsx";
import Card from "./Card.jsx";
import Button from "./Button.jsx";
import Text from "./Text.jsx";
import { useNavigate } from "react-router-dom";
import { sendReviewRequest } from "../../apiControllers/feedbackApiController.js";
import OnboardingReferenceRow from "./OnboardingReferenceRow.jsx";

/**
 * StepThree component for rendering the third step of the onboarding process.
 *
 * @component
 * @example
 * return (
 *   <StepThree />
 * )
 */
function StepThree() {
  const relationshipOptions = [
    { label: "Peer", value: "peer" },
    { label: "Direct Report", value: "direct_report" },
    { label: "Manager", value: "manager" },
    { label: "Teammate", value: "teammate" },
    { label: "Professor", value: "professor" },
  ];

  const [references, setReferences] = useState([
    { name: "", email: "", relationship: "" },
    { name: "", email: "", relationship: "" },
  ]);
  const [errors, setErrors] = useState([]);
  const [generalError, setGeneralError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const navigate = useNavigate();

  /**
   * Handles input changes and updates the reference data state.
   *
   * @param {number} index - The index of the current reference row.
   * @param {string} name - The name of the input field.
   * @param {string} value - The value of the input field.
   */
  const handleInputChange = (index, name, value) => {
    setReferences((prev) => {
      const updated = [...prev];
      updated[index][name] = value;
      return updated;
    });

    setErrors((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: "" };
      return updated;
    });
  };

  useEffect(() => {
    const areFieldsFilled = references
      .slice(0, 1)
      .every(
        (reference) =>
          reference.name && reference.email && reference.relationship
      );
    setIsSubmitDisabled(!areFieldsFilled);
  }, [references]);

  /**
   * Handles the click event to add a new reference row.
   */
  const handleAddClick = () => {
    if (references.length < 5) {
      setReferences((prev) => [
        ...prev,
        { name: "", email: "", relationship: "" },
      ]);
      setErrors((prev) => [...prev, {}]);
    }
  };

  /**
   * Handles the click event to remove a reference row.
   *
   * @param {number} index - The index of the reference row to remove.
   */
  const handleRemoveClick = (index) => {
    setReferences((prev) => prev.filter((_, i) => i !== index));
    setErrors((prev) => prev.filter((_, i) => i !== index));
  };

  /**
   * Validates the email address format.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} - Returns true if the email format is valid, false otherwise.
   */
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  /**
   * Handles the form submission to send review requests.
   */
  const handleSubmit = async () => {
    let hasError = false;

    const newErrors = references.map((reference, index) => {
      if (!isValidEmail(reference.email)) {
        hasError = true;
        return { email: "Invalid email address" };
      }
      return {};
    });

    setErrors(newErrors);

    if (!hasError) {
      for (let reference of references) {
        const payload = {
          source: "test",
          source_email: localStorage.getItem("userEmail"),
          destination: reference.email,
          relationship: reference.relationship,
          fullname: reference.name,
        };
        const response = await sendReviewRequest(payload);
        if (response) {
          navigate("/dashboard");
        } else {
          setGeneralError(
            "An error occurred while sending the review request. Please try again."
          );
        }
      }
    } else {
      setGeneralError(""); // Clear general error if email validation fails
    }
  };

  const handleSkip = () => navigate("/dashboard")

  return (
    <div className="onboarding-container">
      <Card>
        <div className="onboarding-icon-text">
          <Icon type={"mail"} />
          <div className="onboarding-card-title">Send Invites</div>
        </div>
        <Text text="Get started by sending your first two requests!" />

        {references.map((reference, index) => (
          <OnboardingReferenceRow
            key={index}
            index={index}
            reference={reference}
            handleInputChange={handleInputChange}
            handleRemoveClick={handleRemoveClick}
            relationshipOptions={relationshipOptions}
            showRemoveButton={references.length > 2}
            errorMessage={errors[index] ? errors[index].email : ""}
          />
        ))}
        {generalError && (
          <div className="general-error-message">{generalError}</div>
        )}
        <div className="onboarding-icon-text">
          <Button
            onClick={handleAddClick}
            label={
              <>
                <Icon type={"addPerson"} /> Add another person
              </>
            }
          />
        </div>
        <div className="onboarding-actions" id="profile">
          <Button
            onClick={handleSubmit}
            label="Send Requests"
            disabled={isSubmitDisabled}
          />
          <a
            href="/dashboard"
            onClick={handleSkip}
            className="onboarding-skip-link"
          >
            <Text text="Skip" />
          </a>
        </div>
      </Card>
    </div>
  );
}

export default StepThree;
