import React from 'react';

const styles = {
  Icon: {
    color: '#4b4e56',
    fill: '#4b4e56',
    fontSize: '29px',
    top: '619px',
    left: '1303px',
    width: '29px',
    height: '21px',
  },
};

/**
 * IconComponent renders the SVG for the crossed eye icon.
 *
 * @returns {JSX.Element} The SVG element representing the crossed eye icon.
 */
const IconComponent = () => (
  <div className="icon">
    <svg style={styles.Icon}  viewBox="0 0 24 24">
        <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none">
        </path>
        <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46A11.804 11.804 0 0 0 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z">
        </path>
    </svg>
</div>
);

/**
 * Default props for CrossedEyeIcon.
 */
const defaultProps = {
  IconComponent,
};

/**
 * CrossedEyeIcon component renders the crossed eye icon.
 *
 * @param {Object} props - The props passed to the component.
 * @param {JSX.Element} [props.IconComponent] - Optional custom icon component.
 * @returns {JSX.Element} The CrossedEyeIcon component.
 */
const CrossedEyeIcon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent style={styles.Icon} /> 
      : <defaultProps.IconComponent />
  );
};

export default CrossedEyeIcon;