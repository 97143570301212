import React from "react";
import { Link } from "react-router-dom";

/**
 * Renders a component that displays a success message after a password reset.
 *
 * @return {JSX.Element} The rendered component.
 */
const PasswordResetSuccess = () => {
  return (
    <>
      <div className="password-reset-container">
        <div className="password-reset-form-box">
          <span className="password-reset-header">Success!</span>
          <form
            className="password-reset-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="password-reset-message">
              <span>Password changed successfully.</span>
            </div>
            <div className="password-reset-link-login-link">
              <Link className="password-reset-login-link-text" to="/login">
                <span>Back to login</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordResetSuccess;
