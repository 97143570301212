import React from "react";

/**
 * Text component for rendering text within a div container.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.text - The text to be displayed.
 * @example
 * return (
 *   <Text text="Welcome to the onboarding process!" />
 * )
 */
const Text = ({ text }) => <div className="onboarding-text">{text}</div>;

export default Text;
