/**
 * This module is for managing API calls to the server's suggestions endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Fetch suggestions for a user based on their email address.
 *
 * @param {string} email_address - The email address of the user.
 * @returns {Promise<any>} - The suggestions data or an error message.
 */
export const getSuggestions = async (email_address) => {
  try {
    const response = await api.get(
      `${apiUrl}/v1/suggestions/user/${email_address}`
    );

    // Handle the response based on the status and data
    if (response.status === 200) {
      if (Array.isArray(response.data) && response.data.length === 0) {
        return "No courses were found for the current summary";
      }
      return response.data;
    }
  } catch (error) {
    // Handle 404 errors with specific messages
    if (error.response && error.response.status === 404) {
      return error.response.data.detail || "Error: Resource not found";
    }

    // Handle any other errors that occur during the request
    if (error.response && error.response.data && error.response.data.detail) {
      return error.response.data.detail;
    }

    return "An error occurred while fetching data.";
  }
};

/**
 * Mark a course suggestion as unwanted
 * 
 * @param {string} courseId - The ID of the course to mark as unwanted
 * @returns {Promise<any>} - Success message or error
 */
export const markCourseUnwanted = async (courseId) => {
  try {
    const response = await api.patch(
      `${apiUrl}/v1/suggestions/${courseId}/unwanted`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    // Handle 404 errors
    if (error.response && error.response.status === 404) {
      return error.response.data.detail || "Error: Course not found";
    }

    // Handle other errors
    if (error.response && error.response.data && error.response.data.detail) {
      return error.response.data.detail;
    }

    return "An error occurred while marking course as unwanted.";
  }
};

/**
 * Undo the unwanted status of a course suggestion
 * 
 * @param {string} courseId - The ID of the course to undo unwanted status
 * @returns {Promise<any>} - Success message or error
 */
export const undoUnwantedCourse = async (courseId) => {
  try {
    const response = await api.patch(
      `${apiUrl}/v1/suggestions/${courseId}/undo-unwanted`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status === 404) {
      return error.response.data.detail || "Error: Course not found";
    }
    return "An error occurred while undoing unwanted status.";
  }
};

/**
 * Submit feedback for a course suggestion
 * 
 * @param {string} courseId - The ID of the course to submit feedback for
 * @param {string} feedback - The feedback to submit
 * @returns {Promise<any>} - Success message or error
 */
export const submitCourseFeedback = async (courseId, feedback) => {
  try {
    const response = await api.post(
      `${apiUrl}/v1/suggestions/${courseId}/feedback`,
      { feedback }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response?.status === 404) {
      return error.response.data.detail || "Error: Course not found";
    }
    return "An error occurred while submitting feedback.";
  }
};
