import React from "react";

const GoalsImage = () => {
  return (
    <>
      <div className="goals-img-container">
        <img
          src="https://assets.api.uizard.io/api/cdn/stream/53e26f59-c725-4d7d-9e4c-b25d782d9764.png"
          alt="goals-cover"
        />
      </div>
    </>
  );
};

export default GoalsImage;
