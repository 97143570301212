import React, { useState, useRef, useEffect } from "react";
import "../../Styles/GrowthLibrary.css";
import spotifyImg from "../assets/spotify.png";
import udemyImg from "../assets/udemy.png";
import mavenImg from "../assets/maven.png";
import noPhotoImg from "../assets/noPhotoImg.png"; 
import strings from "../assets/strings.json"

const SingleCard = ({ title, price, link, source, imgSrc, courseId, onMarkUnwanted, isMarked, onUndo, onSubmitFeedback }) => {
  /**
   * SingleCard component renders a single card with a course's details.
   *
   * @param {string} title - The title of the course.
   * @param {string} price - The price of the course.
   * @param {string} link - The URL link to the course.
   * @param {string} source - The source of the course (e.g., Udemy, Spotify, Maven).
   * @param {string} imgSrc - The URL of the course's image.
   *
   * @returns {JSX.Element} The SingleCard component.
   */

  // Map the source to the corresponding icon image
  const iconSrc = source === "Udemy" ? udemyImg :
                  source === "Spotify" ? spotifyImg :
                  source === "Maven" ? mavenImg : noPhotoImg;
  
  // Fallback image if imgSrc is not provided or is an empty string
  const imageSource = imgSrc && imgSrc.trim() !== "" ? imgSrc : noPhotoImg;
  
    // State to track if the dropdown is open
  const [isOpen, setIsOpen] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  // Reference to close the dropdown if clicked outside
  const menuRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handler to open the link in a new tab
  const handleButtonClick = () => {
    window.open(link, "_blank");
  };

  const handleMarkUnwanted = async () => {
    try {
      await onMarkUnwanted(courseId);
      setIsOpen(false);
    } catch (error) {
      console.error('Error marking course as unwanted:', error);
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await onSubmitFeedback(courseId, feedback);
      setIsSubmitted(true);
      // Show thank you message for 2 seconds then close
      setTimeout(() => {
        setIsFeedback(false);
        setIsSubmitted(false);
        setFeedback('');
        setIsOpen(false);
      }, 2000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  // Early return if marked as unwanted
  if (isMarked) {
    return (
      <div className="growth-library-card marked-unwanted">
        <div className="unwanted-message">
          <span className="unwanted-text">{strings.card.unwanted.message}</span>
          <button 
            className="undo-button"
            onClick={() => onUndo(courseId)}
          >
            {strings.card.unwanted.undoButton}
          </button>
        </div>
      </div>
    );
  }

  // Early return for feedback mode
  if (isFeedback) {
    return (
      <div className="growth-library-card feedback-mode">
        <div className="feedback-message">
          {isSubmitted ? (
            <span className="feedback-thank-you">{strings.card.feedback.thankYouMessage}</span>
          ) : (
            <>
              <span className="feedback-text">{strings.card.feedback.title}</span>
              <textarea
                className="feedback-input"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder={strings.card.feedback.placeholder}
                required
              />
              <div className="feedback-buttons">
                <button 
                  className="submit-button"
                  onClick={handleFeedbackSubmit}
                  disabled={!feedback.trim()}  // Disable if empty or only whitespace
                >
                  {strings.card.feedback.submitButton}
                </button>
                <button 
                  className="cancel-button"
                  onClick={() => {
                    setIsFeedback(false);
                    setIsOpen(false);
                  }}
                >
                  {strings.card.feedback.cancelButton}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="growth-library-card">
      <div className="growth-card-header">
      <div className="growth-card-ellipsis" ref={menuRef}>
        <button aria-haspopup="true" aria-controls="menu" className="ellipsis-button" onClick={toggleDropdown}> ⋮ </button>
          {isOpen && (
            <div role="menu" id="menu" tabIndex="-1" className="custom-dropdown">
              <div 
                role="menuitem" 
                tabIndex="-1" 
                className="dropdown-item"
                onClick={handleMarkUnwanted}
              > 
                {strings.card.dropdownMenu.option1} 
              </div>
              <div 
                role="menuitem" 
                tabIndex="-1" 
                className="dropdown-item"
                onClick={() => setIsFeedback(true)}
              > 
                {strings.card.dropdownMenu.option2} 
              </div>
            </div> 
          )}
      </div>
        <div className="growth-card-image-container">
          <img className="growth-card-image" src={imageSource} alt={strings.card.altText.courseImage} />
        </div>
      </div>
      <div className="growth-card-content">
        <h3 className="growth-card-title">{title}</h3>
        <div className="growth-card-footer">
          <span className="growth-card-price">{price}</span>
          <img className="growth-card-icon" src={iconSrc} alt={strings.card.altText.sourceIcon} />
          <button className="growth-card-button" onClick={() => window.open(link, "_blank")}>
            {strings.card.buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleCard;
