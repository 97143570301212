import React from "react";

/**
 * OpenEndedQuestion component renders a text area for open-ended questions.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.question - The question object.
 * @param {number} props.displayIndex - The index to display for the question.
 * @param {number} props.questionIndex - The index of the question in the payload array.
 * @param {Array} props.payload - The current payload array.
 * @param {function} props.setPayload - The function to update the payload array.
 * @param {function} props.checkQuestionOrder - The function to determine the question order type.
 *
 * @returns {JSX.Element} The OpenEndedQuestion component.
 */
const OpenEndedQuestion = ({
  question,
  displayIndex,
  questionIndex,
  payload,
  setPayload,
  checkQuestionOrder,
}) => {
  const displayText =
    question.sub_sub_question || question.sub_question || question.question;

  /**
   * Updates the payload array with the response to the open-ended question.
   *
   * @param {Event} event - The event triggered by changing the text area.
   */
  const openUpdatePayload = (event) => {
    const text = event.target.value;
    let updatedPayload = [...payload];
    let updatedPayloadElement = updatedPayload[questionIndex];
    updatedPayloadElement = [...updatedPayloadElement];

    switch (checkQuestionOrder(question)) {
      case "order":
        updatedPayloadElement = [text];
        break;
      case "order_sub":
        updatedPayloadElement[1] = text;
        break;
      case "order_sub_sub":
        // [0, 1, 2, 3, 4*, 5, 6*, 7 8*]
        updatedPayloadElement[question.order_sub_question * 2 + 3 - 1] = text;
        break;
      default:
        break;
    }

    updatedPayload[questionIndex] = updatedPayloadElement;
    setPayload(updatedPayload);
  };

  /**
   * Handles the change event for the text area.
   *
   * @param {Event} event - The event triggered by changing the text area.
   */
  const handleTextareaChange = (event) => {
    // API Payload Handler
    openUpdatePayload(event);
  };

  return (
    <div
      className={`${
        displayIndex === null ? "sub-question" : "survey-question"
      }`}
    >
      <p>
        {displayIndex !== null ? `${displayIndex}. ` : ""}
        {displayText}
      </p>
      <textarea rows="2" cols="50" onChange={handleTextareaChange}></textarea>
    </div>
  );
};

export default OpenEndedQuestion;
