import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Signs up a new user with the provided user data.
 *
 * @param {Object} userData - The user data for signup.
 * @returns {Object} - The response data from the signup request.
 * @throws {Error} - If there's an error during signup.
 */
export const signup = async (userData) => {
  try {
    const response = await api.post(`${apiUrl}/v1/user/signup`, userData);

    Cookies.set("userToken", response.data.token, { expires: 1 / 12 });
    return response.data;
  } catch (error) {
    console.error("Error during signup:", error);
    throw error;
  }
};

/**
 * Logs in a user with the provided user data.
 *
 * @param {Object} userData - The user data for login.
 * @returns {Object} - The response data from the login request.
 * @throws {Error} - If there's an error during login.
 */
export const login = async (userData) => {
  try {
    const response = await api.post(`${apiUrl}/v1/user/login`, userData);

    Cookies.set("userToken", response.data.token, { expires: 1 / 12 });
    localStorage.setItem("userId", response.data.user.user_id);
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

/**
 * Checks if a given token is expired.
 *
 * @param {string} token - The JWT token to check.
 * @returns {boolean} - True if the token is expired or invalid, false otherwise.
 */
export const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};

/**
 * Retrieves the stored JWT token from cookies.
 *
 * @returns {string|null} - The JWT token if it exists, null otherwise.
 */
export const getToken = () => {
  return Cookies.get("userToken");
};

/**
 * Logs out the user by removing the JWT token and reloading the page.
 */
export const logout = () => {
  Cookies.remove("userToken");
  window.location.reload();
};
