import React, { useCallback, useState, useEffect } from "react";

const GoalInfoSection = ({ goalData, completionPercentage }) => {
  const calculateDaysLeft = (deadline) => {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    const timeDifference = deadlineDate.getTime() - today.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  };

  const getCircleClass = (percentage) => {
    if (percentage < 40) {
      return "circle-low";
    } else if (percentage < 74) {
      return "circle-medium";
    } else {
      return "circle-high";
    }
  };

  if (!goalData) return <p>Loading goal data...</p>;

  const daysLeft = calculateDaysLeft(goalData.deadline);

  return (
    <div className="goal-header">
      <div className="goal-info">
        <div className={`goal-percentage-circle ${getCircleClass(completionPercentage)}`}>
          {completionPercentage}%
        </div>
        <div>
          <span className="goal-title">GOAL: {goalData.title}</span>
          <p>Description: {goalData.description}</p>
          <p>Deadline: {goalData.deadline} ({daysLeft} days left)</p>
        </div>
      </div>
    </div>
  );
};

export default GoalInfoSection;