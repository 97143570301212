/**
 * This module is for managing API calls to the server's goals endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const createGoal = async (goalData) => {
  try {
    const response = await api.post(`${apiUrl}/v1/goals/single`, goalData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGoalsByUserId = async (userId) => {
  try {
    const response = await api.get(`${apiUrl}/v1/goals/all/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGoalById = async (goalId) => {
  try {
    const response = await api.get(`${apiUrl}/v1/goals/single/${goalId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGoal = async (goalId, goalData) => {
  try {
    const response = await api.put(
      `${apiUrl}/v1/goals/single/${goalId}`,
      goalData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGoal = async (goalId) => {
  try {
    const response = await api.delete(`${apiUrl}/single/${goalId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
