import React, { useState } from "react";
import { createGoal } from "../../apiControllers/goalsApiController";
import GoalsScale from "./GoalsScale";

const GoalsCreation = ({ user }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const [scale, setScale] = useState("");
  const [error, setError] = useState("");

  // Create goal
  const handleCreate = async () => {
    if (
      !title.trim() ||
      !description.trim() ||
      !deadline.trim() ||
      !scale.trim()
    ) {
      setError("Please fill out all fields.");
      return;
    }

    const goalData = {
      title,
      description,
      deadline,
      status: "Start pending",
      user_id: user,
      notes: "empty notes",
      scale,
    };
    try {
      await createGoal(goalData);
      window.location.href = window.location.href;
    } catch (error) {
      setError("Failed to create goal. Please try again.");
    }
  };

  // Date input event handlers

  const handleFocus = (e) => {
    e.target.type = "date";
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.type = "text";
    }
  };

  return (
    <>
      <div className="goals-creation-container">
        <input
          className="goals-text-input"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          className="goals-text-input goals-description-input"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className="goals-text-input date-input"
          type="text"
          placeholder="deadline (dd/mm/yyyy)"
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={deadline}
          onChange={(e) => setDeadline(e.target.value)}
        />
        <GoalsScale setScale={setScale} />
        <div>
          {" "}
          <button className="create-goals-button" onClick={handleCreate}>
            Create Goal
          </button>
          {error && <span className="goals-error-text">{error}</span>}
        </div>
      </div>
    </>
  );
};

export default GoalsCreation;
