import React, { useState, useEffect } from "react";
import "../../Styles/SurveyForm.css";
import LikertScaleQuestion from "./LikertScaleQuestion";
import OrdinalRankingQuestion from "./OrdinalRankingQuestion";
import OpenEndedQuestion from "./OpenEndedQuestion";
import { SelectedOptionsProvider } from "./SelectedOptionsProvider";
import {
  getSelfReviewSurvey,
  submitReview,
  getReviewerSurvey,
} from "../../../apiControllers/feedbackApiController";
import strings from "../Resources/strings.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getReviewById } from "../../../apiControllers/reviewApiController";

/**
 * Parses and sorts survey data based on various order categories.
 *
 * @param {Object} data - The raw survey data.
 * @returns {Object[]} Sorted questions array.
 */
const parseSurveyData = (data) => {
  const sortedQuestions = data.questions.sort((a, b) => {
    return (
      a.order_category - b.order_category ||
      a.order_sub_category - b.order_sub_category ||
      a.order_sub_sub_category - b.order_sub_sub_category ||
      a.order_question - b.order_question ||
      a.order_sub_question - b.order_sub_question ||
      a.order_sub_sub_question - b.order_sub_sub_question
    );
  });
  return sortedQuestions;
};

/**
 * SelfAssessmentForm component renders the self-assessment survey form.
 *
 * @returns {JSX.Element} The SelfAssessmentForm component.
 */
const SelfAssessmentForm = ({ type }) => {
  const [questions, setQuestions] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [payload, setPayload] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formIsValid, setFormIsValid] = useState(false);
  const [expectedAnswersCount, setExpectedAnswersCount] = useState(0);
  const navigate = useNavigate();

  /**
   * Fetches the self-review survey data and initializes the questions state.
   */
  useEffect(() => {
    const fetchQuestionnaire = async () => {
      try {
        let response;
        switch (type) {
          case "self":
            response = await getSelfReviewSurvey();
            break;
          case "peer":
            response = await getReviewerSurvey();
            response.questions.map((question) => {
              let questionText = question.question;
              let questionSubText = question.sub_question;

              questionText = questionText.replace(
                /\[reviewee\]/g,
                searchParams.get("reviewee")
              );

              if (questionSubText) {
                questionSubText = questionSubText.replace(
                  /\[reviewee\]/g,
                  searchParams.get("reviewee")
                );
              }

              question.question = questionText;
              question.sub_question = questionSubText;
            });
            break;
          default:
            break;
        }
        const sortedQuestions = parseSurveyData(response);

        setQuestions(sortedQuestions);
        setData(response);

         // Calculate expected answers count
        let count = 0;
        sortedQuestions.forEach((question) => {
          if (question.sub_question === null && question.sub_sub_question === null) count += 1; // For the main question
          if (question.sub_question && question.sub_sub_question == null) count += 1;
          if (question.sub_sub_question) count += 1;
          if (question.type === "ordinal_ranking") count += 2; 
        });
        setExpectedAnswersCount(count);
      } catch (error) {
        console.error("Error fetching questionnaire:", error);
      } finally {
        setLoading(false);
      }
      setDataLoaded(true);
    };

    if (!dataLoaded) {
      fetchQuestionnaire();
    }

    if (dataLoaded & (payload.length === 0)) {
      // Populate payload array with empty arrays.
      let payloadLength = 0;

      questions.map((question) => {
        if (
          question.sub_question === null &&
          question.sub_sub_question === null
        ) {
          actualQuestionIndex++;
          payloadLength++;
        }
      });

      setPayload([...Array(payloadLength).fill([]), ...payload.slice(56)]);
    }
  }, [dataLoaded, payload]);

  useEffect(() => {
  const checkFormValidity = () => {
    const isValidItem = (item) => {
      if (typeof item === "string") {
        return item.trim() !== "";
      }
      return item !== undefined && item !== null && item !== "";
    };

    const validItemsCount = payload.reduce((count, response) => {
      if (Array.isArray(response) && response.length > 0) {
        return count + response.filter(isValidItem).length;
      }
      return count;
    }, 0);

    setFormIsValid(validItemsCount === expectedAnswersCount);
  };

  checkFormValidity();
}, [payload, expectedAnswersCount]);



  let actualQuestionIndex = -1;
  let displayIndex = 1;
  const displayedCategories = new Set();
  const displayedSubCategories = new Set();
  const displayedSubSubCategories = new Set();

  /**
   * Handles form submission, sends the payload to the API, and navigates to the feedback page on success.
   *
   * @param {Event} event - The form submit event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      let response = await submitReview(
        searchParams.get("reviewId"),
        payload,
        questions,
        type
      );
      if (response) {
        switch (type) {
          case "self":
            navigate("/feedback");
            break;
          case "peer":
            navigate(-2);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <div>{strings.loadingMessage}</div>;
  }

  /**
   * Checks the order of the question to determine its category.
   *
   * @param {Object} question - The question object.
   * @returns {string} The order type of the question.
   */
  const checkQuestionOrder = (question) => {
    if (question.order_sub_sub_question) {
      return "order_sub_sub";
    }

    if (question.order_sub_question) {
      return "order_sub";
    }
    return "order";
  };

  /**
   * Adds a group separation class to header elements in a list of React elements.
   * 
   * This function iterates through a list of React elements and adds specific 
   * class names to 'h2' and 'h3' elements based on their preceding elements. 
   * If an 'h2' element does not follow an 'h1' element, it gets the class 'group-sub-category'. 
   * If an 'h3' element does not follow an 'h2' element, it gets the class 'group-sub-sub-category'.
   * 
   * @param {React.Element[]} elements - An array of React elements to process.
   * @returns {React.Element[]} - A new array of React elements with added class names.
   */
  const addGroupSeparationClass = (elements) => {
     
  let firstH1Found = false;
  return elements.map((element, index, arr) => {
    const previousElement = arr[index - 1];
    if (element.type === 'h2' && (!previousElement || previousElement.type !== 'h1')) {
      return React.cloneElement(element, {
        className: `${element.props.className ? element.props.className + ' ' : ''}group-sub-category`,
      });
    } else if (element.type === 'h3' && (!previousElement || previousElement.type !== 'h2')) {
      return React.cloneElement(element, {
        className: `${element.props.className ? element.props.className + ' ' : ''}group-sub-sub-category`,
      });
    }
    return element;
  });
};

  return (
    <SelectedOptionsProvider>
      <form className="survey-form" onSubmit={handleSubmit}>
        {questions.map((question, index) => {
          let currentDisplayIndex = null;
          const elements = [];

          if (!displayedCategories.has(question.category)) {
            elements.push(
              <h1
                key={`category-${question.category}`}
                className="survey-category"
              >
                {question.category}
              </h1>
            );
            displayedCategories.add(question.category);
          }

          if (
            question.sub_category &&
            !displayedSubCategories.has(question.sub_category)
          ) {
            elements.push(
              <h2
                key={`sub-category-${question.sub_category}`}
                className="survey-sub-category"
              >
                {question.sub_category}
              </h2>
            );
            displayedSubCategories.add(question.sub_category);
          }

          if (
            question.sub_sub_category &&
            !displayedSubSubCategories.has(question.sub_sub_category)
          ) {
            elements.push(
              <h3
                key={`sub-sub-category-${question.sub_sub_category}`}
                className="survey-sub-sub-category"
              >
                {question.sub_sub_category}
              </h3>
            );
            displayedSubSubCategories.add(question.sub_sub_category);
          }

          if (
            question.sub_question === null &&
            question.sub_sub_question === null
          ) {
            actualQuestionIndex++;
            currentDisplayIndex = displayIndex++;
          }

          switch (question.type) {
            case "likert_scale":
              elements.push(
                <LikertScaleQuestion
                  key={index}
                  question={question}
                  questionIndex={actualQuestionIndex}
                  displayIndex={currentDisplayIndex}
                  options={data[question.options]}
                  payload={payload}
                  setPayload={setPayload}
                  checkQuestionOrder={checkQuestionOrder}
                />
              );
              break;
            case "ordinal_ranking":
              elements.push(
                <OrdinalRankingQuestion
                  key={index}
                  question={question}
                  questionIndex={actualQuestionIndex}
                  displayIndex={currentDisplayIndex}
                  options={data[question.options]}
                  payload={payload}
                  setPayload={setPayload}
                  checkQuestionOrder={checkQuestionOrder}
                />
              );
              break;
            case "open_ended":
              elements.push(
                <OpenEndedQuestion
                  key={index}
                  question={question}
                  displayIndex={currentDisplayIndex}
                  questionIndex={actualQuestionIndex}
                  payload={payload}
                  setPayload={setPayload}
                  checkQuestionOrder={checkQuestionOrder}
                />
              );
              break;
            default:
              return null;
          }

          return addGroupSeparationClass(elements);
        })}
        <div className="survey-btn-container">
          <button
            className="survey-btn"
            type="submit"
            disabled={!formIsValid || submitting}
          >
            {submitting ? (
              <div className="survey-loading-spinner">
                <div className="survey-spinner"></div>
                {strings.loadingMessage}
              </div>
            ) : (
              strings.submitButtonText
            )}
          </button>
        </div>
      </form>
    </SelectedOptionsProvider>
  );
};

export default SelfAssessmentForm;
