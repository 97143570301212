/**
 * Dashboard Component
 *
 * This component is the main dashboard for the user interface.
 * It displays the user's profile, goal progress, and action cards.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered Dashboard component.
 *
 * @example
 * return <Dashboard />;
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../apiControllers/userApiController";
import { getMilestonesByGoalId } from "../../apiControllers/milestoneApiController";
import "../Styles/Dashboard.css";
import IconData from "./Icon-Data";
import { getGoalsByUserId } from "../../apiControllers/goalsApiController";
import Carousel from "./Carousel";
import { getFeedbackGraphData } from "../../apiControllers/feedbackApiController";
import { getAllReviewsByEmail } from "../../apiControllers/reviewApiController";

const Dashboard = () => {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    image:
      "https://assets.api.uizard.io/api/cdn/stream/012d78e6-a892-4b09-b86c-1dae217de3bd.png",
    title: "",
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ac turpis egestas maecenas pharetra. Risus sed vulputate odio ut. Egestas quis ipsum suspendisse ultrices gravida dictum fusce ut. Morbi tincidunt augue interdum velit euismod in pellentesque.",
  });

  const [goals, setGoals] = useState([]);
  const [randomStrengthFeedback, setRandomStrengthFeedback] = useState(null); // State for feedback
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const storedUserId = localStorage.getItem("userId");

        if (storedUserId) {
          const goalsData = await getGoalsByUserId(storedUserId);
          console.log("Goals Data:", goalsData);
          setGoals(goalsData);
        } else {
          console.error("User ID not found in localStorage");
        }
      } catch (error) {
        console.error("Error fetching goals:", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        console.log("User Data:", userData);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    const fetchFeedbackData = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        if (!userEmail) {
          console.error("User email not found in localStorage");
          return;
        }

        const reviews = await getAllReviewsByEmail(userEmail);
        const graphData = await getGraphData(reviews);

        const feedback = getRandomStrengthFeedback(graphData);
        console.log("Random Strength Feedback:", feedback);

        setRandomStrengthFeedback(feedback); // Store the feedback
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    };

    fetchUserData();
    fetchGoals();
    fetchFeedbackData();
  }, []);

  useEffect(() => {
    console.log("randomStrengthFeedback State:", randomStrengthFeedback);
  }, [randomStrengthFeedback]);

  const getGraphData = async (reviews) => {
    try {
      const filteredReviews = reviews.filter((review) => review.status === "Completed");

      const enrichedDataPromises = filteredReviews.map(async (review) => {
        try {
          const detailedData = await getFeedbackGraphData(review.reviewId);
          return {
            ...detailedData,
            relationship: review.type,
            name: review.name,
          };
        } catch (error) {
          console.error(`Error fetching data for review ID ${review.reviewId}:`, error);
          return null;
        }
      });

      const enrichedData = (await Promise.all(enrichedDataPromises)).filter(Boolean);
      console.log("Enriched Feedback Data:", enrichedData);
      return enrichedData;
    } catch (error) {
      console.error("An error occurred in getGraphData:", error);
      return [];
    }
  };

  const getRandomStrengthFeedback = (graphData) => {
    const strengthsFeedback = [];
  
    graphData.forEach((review) => {
      if (review.relationship === "self") return;
  
      for (let i = 9; i < review.answers.length; i++) {
        const answer = review.answers[i];
        const previousAnswer = review.answers[i - 1];
  
        if (
          answer.response_open_question &&
          previousAnswer?.option_close_ended?.likert_number > 2
        ) {
          strengthsFeedback.push({
            message: answer.response_open_question,
            author: review.name,
            relationship: review.relationship,
          });
        }
      }
    });

    const shuffledFeedback = strengthsFeedback.sort(() => Math.random() - 0.5);
  
    return shuffledFeedback.slice(0, 2);
  };

  const generateSlides = () => {
    if (!randomStrengthFeedback || randomStrengthFeedback.length <= 1) {
      return [
        <ActionCard
          title="Let's start your self-assessment"
          details="It’s the first step towards building out your path to success!"
          buttonText="Get Started"
          cardBg="card-bg-1"
          onClick={() => navigate("/feedback")}
        />,
        <ActionCard
          title="Take the first step towards your growth plan"
          details="Let's get started by creating your first goal!"
          buttonText="Create a Goal"
          cardBg="card-bg-2"
          onClick={() => navigate("/goals")}
        />,
        <ActionCard
          title="Discover Valuable Insights"
          details="Explore analytics and trends to stay ahead in your goals."
          buttonText="Go to Insights"
          cardBg="card-bg-3"
          onClick={() => navigate("/insights")}
        />,
        <ActionCard
          title="Explore the Growth Library"
          details="Find new resources to support your personal development."
          buttonText="Go to Library"
          cardBg="card-bg-4"
          onClick={() => navigate("/growth-library")}
        />,
      ];
    } else {
      return [
        <ActionCard
          title="Let's start your self-assessment"
          details="It’s the first step towards building out your path to success!"
          buttonText="Get Started"
          cardBg="card-bg-1"
          onClick={() => navigate("/feedback")}
        />,
        <QuoteCard
          message={randomStrengthFeedback[0].message}
          author={`${randomStrengthFeedback[0].author}`}
          cardBg="card-bg-2"
        />,
        <ActionCard
          title="Take the first step towards your growth plan"
          details="Let's get started by creating your first goal!"
          buttonText="Create a Goal"
          cardBg="card-bg-3"
          onClick={() => navigate("/goals")}
        />,
        <QuoteCard
          message={randomStrengthFeedback[1].message}
          author={`${randomStrengthFeedback[1].author}`}
          cardBg="card-bg-4"
        />,
      ];
    }
  };
  
  return (
    <div className="dashboard-container">
      <MainContent
        user={user}
        goals={goals}
        slides={
          randomStrengthFeedback && randomStrengthFeedback.length > 0
            ? generateSlides()
            : null 
        }
      />
    </div>
  );
};

/**
 * MainContent Component
 *
 * This component renders the main content of the dashboard, including the user's profile,
 * greeting, goal progress, and action cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.user - The user data.
 * @param {Array} props.goals - The list of user goals.
 *
 * @returns {JSX.Element} The rendered MainContent component.
 */
const MainContent = ({ user, goals, slides }) => {
  return (
    <div className="dashboard-main">
      <div className="dashboard-top-container">
        <div className="dashboard-profile-container">
          <div className="dashboard-greeting-container">
            <div className="dashboard-header">
              Good Morning {user.first_name}!
            </div>
            <div className="dashboard-subheader">
              <img
                className="dashboard-profile-picture"
                src={
                  user.image ||
                  "https://assets.api.uizard.io/api/cdn/stream/012d78e6-a892-4b09-b86c-1dae217de3bd.png"
                }
                alt="profile"
              />
              <ProfileCard job_title={user.title} about_me={user.about} />
            </div>
          </div>
          <GoalProgressCard goals={goals} />
        </div>
      </div>

      {/* Render carousel only if slides are available */}
      {slides ? (
        <div className="dashboard-actionCards">
          <Carousel slides={slides} />
        </div>
      ) : (
        <p>Loading feedback...</p>
      )}
    </div>
  );
};

/**
 * ProfileCard Component
 *
 * This component displays the user's job title and about me section.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.job_title - The user's job title.
 * @param {string} props.about_me - Information about the user.
 *
 * @returns {JSX.Element} The rendered ProfileCard component.
 */
const ProfileCard = ({ job_title, about_me }) => (
  <div className="dashboard-profileCard">
    <h2>{job_title}</h2>
    <p className="dashboard-aboutMe">About Me : {about_me}</p>
  </div>
);


/**
 * GoalProgressCard Component
 *
 * This component displays the user's goal progress.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.goals - The list of user goals.
 *
 * @returns {JSX.Element} The rendered GoalProgressCard component.
 */
const GoalProgressCard = ({ goals }) => {
  const navigate = useNavigate();
  const [goalProgress, setGoalProgress] = useState({});


  const getCircleColor = (progress) => {
    if (progress < 40) {
      return "#f2cdd2";
    } else if (progress < 74) {
      return "#f1d7ba";
    } else {
      return "#cdf2d0";
    }
  };

  useEffect(() => {
    const fetchMilestoneProgress = async () => {
      const progressData = {};

      for (const goal of goals) {
        try {
          const milestones = await getMilestonesByGoalId(goal.goal_id);

          if (milestones.length > 0) {
            const completedMilestones = milestones.filter(
              (m) => m.status === "Completed"
            ).length;
            const progressPercentage = Math.round(
              (completedMilestones / milestones.length) * 100
            );
            progressData[goal.goal_id] = progressPercentage;
          } else {
            progressData[goal.goal_id] = 0;
          }
        } catch (error) {
          console.error(`Error fetching milestones for goal ${goal.goal_id}:`, error);
          progressData[goal.goal_id] = 0;
        }
      }
      setGoalProgress(progressData);
    };

    if (goals.length > 0) {
      fetchMilestoneProgress();
    }
  }, [goals]);

  return (
    <div className="dashboard-goalProgress">
      <h2>Goal Progress</h2>
      {goals.length > 0 ? (
        goals.map((goal) => {
          return (
            <GoalItem
              key={goal.goal_id}
              icon={<IconData />}
              title={goal.title}
              progress={goalProgress[goal.goal_id] || 0}
              circleColor={getCircleColor(goalProgress[goal.goal_id] || 0)}
              onClick={() => {
                navigate("/goals", {
                  state: { goal, openModal: true },
                });
              }}
            />
          );
        })
      ) : (
        <p>No goals found</p>
      )}
    </div>
  );
};

/**
 * GoalItem Component
 *
 * This component displays an individual goal with its progress.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {JSX.Element} props.icon - The icon representing the goal.
 * @param {string} props.title - The title of the goal.
 * @param {number} props.progress - The progress percentage of the goal.
 * @param {string} props.circleColor - The color of the progress circle.
 * @param {function} props.onClick - Event handler for clicking the goal item.
 *
 * @returns {JSX.Element} The rendered GoalItem component.
 */
const GoalItem = ({ icon, title, progress, circleColor, onClick }) => (
  <div
    className="dashboard-goal"
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    <div className="dashboard-goal-icon">{icon}</div>
    <div className="dashboard-goal-text">
      <div className="dashboard-goal-title">{title}</div>
      <div className="dashboard-goal-subtitle">Current Progress</div>
    </div>
    <div className="dashboard-goal-progress">
      <div
        className="dashboard-progress-circle"
        style={{
          "--progress": `${progress * 3.6}deg`,
          "--circle-color": circleColor,
        }}
      >
        {progress}%
      </div>
    </div>
  </div>
);

/**
 * ActionCard Component
 *
 * This component displays an action card with a title, details, message, author, and an optional button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the action card.
 * @param {string} props.details - The details of the action card.
 * @param {string} props.message - The message of the action card.
 * @param {string} props.author - The author of the message.
 * @param {string} [props.buttonText] - The text for the optional button.
 *
 * @returns {JSX.Element} The rendered ActionCard component.
 */
const ActionCard = ({
  title,
  details,
  buttonText,
  cardBg,
  onClick,
}) => (
  <div className={`actionCard ${cardBg}`}>
    <h3 className="actionCard-title">{title}</h3>
    <p className="actionCard-details">{details}</p>
    {buttonText && (
      <button className="dashboard-button" onClick={onClick}>
        {buttonText}
      </button>
    )}
  </div>
);

/**
 * QuoteCard Component
 *
 * This component displays a card with a message (quote) and its author.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.message - The message or quote to display.
 * @param {string} props.author - The author of the quote.
 * @param {string} [props.cardBg] - The background class for styling the card.
 *
 * @returns {JSX.Element} The rendered QuoteCard component.
 *
 */
const QuoteCard = ({
  message,
  author,
  cardBg
}) => {
  return (
    <div className={`quote-card ${cardBg}`}>
      <div className="quote-content"> 
        <p className="quote-message">{message}</p>
        <p className="quote-author">- {author}</p>
      </div>
    </div>
  );
};

export default Dashboard;
