import React from "react";
import ImageContainer from "../../Feedback/assets/ImageContainer";
import imageSrc from "../assets/growth-library-img.png";
import strings from "../assets/strings.json";
import "../../Styles/GrowthLibrary.css";

/**
 * InfoSection component renders an informational section with an image and text.
 *
 * @returns {JSX.Element} The InfoSection component.
 */
const InfoSection = () => {
  return (
    <div className="growth-library-info-container">
      <div>
        <ImageContainer imageSrc={imageSrc} />
      </div>
      <div className="growth-library-info-content">
        <h2 className="growth-library-info-content-header">{strings.header}</h2>
        <p className="growth-library-info-content-paragraph">{strings.paragraph}</p>
      </div>
    </div>
  );
};

export default InfoSection;
