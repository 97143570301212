import React, { useState } from "react";
import { useSelectedOptions } from "./SelectedOptionsProvider";

/**
 * LikertScaleQuestion component renders a set of buttons for Likert scale questions.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.question - The question object.
 * @param {number} props.questionIndex - The index of the question in the payload array.
 * @param {number} props.displayIndex - The index to display for the question.
 * @param {Array} props.options - The available options for the question.
 * @param {Array} props.payload - The current payload array.
 * @param {function} props.setPayload - The function to update the payload array.
 * @param {function} props.checkQuestionOrder - The function to determine the question order type.
 *
 * @returns {JSX.Element} The LikertScaleQuestion component.
 */
const LikertScaleQuestion = ({
  question,
  questionIndex,
  displayIndex,
  options,
  payload,
  setPayload,
  checkQuestionOrder,
}) => {
  const { selectedOptions, setSelectedOptions } = useSelectedOptions();
  const [selectedOption, setSelectedOption] = useState(null);

  /**
   * Updates the payload array with the selected option for the Likert scale question.
   *
   * @param {number} index - The index of the selected option.
   */
  const likertUpdatePayload = (index) => {
    let updatedPayload = [...payload];
    let updatedPayloadElement = updatedPayload[questionIndex];
    updatedPayloadElement = [...updatedPayloadElement];

    switch (checkQuestionOrder(question)) {
      case "order":
        updatedPayloadElement[0] = index;
        break;
      case "order_sub":
        // [0, 1, 2, 3*, 4, 5*, 6, 7* 8]
        updatedPayloadElement[question.order_sub_question * 2 + 3 - 2] = index;
        break;
      default:
        break;
    }

    updatedPayload[questionIndex] = updatedPayloadElement;
    setPayload(updatedPayload);
  };

  /**
   * Handles the click event for an option button.
   *
   * @param {number} index - The index of the selected option.
   */
  const handleOptionClick = (index) => {
    setSelectedOption(index);

    // API Payload handler
    likertUpdatePayload(index + 1);
  };

  // Determine the appropriate index to retrieve selected options
  const previousQuestionIndex =
    question.sub_question === null && question.sub_sub_question === null
      ? questionIndex - 1
      : questionIndex;
  const attributes = selectedOptions[previousQuestionIndex]
    ? [...selectedOptions[previousQuestionIndex]]
    : [];

  let displayText =
    question.sub_sub_question || question.sub_question || question.question;

  displayText = displayText.replace(
    /\[attribute 1\]/g,
    attributes.shift() || "[attribute 1]"
  );
  displayText = displayText.replace(
    /\[attribute 2\]/g,
    attributes.shift() || "[attribute 2]"
  );
  displayText = displayText.replace(
    /\[attribute 3\]/g,
    attributes.shift() || "[attribute 3]"
  );

  // Sort options based on likert_number
  const sortedOptions = options
    .slice()
    .sort((a, b) => a.likert_number - b.likert_number);

  return (
    <div
      className={`${
        displayIndex === null ? "sub-question" : "survey-question"
      }`}
    >
      <p>
        {displayIndex !== null ? `${displayIndex}. ` : ""}
        {displayText}
      </p>
      <div className="survey-button-container">
        {sortedOptions.map((option, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleOptionClick(index)}
            className={
              selectedOption === index ? "answer-selected" : "answer-unselected"
            }
          >
            {option.option.split(":")[0]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LikertScaleQuestion;
