import React from "react";
import { Link } from "react-router-dom";
import ImageContainer from "../assets/ImageContainer";
import imageSrc from "../assets/reviews-image.png";

const text = require("../resources/feedback-texts.json");

/**
 * ReviewStarter component renders the initial view for starting reviews.
 *
 * @param {function} openModal - Function to open the feedback modal.
 * @param {string} selfReviewId - The ID of the self-review.
 * @returns {JSX.Element} The ReviewStarter component.
 */
const ReviewStarter = ({ openModal, selfReview }) => {
  const renderButtonBasedOnStatus = (status) => {
    switch (status) {
      case "Completed":
        return (
          <button className="feedback-btn btn-secondary">
            {text.info.buttons.selfAssessmentRedo}
          </button>
        );
      case "Requested":
        return (
          <button className="feedback-btn btn-warning">
            {text.info.buttons.selfAssessmentComplete}
          </button>
        );
      default:
        return (
          <button className="feedback-btn btn-default">
            {text.info.buttons.selfAssessment}
          </button>
        );
    }
  };

  return (
    <div className="review-starter">
      <div className="bg-image">
        <ImageContainer imageSrc={imageSrc} />
      </div>
      <div className="review-starter-info-container">
        <div>
          <h2 className="review-starter-header">{text.info.header}</h2>
          <p className="review-starter-info">{text.info.text}</p>
        </div>

        <div className="feedback-buttons" id="row-of-controls">
          <button className="feedback-btn btn-primary" onClick={openModal}>
            {text.info.buttons.review}
          </button>
          <Link to="/self-assessment">
            {renderButtonBasedOnStatus(selfReview.status)}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReviewStarter;
