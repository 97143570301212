import React from "react";

/**
 * InputField component for rendering text input or textarea elements.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.type="text"] - The type of input field ("text" or "textarea").
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {string} props.value - The value of the input field.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {number} [props.rows=1] - The number of rows for a textarea (default is 1).
 * @example
 * return (
 *   <InputField
 *     type="text"
 *     placeholder="Enter your name"
 *     value={name}
 *     onChange={(e) => setName(e.target.value)}
 *   />
 * )
 */
const InputField = ({
  type = "text",
  placeholder,
  value,
  onChange,
  rows = 1,
}) => {
  const inputClass = value
    ? "onboarding-input-field filled"
    : "onboarding-input-field";

  return type === "textarea" ? (
    <textarea
      className={`${inputClass} textarea`} // Add a 'textarea' class specifically for textareas
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rows={rows}
    />
  ) : (
    <input
      className={inputClass}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default InputField;
