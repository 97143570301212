import React, { useState, useEffect, useRef } from "react";
import "../Styles/Carousel.css";

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(slides.length * 2);
  const slidesRef = useRef([...slides, ...slides, ...slides, ...slides]); 
  const [isAnimating, setIsAnimating] = useState(false);
  const slidesPerPage = 4;

  const handleTransitionEnd = () => {
    setIsAnimating(false);
    if (currentIndex >= slides.length * 3) {
      setCurrentIndex(slides.length * 2); // Reset to the second loop
    } else if (currentIndex < slides.length + 1) {
      setCurrentIndex((slides.length * 3)); // Reset to the third loop
    }
  };

  const nextSlide = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const translateValue = -((currentIndex - slides.length) * (100 / slidesPerPage));

  return (
    <div className="carousel-wrapper">
      <div className="carousel-controls">
        <button className="prev-btn" onClick={prevSlide}>
          &lt;
        </button>

        <div className="carousel-container">
          <div
            className="carousel-slides"
            style={{
              transform: `translateX(${translateValue}%)`,
              transition: isAnimating ? "transform 0.5s ease-in-out" : "none",
            }}
            onTransitionEnd={handleTransitionEnd}
          >
            {slidesRef.current.map((slide, index) => (
              <div key={index} className="carousel-slide">
                {slide}
              </div>
            ))}
          </div>
        </div>

        <button className="next-btn" onClick={nextSlide}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
