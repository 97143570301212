import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/PasswordReset.css";
import { resetPassword } from "../../apiControllers/userApiController";

/**
 * Renders a component for password reset.
 *
 * @return {JSX.Element} The rendered component.
 */
const PasswordReset = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState("");

  const resetPasswordSubmit = async () => {
    try {
      let response = await resetPassword({ email_address: email });
      console.log(response);
      navigate(`/reset-password/link-sent?email=${email}`);
    } catch (error) {
      let updatedErrors = [...errors];
      updatedErrors[0] = "No user with that email was found";
      setErrors(updatedErrors);
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  return (
    <>
      <div className="password-reset-container">
        <div className="password-reset-form-box">
          <span className="password-reset-header">Password reset</span>
          <form
            className="password-reset-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="password-reset-input-field"
              onChange={handleOnChange}
            />
            {errors[0] && (
              <p className="password-reset-error-text">{errors[0]}</p>
            )}

            <div className="password-reset-button-group">
              <button
                type="button"
                className="password-reset-btn"
                onClick={resetPasswordSubmit}
              >
                Reset password
              </button>
            </div>

            <div className="password-reset-login-link">
              <Link className="password-reset-login-link-text" to="/login">
                <span>Back to login</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
