/**
 * This module is for managing API calls to the server's feedback endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Splits an array into sub-arrays based on the order_category property of each element.
 *
 * @param {Array} array - The array to be split.
 * @return {Array} An array of sub-arrays, where each sub-array contains elements with the same order_category.
 */
const splitArrayByCategory = (array) => {
    // Initialize an empty array to store the split sub-arrays
    const result = [];

    // Initialize variables to keep track of the current category and sub-array
    let currentCategory = null;
    let subArray = [];

    // Iterate over each element in the array
    array.forEach((element) => {
        // If the order_category changes, push the current sub-array to the result array
        // and create a new sub-array for the new category
        if (element.order_category !== currentCategory) {
            if (subArray.length > 0) {
                result.push(subArray);
            }
            subArray = [element];
            currentCategory = element.order_category;
        } else {
            // If the order_category is the same, add the element to the current sub-array
            subArray.push(element);
        }
    });

    // Push the last sub-array to the result array
    if (subArray.length > 0) {
        result.push(subArray);
    }

    return result;
};

/**
 * Flattens a 2d array into a 1d array by appending all elements of each sub-array into a single array.
 *
 * @param {Array<Array>} arrays - The 2d array to be flattened.
 * @return {Array} The flattened 1d array.
 */
function flattenAndAppend(arrays) {
    let result = [];
    for (let array of arrays) {
        result.push(...array);
    }
    return result;
}

/**
 * Retrieves the survey data for a given questionnaire. Used as a helper function for specific api calls.
 *
 * @param {string} questionnaire - The ID of the questionnaire.
 * @return {Promise<Object>} A Promise that resolves with the survey helper data.
 */
const getSurveyHelper = async (questionnaire) => {
    try {
        // Make a GET request to the specified URL
        const response = await api.get(
            `${apiUrl}/v1/questionnaire/${questionnaire}`
        );

        // Initial sort
        response.data.questions.sort((a, b) => {
            if (a.order_category !== b.order_category) {
                return a.order_category - b.order_category;
            }

            return a.order_question - b.order_question;
        });

        // Split into categories
        let categoryArrays = splitArrayByCategory(response.data.questions);

        // Sort competency start
        let competencyArray = categoryArrays[1];

        competencyArray.sort((a, b) => {
            if (a.order_sub_category !== b.order_sub_category) {
                return a.order_sub_category - b.order_sub_category;
            }

            return a.order_sub_sub_category - b.order_sub_sub_category;
        });

        categoryArrays[1] = competencyArray;

        // Sort personal start
        let personalArray = categoryArrays[2];

        personalArray.sort((a, b) => {
            let aCompare = 0;
            let bCompare = 0;

            aCompare = a.order_question;
            bCompare = b.order_question;

            aCompare = aCompare * 10 + a.order_sub_question;
            bCompare = bCompare * 10 + b.order_sub_question;

            aCompare = aCompare * 10 + a.order_sub_sub_question;
            bCompare = bCompare * 10 + b.order_sub_sub_question;

            return aCompare - bCompare;
        });

        // Sort personal fin
        categoryArrays[2] = personalArray;

        let fullArray = flattenAndAppend(categoryArrays);

        response.data.questions = fullArray;

        // Set the response data to the state variable
        return response.data;
    } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
    }
};

/**
 * Retrieves the reviewer survey from the app API.
 *
 * @return {Promise<Object>} A Promise that resolves with the survey helper data for the reviewer questionnaire.
 */
export const getReviewerSurvey = async () => {
    return getSurveyHelper("V2%20-%20Reviewer%20questionnaire");
};

/**
 * Retrieves the self-review survey from the app API.
 *
 * @return {Promise<Object>} A Promise that resolves with the survey helper data for the self-assessment questionnaire.
 */
export const getSelfReviewSurvey = async () => {
    return getSurveyHelper("V2%20-%20Self-assessment%20questionnaire");
};

/**
 * Gets the graph data for a feedback.
 *
 * @param {string} review_id - The ID of the review to get data for.
 * @returns {Promise<Object>|undefined} A Promise that resolves with the API data or undefined if an error occurs.
 */
export const getFeedbackGraphData = async (review_id) => {
    try {
        // Make a GET request to the specified URL
        const response = await api.get(
            `${apiUrl}/v1/review/feedback/graph-data/${review_id}`
        );

        return response.data;
    } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
    }
};

/**
 * Sends a review request to the API.
 *
 * @param {Object} payload - The payload containing the necessary information for the review request.
 * @return {Promise<Object>} A Promise that resolves with the response from the API.
 */
export const sendReviewRequest = async (payload) => {
    try {
        const response = await api.post(
            `${apiUrl}/v1/review/feedback/send-email`,
            payload
        );
        return response;
    } catch (error) {
        // Handle any errors that occur during the request
        console.error("Error fetching data:", error);
    }
};

/**
 * Submits a review to the API.
 *
 * @param {string} reviewId - The ID of the review.
 * @param {Array} payload - The payload containing the review answers.
 * @param {Array} sortedQuestions - The sorted questions for the review.
 * @param {string} type - The type of review (self or peer).
 * @return {Promise<Object>} A Promise that resolves with the response from the API.
 */
export const submitReview = async (
    reviewId,
    payload,
    sortedQuestions,
    type
) => {
    let reviewData = (await api.get(`${apiUrl}/v1/review/single/${reviewId}`))
        .data;
    delete reviewData.review_id;

    const flattenedPayload = [].concat(...payload);

    sortedQuestions = [...sortedQuestions];
    sortedQuestions = sortedQuestions.flatMap((element, index) => {
        if (element.type === "ordinal_ranking") {
            return Array.from({length: 3}, () => element);
        }
        return [element];
    });

    let mergedPayload = [];
    let ordinal_counter = 0;

    for (let i = 0; i < sortedQuestions.length; i++) {
        if (!flattenedPayload[i]) {
            throw new Error(`Missing answer position: ${i}`);
        }
        mergedPayload[i] = {
            question: sortedQuestions[i].question,
            sub_question: sortedQuestions[i].sub_question,
            sub_sub_question: sortedQuestions[i].sub_sub_question,
            answer: flattenedPayload[i],
            order_question: sortedQuestions[i].order_question,
            order_sub_question: sortedQuestions[i].order_sub_question,
            order_sub_sub_question: sortedQuestions[i].order_sub_sub_question,
            order_category: sortedQuestions[i].order_category,
            order_sub_category: sortedQuestions[i].order_sub_category,
            order_sub_sub_category: sortedQuestions[i].order_sub_sub_category,
        };

        mergedPayload[i].ordinal_rank_order = null;

        if (sortedQuestions[i].type === "ordinal_ranking") {
            ordinal_counter += 1;
            mergedPayload[i].ordinal_rank_order = ordinal_counter;
        } else {
            ordinal_counter = 0;
        }
    }

    // Prepare final payload with extra info
    let finalPayload = {};

    // Trim for final sendoff
    finalPayload.answers = mergedPayload.map((element, index) => {
        return {
            answer: element.answer,
            ordinal_rank_order: element.ordinal_rank_order,
            order_question: element.order_question,
            order_sub_question: element.order_sub_question,
            order_sub_sub_question: element.order_sub_sub_question,
            order_category: element.order_category,
            order_sub_category: element.order_sub_category,
            order_sub_sub_category: element.order_sub_sub_category,
        };
    });

    finalPayload.review = reviewData;
    switch (type) {
        case "self":
            finalPayload.title = "V2 - Self-assessment questionnaire";
            break;
        case "peer":
            finalPayload.title = "V2 - Reviewer questionnaire";
            break;
        default:
            break;
    }

    finalPayload.feedback_completed = true;

    try {
        const response = await api.post(
            `${apiUrl}/v1/review/feedback/single`,
            finalPayload
        );
        return response;
    } catch (e) {
        console.error(e);
    }
};

/**
 * Retrieves feedback element data from the API based on the provided self review ID and review ID.
 *
 * @param {string} selfReviewId - The ID of the self review.
 * @param {string} reviewId - The ID of the review.
 * @return {Promise<Object>} A Promise that resolves with the feedback element data as an object.
 * @throws {Error} If there is an HTTP error or an error occurs while fetching the data.
 */
export const getFeedbackElementData = async (selfReviewId, reviewId) => {
    try {
        const response = await fetch(
            `${apiUrl}/v1/review/feedback/element-data?self_review_id=${selfReviewId}&review_id=${reviewId}`
        );
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error; // Rethrow the error to be handled by the caller
    }
};

/**
 * Fetches all survey responses for self-assessment and peer review.
 *
 * @return {Promise<Array>} A Promise that resolves to an array of all survey responses.
 */
export const getAllSurveyResponses = async () => {
    try {
        // Fetch self-assessment survey
        const selfReviewResponse = await api.get(`${apiUrl}/v1/questionnaire/self-assessment`);

        // Fetch peer review survey (if necessary)
        const peerReviewResponse = await api.get(`${apiUrl}/v1/questionnaire/reviewer-assessment`);

        // Combine both surveys if needed
        const allSurveyData = [
            ...selfReviewResponse.data.questions,
            ...peerReviewResponse.data.questions
        ];

        return allSurveyData;
    } catch (error) {
        console.error("Error fetching survey responses:", error);
        throw error;
    }
};

export const getFeedbackReport = async (reviewId) => {
    try {
        const feedbackReport = await api.get(`${apiUrl}/v1/analysis/generate_feedback_report?review_id=${reviewId}`);
        
        const data = await feedbackReport.data;

        // console.log("Feedback Report Data:", feedbackReport);
        return data;
    } catch (error) {
        if (error.response) {
            // The request was made, the server responded with a status code that falls out of the range of 2xx
            console.error("Error fetching data:", error.response.status, error.response.data);
        } else if (error.request) {
            // The request was made, but no response was received
            console.error("No response received:", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up the request:", error.message);
        }
        throw error;
    }
};

