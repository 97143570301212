import api from "./axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Fetches the user's profile data from the server.
 *
 * @returns {Object} - The response data containing the user's profile information.
 * @throws {Error} - If there's an error fetching the user data.
 */
export const getUserData = async () => {
  try {
    const token = Cookies.get("userToken");
    const response = await api.get(`${apiUrl}/v1/user/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

/**
 * Updates the user's profile data on the server.
 *
 * @param {Object} userData - The updated user data.
 * @returns {Object} - The response data after updating the user's profile.
 * @throws {Error} - If there's an error updating the user data.
 */
export const updateUser = async (userData) => {
  try {
    const token = Cookies.get("userToken");
    const response = await api.put(`${apiUrl}/v1/user/profile`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};

/**
 * Changes the user's password on the server.
 *
 * @param {Object} passwordData - The new password data.
 * @returns {Object} - The response data after changing the password.
 * @throws {Error} - If there's an error changing the password.
 */
export const changePassword = async (passwordData) => {
  try {
    const token = Cookies.get("userToken");
    const response = await api.put(`${apiUrl}/v1/user/password`, passwordData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

/**
 * Sends a password reset email to the server.
 *
 * @param {Object} payload - The payload containing the user's email and other information.
 * @returns {Promise<Object>} - A promise that resolves to the response data after resetting the password.
 * @throws {Error} - If there's an error resetting the password.
 */
export const resetPassword = async (payload) => {
  // Forced error for testing
  let forced_error = false;

  try {
    if (forced_error) {
      throw new Error("Password reset failed");
    }
    const response = await api.post(
      `${apiUrl}/v1/user/reset-password-email`,
      payload
    );
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

/**
 * Resets a user's password on the server using a password reset token.
 *
 * @param {Object} payload - The payload containing the user's email and other information.
 * @returns {Promise<Object>} - A promise that resolves to the response data after resetting the password.
 * @throws {Error} - If there's an error resetting the password.
 */
export const resetPasswordWithToken = async (payload) => {
  // Forced error for testing
  let forced_error = false;

  try {
    if (forced_error) {
      throw new Error("Password reset failed");
    }
    const response = await api.put(`${apiUrl}/v1/user/reset-password`, payload);
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

/**
 * Validates a token by sending a GET request to the API endpoint.
 *
 * @param {string} payload - The token to be validated.
 * @return {Promise<Object>} A Promise that resolves with the response from the API.
 * @throws {Error} If an error occurs while making the request or if the response contains an error.
 */
export const validateToken = async (payload) => {
  // Forced error for testing
  let forced_error = false;

  try {
    if (forced_error) {
      throw new Error("Token validation failed");
    }
    const response = await api.get(
      `${apiUrl}/v1/user/validate-token/${payload}`
    );
    return response;
  } catch (error) {
    console.error("Error validating token:", error);
    throw error;
  }
};
