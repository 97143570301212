import React from "react";
import ReviewerTable from "./ReviewerTable";

/**
 * Renders a container for a table of reference requests, including a header and the table itself.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.data - The data to be displayed in the table.
 * @param {string} props.selfReviewId - The ID of the self-review.
 * @return {JSX.Element} The rendered table container.
 */
const TableContainer = ({ data, selfReviewId }) => {
  return (
    <div className="reviewer-table-container table-container">
      <div className="table-header">
        <span>Reference Requests</span>
        <div className="controls"></div>
      </div>
      <ReviewerTable
        data={data}
        selfReviewId={selfReviewId}
        className="reviews-table"
      />
    </div>
  );
};

export default TableContainer;
