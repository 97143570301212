import React, { useEffect, useState } from "react";
import {
  getGoalsByUserId,
  updateGoal,
} from "../../apiControllers/goalsApiController";
import GoalModalContent from "./GoalModal/GoalModalContent";
import GoalModal from "./GoalModal/GoalModal";

const GoalsTracking = ({ user }) => {
  const [goals, setGoals] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [scale, setScale] = useState("");
  const [notes, setNotes] = useState("");
  // modal state
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentGoalId, setCurrentGoalId] = useState(null);

  // Fetch goals by user id
  useEffect(() => {
    const fetchGoals = async () => {
      try {
        if (!user) return;
        const data = await getGoalsByUserId(user);
        setGoals(data);
      } catch (error) {
        // handle errors here if needed
      }
    };

    fetchGoals();
  }, [user]);

  // Update goal status to "Completed"
  const handleUpdateStatus = async (goal_id) => {
    try {
      await updateGoal(goal_id, {
        goal_id,
        title,
        description,
        deadline,
        notes,
        status: "Completed",
        scale,
      });
      setGoals(
        goals.map((goal) =>
          goal.goal_id === goal_id ? { ...goal, status: "Completed" } : goal
        )
      );
    } catch (error) {
      // handle errors here if needed
    }
  };

  // handler for when the user clicks on the "Edit Goal" button
  const handleEditGoal = (goal_id) => {
    setModalOpen(true);
    setCurrentGoalId(goal_id);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="goals-tracking-container">
      {goals.map((goal, index) => (
        <div key={index} className="goals-tracking-card">
          <div className="goals-tracking-card-text">
            <span className="goals-tracking-task">{goal.title}</span>
            <span className="goals-tracking-deadline">
              Deadline: {new Date(goal.deadline).toLocaleDateString("en-CA")}
              <span style={{ marginLeft: "10px" }}>Scale: {goal.scale}</span>
            </span>
          </div>
          <div className="goals-tracking-card-buttons-container">
            {goal.status === "Completed" ? (
              <span className="goals-complete-status">Completed</span>
            ) : (
              <>
                <button
                  className="goals-tracking-card-complete"
                  onClick={() => handleUpdateStatus(goal.goal_id)}
                >
                  Mark as Completed
                </button>
              </>
            )}
            <button
              className="goals-tracking-card-edit"
              onClick={() => handleEditGoal(goal.goal_id)}
            >
              Edit Goal
            </button>
          </div>
        </div>
      ))}
      <GoalModal isOpen={isModalOpen} onClose={closeModal}>
        <GoalModalContent goalId={currentGoalId}/>
      </GoalModal>
    </div>
  );
};

export default GoalsTracking;
