import React, { useState, useEffect } from "react";
import Icon from "../../OnBoarding/Icon.jsx";
import ReviewRequestCard from "./ReviewRequestCard.jsx";
import Button from "../../OnBoarding/Button.jsx";
import ReviewRequestRow from "./ReviewRequestRow.jsx";
import Text from "../../OnBoarding/Text.jsx";
import { useNavigate } from "react-router-dom";
import { sendReviewRequest } from "../../../apiControllers/feedbackApiController.js";
import text from "../resources/feedback-texts.json";

/**
 * RequestFeedback component renders the UI for requesting feedback from references.
 *
 * @param {function} closeModal - Function to close the modal.
 * @returns {JSX.Element} The RequestFeedback component.
 */
const RequestFeedback = ({ closeModal }) => {
  const [references, setReferences] = useState([
    { name: "", email: "", relationship: "" },
  ]);
  const [errors, setErrors] = useState([]);
  const [generalError, setGeneralError] = useState(""); // State for general error message
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const navigate = useNavigate();

  /**
   * Handles input changes in the reference fields.
   *
   * @param {number} index - The index of the reference being changed.
   * @param {string} name - The name of the field being changed.
   * @param {string} value - The new value of the field.
   */
  const handleInputChange = (index, name, value) => {
    setReferences((prev) => {
      const updated = [...prev];
      updated[index][name] = value;
      return updated;
    });

    // Clear the error message when the input changes
    setErrors((prev) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [name]: "" };
      return updated;
    });
  };

  /**
   * Effect to check if all required fields are filled.
   */
  useEffect(() => {
    const areFieldsFilled = references
      .slice(0, 1)
      .every(
        (reference) =>
          reference.name && reference.email && reference.relationship
      );
    setIsSubmitDisabled(!areFieldsFilled);
  }, [references]);

  /**
   * Handles adding a new reference row.
   */
  const handleAddClick = () => {
    if (references.length < 5) {
      setReferences((prev) => [
        ...prev,
        { name: "", email: "", relationship: "" },
      ]);
      setErrors((prev) => [...prev, {}]);
    }
  };

  /**
   * Handles removing a reference row.
   *
   * @param {number} index - The index of the reference row to be removed.
   */
  const handleRemoveClick = (index) => {
    setReferences((prev) => prev.filter((_, i) => i !== index));
    setErrors((prev) => prev.filter((_, i) => i !== index));
  };

  /**
   * Validates email format.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} True if the email format is valid, false otherwise.
   */
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  /**
   * Handles form submission.
   */
  const handleSubmit = async () => {
    let hasError = false;

    const newErrors = references.map((reference, index) => {
      if (!isValidEmail(reference.email)) {
        hasError = true;
        return { email: "Invalid email address" };
      }
      return {};
    });

    setErrors(newErrors);

    if (!hasError) {
      for (let reference of references) {
        const payload = {
          source: "test",
          source_email: localStorage.getItem("userEmail"),
          destination: reference.email,
          relationship: reference.relationship,
          fullname: reference.name,
        };
        const response = await sendReviewRequest(payload);
        if (response) {
          closeModal();
        } else {
          setGeneralError(
            "An error occurred while sending the review request. Please try again."
          );
        }
      }
    } else {
      setGeneralError(""); // Clear general error if email validation fails
    }
  };

  return (
    <div className="request-feedback-container">
      <button className="close-button" onClick={closeModal}>
        ×
      </button>
      <ReviewRequestCard>
        <div className="review-request-icon-header">
          <Icon type={"mail"} />
          <div className="review-request-card-title">{text.request.header}</div>
        </div>
        <Text
          className="review-request-card-text"
          text={text.request.subHeader}
        />

        {references.map((reference, index) => (
          <ReviewRequestRow
            key={index}
            index={index}
            reference={reference}
            handleInputChange={handleInputChange}
            handleRemoveClick={handleRemoveClick}
            relationshipOptions={text.request.relationshipOptions}
            showRemoveButton={references.length > 1} // Show remove button if there is more than 1 row
            errorMessage={errors[index] ? errors[index].email : ""}
          />
        ))}

        {generalError && (
          <div className="general-error-message">{generalError}</div>
        )}

        <div className="review-request-icon-text">
          <Button
            onClick={handleAddClick}
            label={
              <>
                <Icon type={"addPerson"} /> {text.request.buttons.addFields}
              </>
            }
          />
        </div>
        <div className="review-request-actions" id="profile">
          <Button
            onClick={handleSubmit}
            label={text.request.buttons.submit}
            disabled={isSubmitDisabled}
          />
        </div>
      </ReviewRequestCard>
    </div>
  );
};

export default RequestFeedback;
