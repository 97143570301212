import React from "react";

const categoryMap = {
  sc_motivating_avg: "Motivating & Developing Others",
  sc_influence_avg: "Leadership & Influence",
  sc_interpersonal_avg: "Interpersonal Relationships",
  sc_communication_avg: "Communication",
  sc_professional_avg: "Professional Competence",
  sc_integrity_avg: "Integrity",
  sc_productivity_avg: "Productivity",
  sc_flexibility_avg: "Flexibility & Stress Management",
  sc_decision_avg: "Decision-Making",
  sc_acumen_avg: "Business Acumen",
  sc_planning_avg: "Planning & Organization",
};

/**
 * Renders the FBRCOverlooked component.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - overlooked: The data for the overlooked component.
 * @return {JSX.Element} The rendered FBRCOverlooked component.
 */
const FBRCOverlooked = ({ overlooked }) => {
  return (
    <>
      {overlooked && (
        <div className="fb-report-competency-overlooked-container">
          {/* Blind Spots */}
          {overlooked.blind_spots.length > 0 && (
            <div className="fb-report-overlooked-row">
              <div className="fb-report-overlooked-container">
                <div className="fb-report-overlooked-attribute-container">
                  <div className="fb-report-overlooked-title">Blind Spots</div>
                  {/* Row start */}
                  {overlooked.blind_spots.map((element, index) => {
                    return (
                      <>
                        <div className="fb-report-overlooked">
                          <div className="fb-report-overlooked-label">
                            {categoryMap[element.category]}
                          </div>
                          <div className="fb-report-overlooked-value-container">
                            <div className="fb-report-overlooked-value">
                              {element.self}
                            </div>
                            <div className="fb-report-overlooked-value">
                              {element.reviewer}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* Row end */}
                </div>
              </div>
              {/* Image */}
              <div className="fb-report-overlooked-image-container">
                <img
                  src="https://assets.api.uizard.io/api/cdn/stream/caaa54dd-7bfc-46a0-a72d-e126859d2d4e.png"
                  alt="blind spots"
                  className="blind-spots-img"
                />
              </div>
            </div>
          )}

          {/* Hidden Strengths */}
          {overlooked.hidden_strengths.length > 0 && (
            <div className="fb-report-overlooked-row">
              {/* Image */}
              <div className="fb-report-overlooked-image-container">
                <img
                  src="https://assets.api.uizard.io/api/cdn/stream/0c60eda1-79f0-4705-8a86-f00b85d3bf68.png"
                  alt="blind spots"
                  className="hidden-strengths-img"
                />
              </div>
              <div className="fb-report-overlooked-container">
                <div className="fb-report-overlooked-attribute-container">
                  <div className="fb-report-overlooked-title">
                    Hidden Strengths
                  </div>
                  {overlooked.hidden_strengths.map((element, index) => {
                    return (
                      <>
                        <div className="fb-report-overlooked">
                          <div className="fb-report-overlooked-label">
                            {categoryMap[element.category]}
                          </div>
                          <div className="fb-report-overlooked-value-container">
                            <div className="fb-report-overlooked-value">
                              {element.self}
                            </div>
                            <div className="fb-report-overlooked-value">
                              {element.reviewer}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FBRCOverlooked;
