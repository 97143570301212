import React from 'react';

const styles = {
  Icon: {
    color: '#3d3d3d',
    fill: '#3d3d3d',
    fontSize: '60px',
    top: '108px',
    left: '701px',
    width: '50px',
    height: '45px',
  },
};

const IconComponent = () => (
  <svg style={styles.Icon}  viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z">
    </path>
    <path d="M14.06 9.94 12 9l2.06-.94L15 6l.94 2.06L18 9l-2.06.94L15 12l-.94-2.06zM4 14l.94-2.06L7 11l-2.06-.94L4 8l-.94 2.06L1 11l2.06.94L4 14zm4.5-5 1.09-2.41L12 5.5 9.59 4.41 8.5 2 7.41 4.41 5 5.5l2.41 1.09L8.5 9zm-4 11.5 6-6.01 4 4L23 8.93l-1.41-1.41-7.09 7.97-4-4L3 19l1.5 1.5z">
    </path>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const InsightsDashboardIcon = (props) => {
  return (
    props.IconComponent
      ? <props.IconComponent style={styles.Icon} />
      : <defaultProps.IconComponent />
  );
};

export default InsightsDashboardIcon;