import React from 'react';

/**
 * Pagination component controls page navigation and page size.
 *
 * @param {function} nextPage - Function to go to the next page.
 * @param {function} previousPage - Function to go to the previous page.
 * @param {boolean} canNextPage - Flag to check if next page is available.
 * @param {boolean} canPreviousPage - Flag to check if previous page is available.
 * @param {number} pageCount - Total number of pages.
 * @param {function} gotoPage - Function to go to a specific page.
 * @param {number} pageIndex - Current page index.
 * @param {number} pageSize - Number of rows per page.
 * @param {function} setPageSize - Function to set the number of rows per page.
 * @param {number} totalRows - Total number of rows.
 * @returns {JSX.Element} The Pagination component.
 */
function Pagination({
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    pageIndex,
    pageSize,
    setPageSize,
    totalRows
}) {
    const currentPageFirstItemIndex = pageIndex * pageSize + 1;
    const currentPageLastItemIndex = Math.min(currentPageFirstItemIndex + pageSize - 1, totalRows);


    
    return (
        <div className="review-pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <span className="review-pagination-icons">first_page</span>
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                <span className="review-pagination-icons">chevron_left</span>
            </button>

            <span className="review-page-status">
                {currentPageFirstItemIndex} - {currentPageLastItemIndex} of {totalRows}
            </span>

            <button onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="review-pagination-icons">chevron_right</span>
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <span className="review-pagination-icons">last_page</span>
            </button>
        </div>
    );
}

export default Pagination;
