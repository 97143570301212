import React from 'react';

const styles = {
  Icon: {
    color: '#4b4e56',
    fill: '#4b4e56',
    fontSize: '29px',
    top: '662px',
    left: '1303px',
    width: '29px',
    height: '21px',
  },
};

/**
 * IconComponent is a functional React component that renders an SVG icon.
 * @returns {JSX.Element} The rendered SVG icon.
 */
const IconComponent = () => (
  <div className="icon">
    <svg style={styles.Icon} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none">
      </path>
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
      </path>
    </svg>
  </div>
);

/**
 * Default props for EyeIcon.
 */
const defaultProps = {
  IconComponent,
};

/**
 * EyeIcon is a functional React component that renders an icon component.
 * It uses a custom IconComponent if provided in props, otherwise it falls back to the default IconComponent.
 * @param {Object} props - The component props.
 * @param {React.ComponentType} [props.IconComponent] - The optional custom icon component to render.
 * @returns {JSX.Element} The rendered icon component.
 */
const EyeIcon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent style={styles.Icon} /> 
      : <defaultProps.IconComponent />
  );
};

export default EyeIcon;