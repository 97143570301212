import React from "react";

/**
 * Select component for rendering a dropdown select input.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.name - The name of the select input.
 * @param {string} props.value - The current value of the select input.
 * @param {function} props.onChange - The function to call when the select value changes.
 * @param {Array} props.options - The options for the select dropdown.
 * @param {string} props.defaultOption - The default option text to display.
 * @example
 * const options = [
 *   { value: 'colleague', label: 'Colleague' },
 *   { value: 'manager', label: 'Manager' },
 *   { value: 'friend', label: 'Friend' }
 * ];
 * return (
 *   <Select
 *     name="relationship"
 *     value="colleague"
 *     onChange={(e) => console.log(e.target.value)}
 *     options={options}
 *     defaultOption="Select Relationship"
 *   />
 * )
 */
const Select = ({ name, value, onChange, options, defaultOption }) => {
  const selectClass = value
    ? "onboarding-input-field filled"
    : "onboarding-input-field";

  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className={selectClass}
    >
      <option value="">{defaultOption}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
