import React, { useState, useEffect } from "react";
import InfoSection from "./growth-library-components/InfoSection";
import CardsContainer from "./growth-library-components/CardsContainer";
import { getSuggestions, markCourseUnwanted, undoUnwantedCourse, submitCourseFeedback } from "../../apiControllers/suggestionsApiController";
import "../Styles/GrowthLibrary.css";

/**
 * GrowthLibrary component fetches and displays a collection of cards and an info section.
 *
 * @returns {JSX.Element} The GrowthLibrary component.
 */
const GrowthLibrary = () => {
  const [cardData, setCardData] = useState([]);
  const [visibleCards, setVisibleCards] = useState(6);
  const [message, setMessage] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [tempMarkedCards, setTempMarkedCards] = useState(new Set());

  /**
   * useEffect hook to fetch card data when the component mounts.
   */
  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const email = localStorage.getItem('userEmail');
        const fetchedCardData = await getSuggestions(email);

        if (Array.isArray(fetchedCardData)) {
          // Sort the card data by rating
          const sortedCardData = fetchedCardData.sort((a, b) => b.rating - a.rating);

          // Extract unique tags
          const uniqueTags = [...new Set(sortedCardData.flatMap(card => card.tags || []))];

          setCardData(sortedCardData);
          setTags(uniqueTags);
        } else {
          setMessage(fetchedCardData);
        }
      } catch (error) {
        setMessage("An error occurred while fetching card data.");
      }
    };

    fetchCardData();
  }, []);

// In GrowthLibrary.jsx
const handleMarkUnwanted = async (courseId) => {
  console.log('handleMarkUnwanted called with courseId:', courseId);
  try {
    await markCourseUnwanted(courseId);
    // Add to temporary marked set
    setTempMarkedCards(prev => new Set([...prev, courseId]));
  } catch (error) {
    console.error('Failed to mark course as unwanted:', error);
  }
};

const handleUndo = async (courseId) => {
  try {
    await undoUnwantedCourse(courseId);
    setTempMarkedCards(prev => {
      const newSet = new Set([...prev]);
      newSet.delete(courseId);
      return newSet;
    });
  } catch (error) {
    console.error('Failed to undo unwanted status:', error);
  }
};

const handleSubmitFeedback = async (courseId, feedback) => {
  try {
    await submitCourseFeedback(courseId, feedback);
  } catch (error) {
    console.error('Failed to submit feedback:', error);
  }
};

  /**
   * Handler to increase the number of visible cards by 6.
   */
  const handleViewMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 6);
  };

  /**
   * Handler to filter cards by tag.
   */
  const handleTagFilter = (tag) => {
    setSelectedTag(tag);
  };

  /**
   * Convert tag label to title case.
   */
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  /**
   * Filtered card data based on the selected tag.
   */
  const filteredCardData = selectedTag
    ? cardData.filter(card => card.tags && card.tags.includes(selectedTag))
    : cardData;

  return (
    <div className="growth-library-parent">
      <div className="growth-library-container">
        <div className="growth-library-content">
          <InfoSection />
          {message ? (
            <div className="growth-library-message">{message}</div>
          ) : (
            <>
              {tags.length >= 2 && (
                <div className="growth-library-tag-buttons-container">
                  <div className="growth-library-filter-label">
                    Browse by category
                  </div>
                  <button onClick={() => handleTagFilter("")} className={!selectedTag ? 'active' : ''}>
                    All
                  </button>
                  {tags.map(tag => (
                    <button
                      key={tag}
                      onClick={() => handleTagFilter(tag)}
                      className={selectedTag === tag ? 'active' : ''}
                    >
                      {toTitleCase(tag)}
                    </button>
                  ))}
                </div>
              )}
              <CardsContainer
                cardData={filteredCardData}
                visibleCards={visibleCards}
                onViewMore={handleViewMore}
                onMarkUnwanted={handleMarkUnwanted}
                tempMarkedCards={tempMarkedCards}
                onUndo={handleUndo}
                onSubmitFeedback={handleSubmitFeedback}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GrowthLibrary;
