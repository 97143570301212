import "../Styles/Insights/Insights.css";
import "../Styles/Insights/Attributes.css";
import {groupReviews, calculateRelationshipAvg, likertToPercentage, getBoxClass} from "./CompetencySummaryCard";

// Define category classes with corresponding category identifiers
const categories = [
    {className: 'initials-row-development', category: 'development-needed'},
    {className: 'initials-row-progressing', category: 'progressing'},
    {className: 'initials-row-competent', category: 'competent'},
    {className: 'initials-row-strong', category: 'strong'},
    {className: 'initials-row-outstanding', category: 'outstanding'},
];

// Sample attributes data structure with keys for each item
const attributes = [
    {
        category: {
            key: "Leadership & Empowering Others",
            value: "leadership"
        },
        items: [
            {name: "Motivating & Developing Others", key: "sc_motivating_avg"},
            {name: "Leaderships & Influence", key: "sc_influence_avg"},
        ],
    },
    {
        category: {
            key: "Collaboration and Communication",
            value: "collaboration"
        },
        items: [
            {name: "Interpersonal Relationships", key: "sc_interpersonal_avg"},
            {name: "Communication", key: "sc_communication_avg"},
        ],
    },
    {
        category: {
            key: "Work Ethic & Values",
            value: "work_ethic"
        },
        items: [
            {name: "Integrity", key: "sc_integrity_avg"},
            {name: "Professional Competence", key: "sc_professional_avg"},
        ],
    },
    {
        category: {
            key: "Results Oriented Adaptability",
            value: "results_oriented"
        },
        items: [
            {name: "Productivity", key: "sc_productivity_avg"},
            {name: "Flexibility & Stress Management", key: "sc_flexibility_avg"},
            {name: "Decision Making", key: "sc_decision_avg"},
        ],
    },
    {
        category: {
            key: "Business Acumen & Strategic Thinking",
            value: "business_acumen"
        },
        items: [
            {name: "Business Acumen", key: "sc_acumen_avg"},
            {name: "Planning & Organization", key: "sc_planning_avg"},
        ],
    },
];

/**
 * Renders the Attributes Card, assigns the score in each section to the correct column for
 * each relationship. Multiple reviews in relationships are also aggregated. SEE: Functions in
 * CompetencySummaryCard.jsx
 *
 * @param competencyData - Refined graph data prop passed in from insights.jsx
 * @returns {JSX.Element} Rendered AttributesCard react component
 */
const AttributesCard = ({competencyData}) => {
    const competencyItems = competencyData || [];

    //console.log("Attributes Competency data: ", competencyData);

    // Group reviews based on their relationship
    const groupedReviews = groupReviews(competencyItems);

    // Average reviews scores for each relationship "ARRAY"
    const aggregateData = calculateRelationshipAvg(groupedReviews);

    //console.log("Aggregated data: ", aggregateData);


    return (
        <div className="attributes-card">
            <div className="attributes-title">Attributes Breakdown</div>
            {attributes.map((attribute) => (
                <div className="section" key={attribute.category.value}>
                    <div className="attributes-section-title">{attribute.category.key}</div>
                    {attribute.items.map((item) => (
                        <div key={item.key} className="attribute-item">
                            <span className="attributes-subsection-label">{item.name}</span>

                            {/* Row for Reviewer Initials for each subsection */}
                            <div className="initials-row">
                                {categories.map(({className, category}) => (
                                    <div key={category} className={className}>
                                        {aggregateData
                                            .map((data, reviewIdx) => {
                                                const initials = data.relationship.charAt(0).toUpperCase();
                                                //console.log("Initials", initials);
                                                const score = data[attribute.category.value][item.key];
                                                //console.log("Score", score);
                                                const likertScore = likertToPercentage(score);
                                                //console.log("Likert", likertScore);
                                                const boxClass = getBoxClass(likertScore);
                                                //console.log("Box", boxClass);

                                                if (boxClass !== category) return null;

                                                return (
                                                    <div
                                                        key={`${reviewIdx}-${item.key}`}
                                                        className={`initials-box-${boxClass}`}
                                                    >
                                                        <span className="initials">{initials}</span>
                                                    </div>
                                                );
                                            })
                                            .filter(Boolean) // Remove nulls
                                        }
                                    </div>
                                ))}
                            </div>

                            {/* Progress Bar */}
                            <div className="progress-container">
                                <div className="attributes-grid">
                                    <div className="attributes-box development-needed"></div>
                                    <div className="attributes-box progressing"></div>
                                    <div className="attributes-box competent"></div>
                                    <div className="attributes-box strong"></div>
                                    <div className="attributes-box outstanding"></div>

                                    <span className="attributes-box-description">Development Needed</span>
                                    <span className="attributes-box-description">Progressing</span>
                                    <span className="attributes-box-description">Competent</span>
                                    <span className="attributes-box-description">Strong</span>
                                    <span className="attributes-box-description">Outstanding</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default AttributesCard;
