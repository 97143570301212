import React from "react";
import "../Styles/Goals.css";

const GoalsScaleOptions = ({
  options,
  selected,
  setSelected,
  setScale,
  toggleDropdown,
}) => {
  const selectOption = (event) => {
    const value = event.target.getAttribute("data-value");
    if (value === selected) {
      setSelected("");
      setScale("");
    } else {
      setSelected(value);
      setScale(value);
    }
    toggleDropdown();
  };

  return (
    <>
      {options.map((option, index) => {
        return (
          <div
            key={`scale${index}`}
            className={
              option === selected
                ? "goals-scale-options active"
                : "goals-scale-options"
            }
            data-value={option}
            onClick={selectOption}
          >
            {option}
          </div>
        );
      })}
    </>
  );
};

export default GoalsScaleOptions;
