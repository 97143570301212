import React from "react";
import SelfAssessmentForm from "./SelfAssessmentFormComponents/SelfAssessmentForm";

/**
 * ReflectiveEvaluation component renders a container for the self-assessment form.
 *
 * @returns {JSX.Element} The ReflectiveEvaluation component.
 */
const ReflectiveEvaluation = ({ type }) => {
    return (
        <div className="self-assessment-form-container">
            <SelfAssessmentForm type={type}/>
        </div>
    );
};

export default ReflectiveEvaluation;
