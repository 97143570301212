import React from "react";
import ReviewsTable from "./ReviewsTable";
// import DownloadIcon from '../assets/DownloadIcon';
// import SettingsIcon from '../assets/SettingsIcon';

const text = require("../resources/feedback-texts.json");

/**
 * TableContainer component renders the reviews table and header.
 *
 * @param {Object[]} data - The data to be displayed in the reviews table.
 * @param {string} selfReviewId - The ID of the self-review.
 * @returns {JSX.Element} The TableContainer component.
 */
const TableContainer = ({ data, selfReviewId }) => {
  return (
    <div className="table-container">
      <div className="table-header">
        <span>{text.table.header}</span>
        <div className="controls">
          {/* Comment out until functionality is needed
                    <button className="table-control download-btn">
                        <DownloadIcon />
                    </button>
                    <button className="table-control setting-btn">
                        <SettingsIcon />
                    </button> */}
        </div>
      </div>
      <ReviewsTable
        data={data}
        selfReviewId={selfReviewId}
        className="reviews-table"
      />
    </div>
  );
};

export default TableContainer;
