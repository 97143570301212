/**
 * This module is for managing API calls to the server's milestones endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const createMilestone = async (milestoneData) => {
  try {
    const response = await api.post(
      `${apiUrl}/v1/milestones/single`,
      milestoneData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMilestonesByGoalId = async (goalId) => {
  try {
    const response = await api.get(
      `${apiUrl}/v1/milestones/all/goal/${goalId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMilestone = async (milestoneId, milestoneData) => {
  try {
    const response = await api.put(
      `${apiUrl}/v1/milestones/single`,
      milestoneData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMilestone = async (milestoneId) => {
  try {
    const response = await api.delete(`${apiUrl}/v1/milestones/single/${milestoneId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
