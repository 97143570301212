/**
 * NotificationSettings Component
 *
 * This component renders the notification settings page, allowing the user to configure
 * their email and SMS notification preferences.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered NotificationSettings component.
 *
 * @example
 * return <NotificationSettings />;
 */

import React from 'react';
import '../Styles/NotificationSettings.css';
import HorizontalDivider from './HorizontalDivider';

const NotificationSettings = () => {
    return (
        <div className="notification-settings">
            <HorizontalDivider />
            <h2>Notification Settings</h2>
            <form>
                <div className="notification-settings-form-group">
                    <label className="notification-settings-form-label">Email Notifications</label>
                    <input type="checkbox" /> Enable email notifications
                </div>
                <div className="notification-settings-form-group">
                    <label className="notification-settings-form-label">SMS Notifications</label>
                    <input type="checkbox" /> Enable SMS notifications
                </div>
                <button className="notification-settings-button" type="submit">Save Changes</button>
            </form>
        </div>
    );
};

export default NotificationSettings;
