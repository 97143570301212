import React, { useState, useEffect } from "react";
import { getMilestonesByGoalId } from "../../../../../apiControllers/milestoneApiController";
import CreateMilestoneModal from "./CreateMilestoneModal";
import EditMilestoneModal from "./EditMilestoneModal";
import "../../../../../components/Styles/MilestoneSection.css";
import { updateMilestone } from "../../../../../apiControllers/milestoneApiController";


const MilestoneSection = ({ goalId, onMilestoneChange }) => {
  const [milestonesData, setMilestonesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);

  useEffect(() => {
    const fetchMilestones = async () => {
      console.log("Fetching milestones for goal ID:", goalId);
      try {
        if (goalId) {
          const milestones = await getMilestonesByGoalId(goalId);
          console.log("Fetched milestones:", milestones);

          setMilestonesData(milestones || []);

          // Notify the parent about milestone changes
          if (onMilestoneChange) {
            console.log("Notifying parent about initial milestones...");
            onMilestoneChange(milestones);
          }
        }
        setLoading(false);
      } catch (err) {
        console.error("Error fetching milestones:", err);
        if (err.response?.status !== 404) {
          setError("Failed to fetch milestones");
        } else {
          setMilestonesData([]);
        }
        setLoading(false);
      }
    };

    fetchMilestones();
  }, [goalId]);

  const handleStatusChange = async (milestoneId, newStatus) => {
    try {
      const milestoneData = { milestone_id: milestoneId, status: newStatus };

      const updatedMilestone = await updateMilestone(milestoneId, milestoneData);

      const updatedMilestones = milestonesData.map((m) =>
        m.milestone_id === milestoneId ? updatedMilestone : m
      );
      setMilestonesData(updatedMilestones);

      if (onMilestoneChange) {
        onMilestoneChange(updatedMilestones);
      }
    } catch (error) {
      console.error("Error updating milestone status:", error);
    }
  };

  const handleAddMilestone = (newMilestone) => {
    console.log("Adding new milestone:", newMilestone);
    const updatedMilestones = [...milestonesData, newMilestone];
    console.log("Updated milestones after addition:", updatedMilestones);

    setMilestonesData(updatedMilestones);
    handleCloseCreateModal();

    if (onMilestoneChange) {
      console.log("Notifying parent about new milestone...");
      onMilestoneChange(updatedMilestones);
    }
  };

  const handleSaveMilestone = (updatedMilestone) => {
    console.log("Saving updated milestone:", updatedMilestone);
    const updatedMilestones = milestonesData.map((m) =>
      m.milestone_id === updatedMilestone.milestone_id ? updatedMilestone : m
    );
    console.log("Updated milestones after saving:", updatedMilestones);

    setMilestonesData(updatedMilestones);
    handleCloseEditModal();

    if (onMilestoneChange) {
      console.log("Notifying parent about updated milestone...");
      onMilestoneChange(updatedMilestones);
    }
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleOpenEditModal = (milestone) => {
    setSelectedMilestone(milestone);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedMilestone(null);
    setIsEditModalOpen(false);
  };

  const formatClassName = (status) => {
    return status.toLowerCase().replace(" ", "-");
  };

  if (loading) {
    console.log("Loading milestones...");
    return <p>Loading milestones...</p>;
  }

  if (error) {
    console.log("Error:", error);
    return <p>{error}</p>;
  }

  return (
    <div
      className={
        milestonesData.length > 0
          ? "milestones-grid-two-columns"
          : "milestones-grid-one-column"
      }
    >
      {milestonesData.length === 0 ? (
        <div className="no-milestones-prompt">
          <h2>Add your first milestone</h2>
          <p>Break down your goal into smaller, manageable milestones</p>
          <button className="create-milestone-button" onClick={handleOpenCreateModal}>
            Create a milestone
          </button>
        </div>
      ) : (
        <>
          {milestonesData.map((milestone) => (
            <div
              key={milestone.milestone_id}
              className={`milestone-inside ${formatClassName(milestone.status)}`}
            >
              <h3>
                {milestone.title}
                <span
                  className="material-symbols-outlined edit-symbol"
                  onClick={() => handleOpenEditModal(milestone)}
                >
                  edit
                </span>
              </h3>
              <p>{milestone.description}</p>
              <p>
                Start Date: {milestone.start_date} | End Date: {milestone.deadline}
              </p>
              <p>
                Status: {milestone.status}
              </p>
              <p>
                <select
                  className={`milestone-status-dropdown ${milestone.status.toLowerCase().replace(' ', '-')}`}
                  value={milestone.status}
                  onChange={(e) => handleStatusChange(milestone.milestone_id, e.target.value)}
                >
                  <option value="Not Started">Incomplete</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </p>
            </div>
          ))}

          <p className="create-milestone-link" onClick={handleOpenCreateModal}>
            + Create a new milestone
          </p>
        </>
      )}

      <CreateMilestoneModal
        isOpen={isCreateModalOpen}
        onClose={handleCloseCreateModal}
        goalId={goalId}
        onAddMilestone={handleAddMilestone}
      />

      {isEditModalOpen && selectedMilestone && (
        <EditMilestoneModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          milestone={selectedMilestone}
          onSave={handleSaveMilestone}
        />
      )}
    </div>
  );
};

export default MilestoneSection;