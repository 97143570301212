import React, { useMemo, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import COLUMNS from "./reviews-columns";
import EyeIcon from "../assets/EyeIcon";
import CrossedEyeIcon from "../assets/CrossedEyeIcon";
import Pagination from "./Pagination";

/**
 * ReviewsTable component renders a table for displaying reviews with pagination and sorting.
 *
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {string} selfReviewId - The ID of the self-review.
 * @returns {JSX.Element} The ReviewsTable component.
 */
function ReviewsTable({ data = [], selfReviewId }) {
  const emailInLocalStorage = localStorage.getItem("userEmail");

  /**
   * Memoized sorting function to move rows with matching emails to the top.
   */
   {/* Comment out until functionality is needed
  const sortedData = useMemo(() => {
    return data.slice().sort((a, b) => {
      if (a.email === emailInLocalStorage && b.email !== emailInLocalStorage) {
        return -1;
      } else if (
        a.email !== emailInLocalStorage &&
        b.email === emailInLocalStorage
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }, [data, emailInLocalStorage]);
    */}

  /**
   * Memoized columns definition including selection and report columns.
   */
  const columns = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const props = getToggleAllRowsSelectedProps();
          const checkboxRef = useRef(null);
          useEffect(() => {
            if (checkboxRef.current) {
              checkboxRef.current.indeterminate = props.indeterminate;
            }
          }, [props.indeterminate]);
          return (
            <div className="checkbox">
              <input
                type="checkbox"
                ref={checkboxRef}
                {...props}
                className="checkbox-input"
              />
            </div>
          );
        },
        Cell: ({ row }) => {
          const props = row.getToggleRowSelectedProps();
          const checkboxRef = useRef(null);
          useEffect(() => {
            if (checkboxRef.current) {
              checkboxRef.current.indeterminate = props.indeterminate;
            }
          }, [props.indeterminate]);
          return (
            <div className="checkbox">
              <input
                type="checkbox"
                ref={checkboxRef}
                {...props}
                className="checkbox-input"
              />
            </div>
          );
        },
        disableSortBy: true,
      },
      ...COLUMNS,
      {
        id: "report",
        Header: "Report",
        accessor: "status",
        Cell: ({ value, row }) => {
          // Decide which icon to render based on the status
          if (row.original.type === "self") {
            return null;
          }

          switch (value) {
            case "Completed":
              return selfReviewId ? (
                <Link
                  to={`/feedback/report?selfReviewId=${selfReviewId}&peerReviewId=${row.original.reviewId}`}
                >
                  <EyeIcon />
                </Link>
              ) : null;
            case "Started":
              return <CrossedEyeIcon />;
            default:
              return null;
          }
        },
      },
    ],
    []
  );

  /**
   * Destructure functions and state variables from useTable hook.
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="th"
                >
                  {column.render("Header")}
                  {column.id !== "selection" && (
                    <span className="review-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "arrow_upward"
                          : "arrow_downward"
                        : ""}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            // Check if the email matches the one in localStorage
            const isEmailMatch = row.original.email === emailInLocalStorage;
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                // Apply the bold-row class if emails match
                className={`tr ${isEmailMatch ? "feedback-bold-row" : ""}`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      key={cell.column.id}
                      {...cell.getCellProps()}
                      className={`td ${
                        cell.column.id === "report" ? "report-cell" : ""
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        setPageSize={setPageSize}
        totalRows={data.length}
      />
    </>
  );
}

export default ReviewsTable;
