import React from "react";

/**
 * Image component for rendering a background image within a div container.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.id - The id of the div container.
 * @param {string} props.image - The URL of the image to be used as the background.
 * @example
 * return (
 *   <Image id="exampleImage" image="https://example.com/image.jpg" />
 * )
 */
const Image = (props) => {
  return (
    <div
      id={props.id}
      className="onboarding-image-container"
      style={{ backgroundImage: `url(${props.image})` }}
    />
  );
};

export default Image;
