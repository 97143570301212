import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar, Bar } from "react-chartjs-2";
import { getFeedbackGraphData } from "../../apiControllers/feedbackApiController";
import { useSearchParams } from "react-router-dom";

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/**
 * Component to render a Radar chart.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the Radar chart.
 * @param {Object} props.options - The options for the Radar chart.
 * @returns {JSX.Element} - The Radar chart component.
 */
const RadarChart = ({ data, options }) => (
  <div className="fb-report-competency-graph-container-radar">
    <div className="fb-report-competency-graph-title">
      <span>Competency Radar Chart</span>
    </div>
    <div className="fb-report-radar-chart">
      <Radar data={data} options={options} />
    </div>
  </div>
);

/**
 * Component to render a Bar chart.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.data - The data for the Bar chart.
 * @param {Object} props.options - The options for the Bar chart.
 * @returns {JSX.Element} - The Bar chart component.
 */
const BarChart = ({ data, options }) => (
  <div className="fb-report-competency-graph-container-bar">
    <div className="fb-report-competency-graph-title">
      <span>Competency Summary Breakdown</span>
    </div>
    <Bar data={data} options={options} />
  </div>
);

/**
 * Custom hook to fetch feedback data.
 *
 * @param {string} selfId - The self-review ID.
 * @param {string} reviewId - The peer-review ID.
 * @returns {Object} - The feedback data and error state.
 */
const useFeedbackData = (selfId, reviewId) => {
  const [selfData, setSelfData] = useState(null);
  const [reviewerData, setReviewerData] = useState(null);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [selfResult, reviewerResult] = await Promise.all([
          getFeedbackGraphData(searchParams.get("selfReviewId")),
          getFeedbackGraphData(searchParams.get("peerReviewId")),
        ]);
        setSelfData(selfResult);
        setReviewerData(reviewerResult);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      }
    };

    fetchData();
  }, [selfId, reviewId]);

  return { selfData, reviewerData, error };
};

/**
 * Component to render the Radar and Bar charts based on feedback data.
 *
 * @param {Object} props - The component props.
 * @param {string} props.reviewId - The review ID.
 * @param {string} props.selfId - The self ID.
 * @returns {JSX.Element} - The charts component.
 */
const Charts = ({ reviewId, selfId }) => {
  const { selfData, reviewerData, error } = useFeedbackData(selfId, reviewId);

  if (error) {
    return <div>{error}</div>;
  }

  if (!selfData || !reviewerData) {
    return <div>Loading...</div>;
  }

  const categories = [
    "Leadership & Empowering Others",
    "Collaboration & Communication",
    "Work Ethic & Values",
    "Results Oriented & Adaptability",
    "Business Acumen & Strategic Thinking",
  ];

  const radarLabels = categories.map(category => {
                        const [firstPart, secondPart] = category.split(' & ');
                        return [firstPart + ' &', secondPart];
                      });

  const categoryKeys = {
    "Leadership & Empowering Others": "leadership",
    "Collaboration & Communication": "collaboration",
    "Work Ethic & Values": "work_ethic",
    "Results Oriented & Adaptability": "results_oriented",
    "Business Acumen & Strategic Thinking": "business_acumen",
  };

  const subCategories = {
    leadership: ["Motivation", "Influence"],
    collaboration: ["Interpersonal", "Communication"],
    work_ethic: ["Interpersonal", "Communication"],
    results_oriented: ["Interpersonal", "Communication", "Decision"],
    business_acumen: ["Interpersonal", "Communication"],
  };

  /**
   * Maps the feedback data to the appropriate structure for charting.
   *
   * @param {Object} data - The feedback data.
   * @returns {Object} - The mapped values.
   */
  const mapValues = (data) => ({
    leadership: [
      data.leadership.c_leadership_avg,
      data.leadership.sc_motivating_avg,
      data.leadership.sc_influence_avg,
    ],
    collaboration: [
      data.collaboration.c_collaboration_avg,
      data.collaboration.sc_interpersonal_avg,
      data.collaboration.sc_communication_avg,
    ],
    work_ethic: [
      data.work_ethic.c_work_ethic_avg,
      data.work_ethic.sc_professional_avg,
      data.work_ethic.sc_integrity_avg,
    ],
    results_oriented: [
      data.results_oriented.c_results_avg,
      data.results_oriented.sc_productivity_avg,
      data.results_oriented.sc_flexibility_avg,
      data.results_oriented.sc_decision_avg,
    ],
    business_acumen: [
      data.business_acumen.c_business_avg,
      data.business_acumen.sc_acumen_avg,
      data.business_acumen.sc_planning_avg,
    ],
  });

  const selfValues = mapValues(selfData);
  const reviewerValues = mapValues(reviewerData);

  const radarData = {
    labels: radarLabels,
    datasets: [
      {
        label: "Self-Assessment",
        data: categories.map(
          (category) => selfValues[categoryKeys[category]]?.[0]
        ),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Reviewer",
        data: categories.map(
          (category) => reviewerValues[categoryKeys[category]]?.[0]
        ),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barData = {
    labels: categories.flatMap((category) => {
      const key = categoryKeys[category];
      return [category, ...(subCategories[key] || [])];
    }),
    datasets: [
      {
        label: "Self-Assessment Average",
        data: categories.flatMap((category) => {
          const key = categoryKeys[category];
          return [selfValues[key]?.[0], ...(selfValues[key]?.slice(1) || [])];
        }),
        backgroundColor: categories.flatMap((category) => {
          const key = categoryKeys[category];
          return [
            "rgba(54, 162, 235, 0.8)", // Category color
            ...new Array(subCategories[key]?.length || 0).fill(
              "rgba(54, 162, 235, 0.4)"
            ), // Subcategory color
          ];
        }),
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Reviewer Average",
        data: categories.flatMap((category) => {
          const key = categoryKeys[category];
          return [
            reviewerValues[key]?.[0],
            ...(reviewerValues[key]?.slice(1) || []),
          ];
        }),
        backgroundColor: categories.flatMap((category) => {
          const key = categoryKeys[category];
          return [
            "rgba(255, 99, 132, 0.8)", // Category color
            ...new Array(subCategories[key]?.length || 0).fill(
              "rgba(255, 99, 132, 0.4)"
            ), // Subcategory color
          ];
        }),
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const radarOptions = {
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
            display: false, // Turn off tick numbers
          stepSize: 1,
          font: {
            size: 12,
          },
        },
        pointLabels: {
          font: {
            size: 14, // Adjust this value to increase/decrease font size for labels
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16, // Adjust this value to increase/decrease font size for legend
          },
        },
      },
    },
  };

  const barOptions = {
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          generateLabels: (chart) => {
            const data = chart.data;
            return [
              {
                text: "Self-Assessment Average",
                fillStyle: "rgba(54, 162, 235, 0.8)",
                hidden: false,
                lineCap: "butt",
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: "miter",
                strokeStyle: "rgba(54, 162, 235, 1)",
                pointStyle: "circle",
                datasetIndex: 0,
              },
              {
                text: "Self-Assessment Individual Competency",
                fillStyle: "rgba(54, 162, 235, 0.4)",
                hidden: false,
                lineCap: "butt",
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: "miter",
                strokeStyle: "rgba(54, 162, 235, 1)",
                pointStyle: "circle",
                datasetIndex: 0,
              },
              {
                text: "Reviewer Average",
                fillStyle: "rgba(255, 99, 132, 0.8)",
                hidden: false,
                lineCap: "butt",
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: "miter",
                strokeStyle: "rgba(255, 99, 132, 1)",
                pointStyle: "circle",
                datasetIndex: 1,
              },
              {
                text: "Reviewer Individual Competency",
                fillStyle: "rgba(255, 99, 132, 0.4)",
                hidden: false,
                lineCap: "butt",
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: "miter",
                strokeStyle: "rgba(255, 99, 132, 1)",
                pointStyle: "circle",
                datasetIndex: 1,
              },
            ];
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value, index, values) {
            const label = this.getLabelForValue(value);
            if (categories.includes(label)) {
              return `${label}`;
            }
            return label;
          },
          font: function (context) {
            const label = context.tick.label;
            if (categories.includes(label)) {
              return {
                weight: "bold",
              };
            }
            return {};
          },
        },
      },
    },
  };

  return (
    <div className="fb-report-competency-graph-section">
      <RadarChart data={radarData} options={radarOptions} />
      <BarChart data={barData} options={barOptions} />
    </div>
  );
};

export default Charts;
