import React from "react";
import "../Styles/PeerFeedback.css";
import ReflectiveEvaluation from "../SelfAssessment/ReflectiveEvaluation";
import image from "../SelfAssessment/Assets/self-assessment-image.png";

/**
 * Renders the PeerFeedback component which displays the peer feedback section.
 *
 * @return {JSX.Element} The rendered PeerFeedback component.
 */
const PeerFeedback = () => {
  return (
    <>
      <div className="parent">
        <div className="container">
          <div className="self-assessment-container">
            <div className="self-assessment-img-container-comp peer-feedback-img">
              <img src={image} alt="Self-Assessment" />
            </div>
            <ReflectiveEvaluation type={"peer"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PeerFeedback;
