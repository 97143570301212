import React from "react";
import { useLocation, Link } from "react-router-dom";
import "../Styles/OnBoarding.css";
import Icon from "./Icon.jsx";
import Card from "./Card.jsx";
import Image from "./Image.jsx";
import Button from "./Button.jsx";
import Text from "./Text.jsx";

/**
 * StepOne component for rendering the first step of the onboarding process.
 *
 * @component
 * @example
 * return (
 *   <StepOne />
 * )
 */
const StepOne = () => {
  const location = useLocation();
  const { user } = location.state || {};
  const userName = user ? user.first_name : "User";

  return (
    <div className="onboarding-container">
      <Card>
        <div className="onboarding-content">
          <div className="onboarding-header-and-image">
            <div className="onboarding-text-section">
              <div className="onboarding-icon-text">
                <Icon type={"wave"} />
                <div className="onboarding-card-title">
                  Welcome to Ofstaff, {userName}!
                </div>
              </div>
              <Text text="Let's get started with setting up your references" />
              <div className="onboarding-icon-text">
                <Icon type={"people"} />
                <Text text="Identify at least 2 people to ask for a reference" />
              </div>
              <div className="onboarding-icon-text">
                <Icon type={"airplane"} />
                <Text text="Send a request for references to fill out a survey" />
              </div>
              <div className="onboarding-icon-text">
                <Icon type={"check"} />
                <Text text="References complete the survey" />
              </div>
              <div className="onboarding-icon-text">
                <Icon type={"summary"} />
                <Text text="Summary report is created for each reference*" />
              </div>
              <div className="onboarding-icon-text">
                <Icon type={"rocket"} />
                <Text text="You can now share references and or use them for your own development" />
              </div>
              <div className="onboarding-actions">
                <Link
                  to="/OnBoarding/step-two"
                  className="onboarding-next-link"
                >
                  <Button label="Next" />
                </Link>
              </div>
            </div>
            <Image
              image={ "https://assets.api.uizard.io/api/cdn/stream/8b6dfccf-a76e-4409-abf9-1053143f487a.png" }
              id="stepOneImage"
            />
          </div>
        </div>
        <div className="onboarding-note-one">
          <Text text="* Self-assessment must be completed before a report can be viewed." />
        </div>
      </Card>
    </div>
  );
};

export default StepOne;
