import React, { useEffect, useState } from "react";
import "../Styles/Feedback.css";
import TableContainer from "./feedback-components/TableContainer";
import ReviewStarter from "./feedback-components/ReviewStarter";
import RequestFeedback from "./feedback-components/RequestFeedback";
import { getAllReviewsByEmail } from "../../apiControllers/reviewApiController";

/**
 * Feedback component manages and displays feedback reviews.
 *
 * @returns {JSX.Element} The Feedback component.
 */
const Feedback = () => {
  const [reviews, setReviews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selfReviewId, setSelfReviewId] = useState("");
  const [hasCalledApi, setHasCalledApi] = useState(false);
  const [error, setError] = useState(null);
  const [selfReview, setSelfReview] = useState({ status: null });

  /**
   * Opens the modal for requesting feedback.
   */
  const openModal = () => setIsModalOpen(true);

  /**
   * Closes the modal for requesting feedback.
   */
  const closeModal = () => setIsModalOpen(false);

  /**
   * Fetches all reviews by email when the component mounts.
   */
  useEffect(() => {
    const apiCalls = async () => {
      try {
        const result = await getAllReviewsByEmail(
          localStorage.getItem("userEmail")
        );
        result.forEach((review) => {
          if (review.type === "self") {
            setSelfReviewId(review.reviewId);
            setSelfReview(review);
          }
        });
        setReviews(result);
      } catch (error) {
        setError(error.message);
      }
      setHasCalledApi(true);
    };

    if (!hasCalledApi) {
      apiCalls();
    }
  }, [selfReviewId, hasCalledApi]);

  return (
    hasCalledApi && (
      <div className="parent">
        <div className="container">
          <div className="feedback">
            <ReviewStarter openModal={openModal} selfReview={selfReview} />
            {error ? (
              <div className="feedback-error">{error}</div>
            ) : (
              <TableContainer data={reviews} selfReviewId={selfReviewId} />
            )}
            {isModalOpen && (
              <div className="request-feedback-overlay">
                <RequestFeedback closeModal={closeModal} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Feedback;
