import React, { useState, useEffect } from "react";
import {
    createReflections,
    getReflectionsByGoalId,
    updateReflections,
} from "../../../../apiControllers/reflectionsApiController";
import "../../../Styles/ReflectionsSection.css";

const ReflectionsSection = ({ goalId }) => {
    const [reflections, setReflections] = useState({
        new_skills: "",
        challenges_faced: "",
        proud_moments: "",
        goal_achievement: "",
    });

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchReflections = async () => {
            try {
                const response = await getReflectionsByGoalId(goalId);
                if (response) {
                    setReflections(response); 
                    setIsEditing(true);
                }
            } catch (error) {
                console.error("Error fetching reflections:", error);
            }
        };

        if (goalId) fetchReflections();
    }, [goalId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReflections((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await updateReflections({ goal_id: goalId, ...reflections });
            } else {
                await createReflections({ goal_id: goalId, ...reflections });
            }
        } catch (error) {
            console.error("Error submitting reflections:", error);
        }
    };

    return (
        <div className="goal-reflections-container">
            <div className="reflections-section-header">
                <span className="material-symbols-outlined custom-symbol-size">
                    draw
                </span>
                <span className="reflections-title">Reflections</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="new_skills">
                        What new skills have you acquired during this goal
                        pursuit?
                    </label>
                    <textarea
                        id="new_skills"
                        name="new_skills"
                        value={reflections.new_skills}
                        onChange={handleChange}
                        className="reflections-textarea"
                    />
                </div>
                <div>
                    <label htmlFor="challenges_faced">
                        What challenges have you faced and what did you learn
                        from this experience?
                    </label>
                    <textarea
                        id="challenges_faced"
                        name="challenges_faced"
                        value={reflections.challenges_faced}
                        onChange={handleChange}
                        className="reflections-textarea"
                    />
                </div>
                <div>
                    <label htmlFor="proud_moments">
                        What went well and what are you the most proud of?
                    </label>
                    <textarea
                        id="proud_moments"
                        name="proud_moments"
                        value={reflections.proud_moments}
                        onChange={handleChange}
                        className="reflections-textarea"
                    />
                </div>
                <div>
                    <label htmlFor="goal_achievement">
                        Did you achieve your goal? Why or why not?
                    </label>
                    <textarea
                        id="goal_achievement"
                        name="goal_achievement"
                        value={reflections.goal_achievement}
                        onChange={handleChange}
                        className="reflections-textarea"
                    />
                </div>
                <div className="submit-container">
                    <button type="submit" className="submit-button">
                        Save Reflections
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ReflectionsSection;
