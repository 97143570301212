/**
 * AccountSettings Component
 *
 * This component renders the account settings page, allowing the user to update their profile,
 * security settings, and notification settings. It fetches user data on mount and provides
 * form handling for updating user information.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered AccountSettings component.
 *
 * @example
 * return <AccountSettings />;
 */

import React, { useState, useEffect } from "react";
import "../Styles/AccountSettings.css";
import ProfileIcon from "./Icon-Profile";
import SecurityIcon from "./Icon-Security";
import NotificationIcon from "./Icon-Notification";
import HorizontalDivider from "./HorizontalDivider";
import ProfileImage from "./ProfileImage";
import SecuritySettings from "./SecuritySettings";
import NotificationSettings from "./NotificationSettings";
import {
  getUserData,
  updateUser,
} from "../../apiControllers/userApiController";

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    title: "",
    about: "",
    city: "",
    country: "",
    image:
      "https://assets.api.uizard.io/api/cdn/stream/068a1949-ca93-4983-ba8f-ad98414e3090.png",
    company: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        setUser({
          first_name: userData.first_name || "",
          last_name: userData.last_name || "",
          email_address: userData.email_address || "",
          title: userData.title || "",
          about: userData.about || "",
          city: userData.city || "",
          country: userData.country || "",
          image:
            userData.image ||
            "https://assets.api.uizard.io/api/cdn/stream/068a1949-ca93-4983-ba8f-ad98414e3090.png",
          company: userData.company || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: name === "email_address" ? value.toLowerCase() : value,
    }));
  };

  const handleImageChange = (image) => {
    setUser((prevUser) => ({
      ...prevUser,
      image: image,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(user);
      window.location.reload();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="account-settings">
      <main className="account-settings-profile">
        <div className="account-settings-tabs">
          <button
            className={`account-settings-tab-button ${
              activeTab === "profile" ? "account-settings-tab-highlighted" : ""
            }`}
            onClick={() => setActiveTab("profile")}
          >
            <ProfileIcon /> <span>Profile</span>
          </button>
          <button
            className={`account-settings-tab-button ${
              activeTab === "security" ? "account-settings-tab-highlighted" : ""
            }`}
            onClick={() => setActiveTab("security")}
          >
            <SecurityIcon /> <span>Security & Privacy</span>
          </button>
          <button
            className={`account-settings-tab-button ${
              activeTab === "notification"
                ? "account-settings-tab-highlighted"
                : ""
            }`}
            onClick={() => setActiveTab("notification")}
          >
            <NotificationIcon /> <span>Notification Settings</span>
          </button>
        </div>
        <div className="account-settings-container">
          <HorizontalDivider />
          {activeTab === "profile" && (
            <>
              <h2>Profile Settings</h2>
              <div className="account-settings-profile-image">
                <ProfileImage
                  image={user.image}
                  onImageChange={handleImageChange}
                />
              </div>
              <form onSubmit={handleSubmit}>
                <div className="account-settings-form-group-row">
                  <div className="account-settings-form-group">
                    <label className="account-settings-form-label">
                      First Name*
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={user.first_name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-settings-form-group">
                    <label className="account-settings-form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={user.last_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="account-settings-form-group">
                  <label className="account-settings-form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email_address"
                    placeholder="email@example.com"
                    value={user.email_address}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="account-settings-form-group">
                  <label className="account-settings-form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Manager"
                    value={user.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="account-settings-form-group">
                  <label className="account-settings-form-label">Company</label>
                  <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    value={user.company}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="account-settings-form-group">
                  <label className="account-settings-form-label">
                    About You
                  </label>
                  <textarea
                    name="about"
                    placeholder="Write a summary about yourself"
                    value={user.about}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="account-settings-form-group-row">
                  <div className="account-settings-form-group">
                    <label className="account-settings-form-label">
                      City/Town
                    </label>
                    <input
                      type="text"
                      name="city"
                      placeholder="Vancouver"
                      value={user.city}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-settings-form-group">
                    <label className="account-settings-form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      name="country"
                      placeholder="Canada"
                      value={user.country}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <button className="account-settings-button" type="submit">
                  Save Changes
                </button>
              </form>
            </>
          )}
          {activeTab === "security" && <SecuritySettings />}
          {activeTab === "notification" && <NotificationSettings />}
        </div>
      </main>
    </div>
  );
};

export default AccountSettings;
