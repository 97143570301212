import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../Styles/Goals.css";
import GoalsScaleOptions from "./GoalsScaleOptions";

const GoalsScale = ({ setScale }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [selected, setSelected] = useState("");

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  const sampleOptions = ["Large", "Medium", "Small"];

  return (
    <>
      <div
        className={
          isDropdown ? "goals-scale-dropdown active" : "goals-scale-dropdown"
        }
        onClick={toggleDropdown}
      >
        {selected ? (
          <span data-value={selected} className="goals-scale-value">
            {selected}
          </span>
        ) : (
          <span data-value="" className="goals-scale-placeholder">
            Scale
          </span>
        )}

        <div>
          <span className="goals-scale-dropdown-btn">
            <RiArrowDropDownLine
              className={isDropdown ? "goals-dropdown-rotate" : ""}
            />
          </span>
        </div>
      </div>
      {isDropdown ? (
        <GoalsScaleOptions
          options={sampleOptions}
          setScale={setScale}
          selected={selected}
          setSelected={setSelected}
          toggleDropdown={toggleDropdown}
        ></GoalsScaleOptions>
      ) : (
        <></>
      )}
    </>
  );
};

export default GoalsScale;
