import React from 'react';

const styles = {
  HorizontalDivider: {
    width: '711px',
    height: '2px',
    backgroundColor: '#f0f0f0',
    borderRadius: '2px',
  },
};

const HorizontalDivider = (props) => {
  return (
    <div style={styles.HorizontalDivider} />
  );
};

export default HorizontalDivider;