import React, { useState, useEffect, useCallback } from "react";
import "../../Styles/GoalModalContent.css";
import GoalInfoSection from "./GoalModalSections/GoalInfoSection";
import MilestoneSection from "./GoalModalSections/MilestoneSection/MilestoneSection";
import ReflectionsSection from "./GoalModalSections/ReflectionsSection";
import NotesSection from "./GoalModalSections/NotesSection"
import { getGoalById } from "../../../apiControllers/goalsApiController";
import { getMilestonesByGoalId } from "../../../apiControllers/milestoneApiController";

function GoalModalContent({ goalId }) {
  const [goalData, setGoalData] = useState(null);
  const [milestonesData, setMilestonesData] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  useEffect(() => {
    const fetchGoalAndMilestones = async () => {
      try {
        const goal = await getGoalById(goalId);
        setGoalData(goal);

        const milestones = await getMilestonesByGoalId(goalId);
        setMilestonesData(milestones);

        updateCompletionPercentage(milestones);
      } catch (error) {
        console.error("Error fetching goal or milestones:", error);
      }
    };

    if (goalId) {
      fetchGoalAndMilestones();
    }
  }, [goalId]);

  const updateCompletionPercentage = (milestones) => {
    const completedMilestones = milestones.filter((m) => m.status === "Completed").length;
    const percentage = milestones.length
      ? Math.round((completedMilestones / milestones.length) * 100)
      : 0;
    setCompletionPercentage(percentage);
  };

  const handleMilestoneChange = useCallback(async () => {
    try {
      console.log("Fetching updated milestones...");
      const milestones = await getMilestonesByGoalId(goalId);
      setMilestonesData(milestones);
      updateCompletionPercentage(milestones);
    } catch (error) {
      console.error("Error updating milestones:", error);
    }
  }, [goalId]);

  return (
    <div className="goal-container">
      <div className="goal-and-image-container">
        <div className="goal-content">
          <GoalInfoSection
            goalData={goalData}
            completionPercentage={completionPercentage}
          />
        </div>
        <img
          className="goal-side-image"
          src="https://assets.api.uizard.io/api/cdn/stream/53e26f59-c725-4d7d-9e4c-b25d782d9764.png"
          alt="decorative illustration"
        />
      </div>

      <div className="milestones-section-container">
        <div className="milestones-section-header">
          <span className="material-symbols-outlined custom-symbol-size">
            track_changes
          </span>
          <span className="milestones-title">Milestones</span>
        </div>
        <MilestoneSection
          milestonesData={milestonesData}
          goalId={goalId}
          onMilestoneChange={handleMilestoneChange}
        />
      </div>
      <div>
        <NotesSection />
      </div>

      <div>
        <ReflectionsSection goalId={goalId} />
      </div>
    </div>
  );
}

export default GoalModalContent;
