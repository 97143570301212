/**
 * This module is for managing API calls to the server's review endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Converts a given string to title case.
 *
 * @param {string} str - The input string to be converted.
 * @return {string} The converted string in title case.
 */
export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

/**
 * Retrieves all reviews associated with a user by their email.
 *
 * @param {string} email - The email address of the user.
 * @returns {Promise<Array>} A Promise that resolves with the reviews data.
 * @throws {Error} If an error occurs while fetching the data.
 * @throws {Error} If the user doesn't have any reviews.
 * @throws {Error} If an unexpected error occurs.
 */
export const getAllReviewsByEmail = async (email) => {
    try {
        // Make a GET request to the specified URL
        const response = await api.get(`${apiUrl}/v1/review/all/user/${email}`);
        const parsedResponse = response.data.map((review, index) => {
            return {
                dateSent: review.date_requested,
                name: `${review.reviewer_first_name} ${review.reviewer_last_name}`,
                company: review.company_name,
                email: review.reviewer_email_address,
                status: toTitleCase(review.status),
                reviewId: review.review_id,
                type: review.relationship_to_reviewee,
            };
        });

        return parsedResponse;
    } catch (error) {
        if (error.response.status === 404) {
            const message =
                "You don't have reviews at the moment. Please fill out the Self-Assessment survey or request a review from your colleague.";
            throw new Error(message);
        } else {
            throw new Error("An unexpected error occurred. Please try again later.");
        }
    }
};

/**
 * Retrieves all reviews made by a reviewer based on their email address.
 *
 * @param {string} email - The email address of the reviewer.
 * @return {Promise<Array>} An array of review objects containing the review ID,
 * date invited, reviewer's name, date submitted, reviewer's email address,
 * and review status.
 * @throws {Error} If an error occurs while fetching the data.
 */
export const getAllReviewerReviewsByEmail = async (email) => {
    try {
        const response = await api.get(`${apiUrl}/v1/review/all/reviewer/${email}`);

        const parsedResponse = response.data.map((review, index) => {
            return {
                reviewId: review.review_id,
                dateInvited: review.date_requested,
                name: `${review.reviewee_first_name} ${review.reviewee_last_name}`,
                dateSubmitted: review.date_completed,
                email: review.reviewer_email_address,
                status: toTitleCase(review.status),
            };
        });

        return parsedResponse;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

/**
 * Creates a review by sending a POST request to the API endpoint.
 *
 * @param {Object} payload - The payload containing the necessary information for the review.
 * @return {Promise<Object>} A Promise that resolves with the response from the API.
 * @throws {Error} If an error occurs while making the request or if the response contains an error.
 */
export const createReview = async (payload) => {
    try {
        const response = await api.post(`${apiUrl}/v1/review/single`, payload);
        return response;
    } catch (error) {
        if (error.response) {
            const message =
                error.response.data.detail ||
                "Failed to create review. Please try again later.";
            throw new Error(message);
        } else {
            throw new Error("An unexpected error occurred. Please try again later.");
        }
    }
};

/**
 * Retrieves a review by its ID from the API.
 *
 * @param {string} reviewId - The ID of the review to fetch.
 * @return {Promise<Object>} The review data returned by the API.
 * @throws {Error} If an error occurs while fetching the review.
 */
export const getReviewById = async (reviewId) => {
    try {
        const response = await api.get(`${apiUrl}/v1/review/single/${reviewId}`);
        const reviewData = response.data;
        return reviewData;
    } catch (error) {
        console.error("Error fetching review by ID:", error);
    }
};

/**
 * Retrieves the review analysis data for a given user ID from the API.
 *
 * @param {string} userId - The ID of the user whose review analysis data is being fetched.
 * @return {Promise<Object>} The review analysis data returned by the API.
 * @throws {Error} If an error occurs while fetching the review analysis data.
 */
export const getReviewAnalysisByUserId = async (userId) => {
    try {
        const response = await api.get(`${apiUrl}/v1/review/response/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching review analysis data:", error);
        throw error;
    }
};

/**
 * Deletes a review by reviewee email.
 *
 * @param {Object} payload - The payload containing the reviewee email.
 * @return {Promise<Object>} The response data from the API.
 * @throws {Error} If an error occurs while deleting the review data.
 */
export const deleteReviewByRevieweeEmail = async (payload) => {
    try {
        const response = await api.delete(`${apiUrl}/v1/review/single`, {
            data: payload,
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting review data:", error);
        throw error;
    }
};