import React from "react";
import "../Styles/Insights/CompetencySummary.css";

export const attributes = ["leadership", "collaboration", "work_ethic", "results_oriented", "business_acumen"];

export const attributesFieldsMap = {
    leadership: ["c_leadership_avg", "sc_influence_avg", "sc_motivating_avg"],
    collaboration: ["c_collaboration_avg", "sc_communication_avg", "sc_interpersonal_avg"],
    work_ethic: ["c_work_ethic_avg", "sc_integrity_avg", "sc_professional_avg"],
    results_oriented: ["c_results_avg", "sc_decision_avg", "sc_flexibility_avg", "sc_productivity_avg"],
    business_acumen: ["c_business_avg", "sc_acumen_avg", "sc_planning_avg"],
};

/**
 * Calculates the average Likert scores for each competency section and converts them to percentages
 *
 * @param {Object} data - The graph data containing competency scores
 * @returns {Object} sections - An object containing average scores converted to percentages for each competency section
 */
const calculateSectionAverages = (data) => {
    const sections = {};

    attributes.forEach(attribute => {
        const fields = attributesFieldsMap[attribute];
        const sectionData = data[attribute];

        if (sectionData) {
            // Creates an array containing scores of each respective field
            const scores = fields.map(field => sectionData[field]);

            const average = calculateAverage(scores);

            // Assign percentage to appropriate key
            sections[attribute] = likertToPercentage(average);
        } else {
            sections[attribute] = null;
        }
    });

    return sections;
};

/**
 * Converts a Likert scale score to a percentage
 *
 * @param {number} score - Score to convert
 * @returns {number} - Score Converted to a percentage
 */
export const likertToPercentage = (score) => ((score - 1) / 4) * 100;

/**
 * Calculates the average of an array of numerical scores
 *
 * @param {number[]} scores - An array of numerical scores
 * @returns {number} - Average score
 */
const calculateAverage = (scores) => scores.reduce((total, score) => total + score, 0) / scores.length;

/**
 * Formats a label by replacing underscores and hyphens with spaces and capitalizing each word.
 *
 * @param {string} label - The label string to format
 * @returns {string} - The formatted label with spaces and capitalized words
 */
const formatLabel = (label) => {
    return label
        .replace(/[_-]/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * Determines the appropriate CSS class based on the given score
 *
 * @param {number} score - The numerical score to evaluate. If undefined, returns "missing-value"
 * @returns {string} - The corresponding CSS class name
 */
export const getBoxClass = (score) => {
    if (score === undefined || null) return "missing-value";
    if (score < 57) return "development-needed";
    if (score >= 57 && score < 71) return "progressing";
    if (score >= 71 && score < 85) return "competent";
    if (score >= 85 && score < 89) return "strong";
    if (score >= 89) return "outstanding";
    return "missing-value";
};

/**
 * Groups reviews in graph data by their relationship.
 *
 * @param {Array<Object>} data -  An array of graph data objects, where each object contains a "relationship" property.
 * @returns {Object} - An object where each key corresponds to a relationship, and its value is an array of associated
 * reviews.
 */
export const groupReviews = (data) => {
    return data.reduce((acc, item) => {
        const key = item.relationship.toLowerCase();
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc
    }, {});
}

/**
 * Initializes field sums to zero for each field
 *
 * @param {string[]} fields - Array of field names
 * @returns {Object} - An object with field names as keys and 0 as initial values
 */
const initializeFieldSums = (fields) =>
    fields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
    }, {});

/**
 * Aggregates scores from a review into the field sums
 *
 * @param {Object} fieldSums - Current sums of each field
 * @param {Object} review - The review object
 * @param {string} attribute - The attribute to aggregate
 * @param {string[]} fields - Fields related to the attribute
 */
const aggregateScores = (fieldSums, review, attribute, fields) => {
    // Get the data for the specific attribute from the review
    const sectionData = review?.[attribute];

    // Iterate through each element in the fields array, ie "c_leadership_avg"
    fields.forEach(field => {
        const score = sectionData[field];
        fieldSums[field] += score;
    });
};

/**
 * Calculates the average for each field based on the number of reviews
 *
 * @param {Object} fieldSums - Sums of each field
 * @param {number} numReviews - Total number of reviews
 * @returns {Object} - An object with averaged values for each field
 */
const calculateAverages = (fieldSums, numReviews) =>
    Object.keys(fieldSums).reduce((acc, field) => {
        acc[field] = fieldSums[field] / numReviews;
        return acc;
    }, {});

/**
 * Processes a single relationship to calculate averaged attributes
 *
 * @param {string} relationship - The relationship identifier
 * @param {Object[]} reviewArray - Array of review objects
 * @returns {Object} - An object containing the relationship and its averaged attributes
 */
const processRelationship = (relationship, reviewArray) => {
    const relationshipData = {relationship};
    const numReviews = reviewArray.length;

    // Attributes is defined globally
    attributes.forEach(attribute => {
        const fields = attributesFieldsMap[attribute];
        // Creates initial values for each element in the fields array
        const fieldSums = initializeFieldSums(fields);

        reviewArray.forEach(review => {
            aggregateScores(fieldSums, review, attribute, fields);
        });

        relationshipData[attribute] = calculateAverages(fieldSums, numReviews);
    });

    return relationshipData;
};

/**
 * Calculates the average scores for each relationship
 *
 * @param {Object} groupedData - Object with relationships as keys and arrays of reviews as values
 * @returns {Object[]} - Array of objects containing relationships with their averaged attributes
 */
export const calculateRelationshipAvg = (groupedData) =>
    // Covert the object to an array so it can be iterated over
    Object.entries(groupedData).map(
        // Deconstruct the key value pairs into variables "relationship" and "reviewArray"
        ([relationship, reviewArray]) => processRelationship(relationship, reviewArray)
    );

// Defines the competency sections with their titles, keys, and placeholder scores
const competencyRows = [
    {
        title: "Leadership & Empowering Others",
        key: attributes[0],
        scores: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    },
    {
        title: "Collaboration & Communication",
        key: attributes[1],
        scores: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    },
    {
        title: "Work Ethic & Value",
        key: attributes[2],
        scores: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    },
    {
        title: "Results-oriented & Adaptability",
        key: attributes[3],
        scores: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    },
    {
        title: "Business Acumen & Strategic Thinking",
        key: attributes[4],
        scores: [undefined, undefined, undefined, undefined, undefined, undefined, undefined],
    },
];

/**
 * Renders the Competency Summary, calculates the values to insert dynamically based
 * on graph data
 *
 * @param {Object} competencyData - Refined graph data prop passed in from insights.jsx
 * @returns {JSX.Element} - Rendered CompetencySummary React component
 */
const CompetencySummaryCard = ({competencyData}) => {
    const competencyItems = competencyData || [];

    // Group reviews on their relationship
    const groupedReviews = groupReviews(competencyItems);

    //console.log("Grouped Data", groupedReviews);

    const aggregateAttempt = calculateRelationshipAvg(groupedReviews);

    //console.log("AggregateAttempt", aggregateAttempt);

    const processedCompetencyData = aggregateAttempt.map((data) =>
        calculateSectionAverages(data)
    );

    //console.log("Final", processedCompetencyData);

    const updatedCompetencyRows = competencyRows.map((row) => {
        const key = row.key;
        const scores = processedCompetencyData.map((data) => data[key]);
        const validScores = scores.filter(
            (score) => score !== undefined && score !== null
        );

        const average =
            validScores.length > 0
                ? validScores.reduce((sum, score) => sum + score, 0) /
                validScores.length
                : undefined;

        const newScores = [average, ...scores];

        while (newScores.length < 6) {
            newScores.push(undefined);
        }


        return {
            ...row,
            scores: newScores,
        };
    });


    return (
        <div className="competency-summary-card">
            <div className="competency-grid">
                <div className="competency-title">
                    Competency <br/>
                    Summary
                </div>

                {/* Top row of labels */}
                <div className="competency-empty-space"></div>
                {[
                    "Self-Assessment",
                    "Manager",
                    "Direct Report",
                    "Teammate",
                    "Peer",
                    "Professor",
                ].map((defaultTitle, index) => (
                    <div key={index} className="competency-top-label rotated-label">
                        {aggregateAttempt[index] &&
                        aggregateAttempt[index].relationship
                            ? formatLabel(aggregateAttempt[index].relationship)
                            : defaultTitle}
                    </div>
                ))}

                {/* Loop through each competency row to render title, row average, and scores */}
                {updatedCompetencyRows.map((row, index) => (
                    <React.Fragment key={index}>
                        {/* Competency Title */}
                        <div className="competency-label">{row.title}</div>

                        {/* Column 2: Row Average */}
                        <div className="competency-score">
                            {row.scores[0] !== undefined ? Math.floor(row.scores[0]) : ""}
                        </div>

                        {/* Columns 3-8: Individual Scores */}
                        {row.scores.slice(1).map((score, idx) => (
                            <div
                                key={idx}
                                className={`competency-box ${getBoxClass(score)}`}
                            ></div>
                        ))}
                    </React.Fragment>
                ))}
            </div>

            {/* Competency Key Legend */}
            <div className="competency-legend">
                <div className="legend-label">
                    Development Needed <br/>
                    (&lt;56)
                </div>
                <div className="legend-label">
                    Progressing <br/>
                    (57-70)
                </div>
                <div className="legend-label">
                    Competent
                    <br/>
                    (71-84)
                </div>
                <div className="legend-label">
                    Strong
                    <br/>
                    (85-89)
                </div>
                <div className="legend-label">
                    Outstanding
                    <br/>
                    (&gt;89)
                </div>
                <div className="legend-box development-needed"></div>
                <div className="legend-box progressing"></div>
                <div className="legend-box competent"></div>
                <div className="legend-box strong"></div>
                <div className="legend-box outstanding"></div>
            </div>
        </div>
    );
};

export default CompetencySummaryCard;