import React, { useState, useEffect } from "react";
import SuggestedResourcesCard from "./SuggestedResourcesCard";
import { getSuggestions } from "../../apiControllers/suggestionsApiController";  // Use getSuggestions
import "../Styles/Insights/SuggestedResources.css";

/**
 * SuggestedResources Component
 * Fetches resource suggestions and displays them grouped by growth areas.
 */
const SuggestedResources = ({ growth_areas }) => {
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const email = localStorage.getItem('userEmail');  // Fetch the user email from localStorage
        console.log("Fetching suggestions for:", email);
        const fetchedResources = await getSuggestions(email);  // Fetch suggestions from the API

        //console.log("Fetched Resources:", fetchedResources);

        if (Array.isArray(fetchedResources)) {
          setResources(fetchedResources);  // Set the resources in the state
        } else {
          throw new Error("Failed to fetch resources.");
        }
      } catch (err) {
        setError("Failed to fetch resources.");
        console.error("Error fetching resources:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchResources();
  }, []);

  const groupResourcesByCategory = () => {
    const groupedResources = {};

    // Group resources by growth areas (categories)
    growth_areas.forEach((growth_area) => {
      const matchedResources = resources.filter(resource =>
        resource.tags?.some(tag => growth_area.keyword.toLowerCase().includes(tag.toLowerCase()))
      ).slice(0, 2);  // Limit to 2 suggestions per growth area

      groupedResources[growth_area.keyword] = matchedResources;
    });

    return groupedResources;
  };

  if (loading) {
    return <div>Loading suggested resources...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="suggested-resources-section">
      <SuggestedResourcesCard resourcesByCategory={groupResourcesByCategory()} />
    </div>
  );
};

export default SuggestedResources;
