import React, { useEffect, useState } from "react";
import "../Styles/FBReport.css";
import FBReportIntro from "./FBReportIntro";
import FBReportAnalysis from "./Analysis/FBReportAnalysis";
import FBChart from "./FBChart";
import { getFeedbackElementData } from "../../apiControllers/feedbackApiController";
import { useSearchParams } from "react-router-dom";
import FBReportCompetency from "./Competency/FBReportCompetency";

/**
 * Renders the background component for the FBReport.
 *
 * @return {JSX.Element} The JSX element representing the FBReport background.
 */
const FBReportBackground = () => {
  const [elementData, setElementData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getElementData = async () => {
      try {
        let apiData = await getFeedbackElementData(
          searchParams.get("selfReviewId"),
          searchParams.get("peerReviewId")
        );
        setElementData(apiData);
        setDataLoaded(true);
      } catch (error) {
        console.error(error);
        setError(true);
      }
    };

    if (!dataLoaded) {
      getElementData();
    }
  }, [elementData]);

  return (
    <>
      {!dataLoaded && !error && <p>Loading... (Do not yet download PDF)</p>}
      {error && <p>Error with analysis. Please reload page.</p>}
      {!error && dataLoaded && elementData !== null && (
        <>
          <div className="fb-report-bg">
            <div className="fb-report-content-container">
              <FBReportIntro elementData={elementData}></FBReportIntro>
              <FBChart></FBChart>
              <FBReportCompetency
                overlooked={elementData.differences}
                topThree={elementData.top_three}
              ></FBReportCompetency>
              <FBReportAnalysis
                generalData={{
                  observations: elementData.general_feedback,
                  ai: elementData.ai,
                }}
              ></FBReportAnalysis>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FBReportBackground;
