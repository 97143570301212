import React from "react";
import "../Styles/Insights/Insights.css";
import "../Styles/Insights/GeneralFeedbackCard.css";

const strengthsFeedback = [];
const growthFeedback = [];

/**
 * Format the div to display the text and relationship
 *
 * @param relationship - Relationship between reviewee and reviewer
 * @returns {String} - Formatted relationship string
 */
export const formatRelationship = (relationship) => {
    return relationship
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

/**
 * Process answers from non-self reviews only
 *
 * @param graphData - Refined graph data prop passed in from insights.jsx
 * @return {*[]} - Array of filtered reviews
 */
export const filterReviews = (graphData) => {
    const filteredReviews = []
    graphData.forEach((review) => {
        if (review.relationship !== 'self') {
            filteredReviews.push((review));
        }
    })
    return filteredReviews;
}

/**
 * Add answers to the appropriate display
 *
 * @param likertNum - likert number of the previous question to determine which array to add the open ended response
 * @param feedbackText - answer of the question
 */
const addToArray = (likertNum, feedbackText) => {
    if (likertNum <= 2) {
        growthFeedback.push(feedbackText);
    } else {
        strengthsFeedback.push(feedbackText);
    }
}

/**
 * Check if the question is open-ended
 *
 * @param answer - Answer of the question we are checking
 * @param previousAnswer - Previous answer to the current question we are checking
 * @param review - Current review that is being processed
 */
const checkQuestion = (answer, previousAnswer, review) => {
    if (answer.response_open_question && previousAnswer.option_close_ended) {
        const feedbackText = `${answer.response_open_question} - ${review.name}, ${review.relationship}`;
        const likertNumber = previousAnswer.option_close_ended.likert_number;
        addToArray(likertNumber, feedbackText);
    }
}

/**
 * Process each review and add open-ended answers to the appropriate arrays
 *
 * @param reviews - Array of reviews to process
 */
const processReviews = (reviews) => {
    reviews.forEach((review) => {
        for (let i = 9; i < review.answers.length; i++) {
            const answer = review.answers[i];
            const previousAnswer = review.answers[i - 1];

            checkQuestion(answer, previousAnswer, review);
        }
    })
}

/**
 * Display Strengths and Feedback by retrieving answers from reviews associated with
 * a specific user
 *
 * @param graphData - Array containing each review graph data associated with a user
 * @returns {JSX.Element} - General Feedback component
 */
const GeneralFeedbackCard = ({graphData}) => {
    //console.log("Feedback", graphData);

    const filteredReviews = filterReviews(graphData);
    //console.log("Filtered", graphData);

    processReviews(filteredReviews);

    return (
        <div className="general-feedback-card">
            <div className="general-feedback-container">
                {/* Strengths Column */}
                <div className="feedback-column strengths">
                    <div className="general-feedback-title">
                        General Feedback <br/>
                        (Strengths)
                    </div>
                    <div className="insights-text-ai-small">
                        {strengthsFeedback.map((feedback, index) => {
                            const [responseText, reviewerDetails] = feedback.split(" - ");
                            return (
                                <p key={index} className="feedback-text">
                                    {responseText}
                                    <br/>
                                    - {reviewerDetails.split(", ")[0]},
                                    ({formatRelationship(reviewerDetails.split(", ")[1])})
                                </p>
                            );
                        })}
                    </div>
                </div>

                {/* Growth Areas Column */}
                <div className="feedback-column growth-areas">
                    <div className="general-feedback-title">
                        General Feedback <br/>
                        (Growth Areas)
                    </div>
                    <div className="insights-text-ai-small">
                        {growthFeedback.map((feedback, index) => {
                            const [responseText, reviewerDetails] = feedback.split(" - ");
                            return (
                                <p key={index} className="feedback-text">
                                    {responseText}
                                    <br/>
                                    - {reviewerDetails.split(", ")[0]},
                                    ({formatRelationship(reviewerDetails.split(", ")[1])})
                                </p>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralFeedbackCard;
