import React, { useState, useRef, useEffect } from "react";
import "../Styles/OnBoarding.css";
import Icon from "./Icon.jsx";
import Card from "./Card.jsx";
import Image from "./Image.jsx";
import Button from "./Button.jsx";
import InputField from "./InputField.jsx";
import Text from "./Text.jsx";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserData,
  updateUser,
} from "../../apiControllers/userApiController.js";

/**
 * StepTwo component for rendering the second step of the onboarding process.
 *
 * @component
 * @example
 * return (
 *   <StepTwo />
 * )
 */
function StepTwo() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState(
    "https://assets.api.uizard.io/api/cdn/stream/068a1949-ca93-4983-ba8f-ad98414e3090.png"
  );
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData(); // Fetch user data
        setFirstName(userData.first_name || "");
        setLastName(userData.last_name || "");
        setTitle(userData.title || "");
        setCompany(userData.company || "");
        setAbout(userData.about || "");
        setImage(
          userData.image ||
            "https://assets.api.uizard.io/api/cdn/stream/068a1949-ca93-4983-ba8f-ad98414e3090.png"
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  /**
   * Handles form submission to update user data.
   */
  const handleSubmit = async () => {
    const user = {
      first_name: firstName,
      last_name: lastName,
      title: title,
      company: company,
      about: about,
      image: image,
    };
    try {
      await updateUser(user);
      navigate("/OnBoarding/step-three");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  /**
   * Handles image file change and updates the profile image.
   *
   * @param {Object} event - The change event of the file input.
   */
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  /**
   * Triggers the file input click event.
   */
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="onboarding-container">
      <Card>
        <div className="onboarding-icon-text">
          <Icon type={"profile"} />
          <div className="onboarding-card-title">Profile Details</div>
        </div>
        <Text text="Now lets fill out your profile" />
        <div className="onboarding-header-and-image">
          <div className="onboarding-form">
            <div className="onboarding-form-top">
              <div className="onboarding-column-1">
                <div className="onboarding-input">
                  <Text text="First Name" />
                  <InputField
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="onboarding-input">
                  <Text text="Title" />
                  <InputField
                    placeholder="Your role"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="onboarding-column-2">
                <div className="onboarding-input">
                  <Text text="Last Name" />
                  <InputField
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="onboarding-input">
                  <Text text="Company" />
                  <InputField
                    placeholder="Current Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="onboarding-form-bottom">
              <div className="onboarding-about">
                <Text text="About" />
                <InputField
                  type="textarea"
                  rows={4}
                  placeholder="Summary about your experience and how you're looking to grow in your career"
                  value={about}
                  onChange={(e) => setAbout(e.target.value)}
                />
              </div>
              <div className="onboarding-actions" id="profile">
                <Button label="Next" onClick={handleSubmit} />
                <Link
                  to="/OnBoarding/step-three"
                  className="onboarding-skip-link"
                >
                  <Text text="Skip" />
                </Link>
              </div>
            </div>
          </div>
          <div className="onboarding-profile-image-container">
            <Image image={image} id="stepTwoImage" />
            <svg
              className="onboarding-upload"
              viewBox="0 0 24 24"
              onClick={triggerFileInput}
            >
              <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
            </svg>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
              accept="image/*"
            />
            <Text text="Update Profile Image" />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default StepTwo;
