import React from "react";
import "../Styles/Insights/Insights.css";
import {formatRelationship, filterReviews} from "./GeneralFeedback";

const strengthsFeedback = [];

/**
 * Retrieve a random element from an array
 *
 * @param array - Array to help determine bounds for random integer
 * @returns {*} - Random integer, based on array size
 */
const getRandomElement = (array) => array[Math.floor(Math.random() * array.length)];

/**
 * Add answers to the strengthsFeedback array
 *
 * @param likertNum - likert number of the previous question to determine which array to add the open-ended response
 * @param feedbackText - answer of the question
 */
const addToArray = (likertNum, feedbackText) => {
    if (likertNum > 2) {
        strengthsFeedback.push(feedbackText);
    }
}

/**
 * Check if the question is open-ended
 *
 * @param answer - Answer of the question we are checking
 * @param previousAnswer - Previous answer to the current question we are checking
 */
const checkQuestion = (answer, previousAnswer) => {
    if (answer.response_open_question && previousAnswer.option_close_ended) {
        const feedbackText = `${answer.response_open_question}`;
        const likertNumber = previousAnswer.option_close_ended.likert_number;
        addToArray(likertNumber, feedbackText);
    }
}

/**
 * Process review to add strengths answers to the array, start at 9 to avoid linked in response
 *
 * @param review - Review to process
 */
const processReview = (review) => {
    for (let i = 9; i < review.answers.length; i++) {
        const answer = review.answers[i];
        const previousAnswer = review.answers[i - 1];

        checkQuestion(answer, previousAnswer);
    }
}

/**
 * Get a random quote for a specific user
 *
 * @param graphData - Array of all reviews associated with a user
 * @returns {[*,*]} - Array containing review and answer objects, respectively
 */
const getRandomQuote = (graphData) => {
    const filteredReviews = filterReviews(graphData);
    const randomReview = getRandomElement(filteredReviews);
    //console.log(randomReview);
    processReview(randomReview);

    //console.log("Strengths", strengthsFeedback);
    // Filter for open-ended questions, then get a random element
    const randomAnswer = getRandomElement(strengthsFeedback);

    return [randomReview, randomAnswer];
}

/**
 * Renders a random quote
 *
 * @param graphData - Graph data of all reviews
 * @returns {JSX.Element} - React component displaying the quote
 */
const Quote = ({graphData}) => {
    const [review, answer] = getRandomQuote(graphData);

    return (
        <div className="insight-peer-feedback">
            <div className="insight-peer-feedback-text">
                {answer}
            </div>

            <div className="insight-peer-feedback-person">
                {`${review.name} (${formatRelationship(review.relationship)})`}
            </div>
        </div>
    )
}

export default Quote;

