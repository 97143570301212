import React, { useEffect, useState } from 'react';
import '../Styles/Insights/Insights.css';
import '../Styles/Insights/GrowthPlan.css';
import { getFeedbackReport } from '../../apiControllers/feedbackApiController';

const GrowthPlanCard = ({ reviewResponse }) => {
  const [growthPlanData, setGrowthPlanData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrowthPlanData = async () => {
      if (!reviewResponse || !Array.isArray(reviewResponse)) {
        console.warn("Invalid or empty reviewResponse");
        setLoading(false);
        return;
      }

      // Find the first completed review in the reviewResponse
      const completedReview = reviewResponse.find(review => review.status === "Completed");
      if (!completedReview) {
        console.warn("No completed reviews found in reviewResponse");
        setLoading(false);
        return;
      }

      try {
        // Fetch growth plan data using the ID of the first completed review
        const data = await getFeedbackReport(completedReview.reviewId);
        setGrowthPlanData(data);
      } catch (error) {
        console.error("Error fetching growth plan data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGrowthPlanData();
  }, [reviewResponse]);

  const { proposed_growth_plan, growth_areas } = growthPlanData || {};

  return (
    <div className="growth-plan-card">
      <h2 className="insights-text-ai-large">Growth Plan</h2>
      {loading ? (
        <p className="insights-loading">Loading...</p>
      ) : growthPlanData ? (
        <>
          <p className="insights-text-ai-small">
            {proposed_growth_plan?.details || "No proposed growth plan details available"}
          </p>
          {growth_areas && growth_areas.slice(0, 4).map((area, index) => (
            <p key={index} className="insights-text-ai-small">
              {area.keyword}: {area.details}. {area.suggestions}.
            </p>
          ))}
        </>
      ) : (
        <p className="insights-text-ai-small">No data available</p>
      )}
    </div>
  );
};

export default GrowthPlanCard;
