import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createMilestone } from "../../../../../apiControllers/milestoneApiController";
import "../../../../../components/Styles/CreateMilestoneModal.css";

const CreateMilestoneModal = ({ isOpen, onClose, goalId, onAddMilestone }) => {
    const modalRef = useRef(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const [deadline, setDeadline] = useState(""); 
    const [error, setError] = useState("");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.body.style.overflow = "auto";
        };
    }, [isOpen, onClose]);

    const handleCreateMilestone = async (e) => {
        e.preventDefault();
        if (!title.trim() || !description.trim() || !startDate.trim() || !deadline.trim()) {
            setError("Please fill out all fields.");
            return;
        }

        const milestoneData = {
            goal_id: goalId,
            title,
            description,
            start_date: startDate,
            deadline,
            status: "Not Started"
        };
        console.log("Payload being sent:", milestoneData);

        try {
            const newMilestone = await createMilestone(milestoneData);
            onAddMilestone(newMilestone);
            onClose();
        } catch (error) {
            console.error("Error creating milestone:", error);
            setError("Failed to create milestone. Please try again.");
        }
    };

    const handleFocus = (e) => {
        e.target.type = "date";
    };

    const handleBlur = (e) => {
        if (!e.target.value) {
            e.target.type = "text";
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="milestone-modal-overlay">
            <div className="milestone-modal-content" ref={modalRef} role="dialog" aria-modal="true">
                <h2>Create a Milestone</h2>
                <form className="milestone-form" onSubmit={handleCreateMilestone}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="title"
                            placeholder="Milestone Title"
                            className="milestone-input"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                            id="description"
                            placeholder="Milestone Description"
                            className="milestone-input"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="startDate"
                            placeholder="Start Date (mm/dd/yy)"
                            className="milestone-input"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="deadline"
                            placeholder="End Date (mm/dd/yy)"
                            className="milestone-input"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                        />
                    </div>
                    <div className="button-container" style={{ textAlign: "right" }}>
                        <button type="submit" className="milestone-save-button">
                            Save
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                </form>
            </div>
        </div>,
        document.getElementById("modal-root")
    );
};

export default CreateMilestoneModal;
