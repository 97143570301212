import React from "react";
import InputField from "./InputField.jsx";
import Select from "./Select.jsx";

/**
 * OnboardingReferenceRow component for rendering a row of input fields for onboarding references.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {number} props.index - The index of the current reference row.
 * @param {Object} props.reference - The reference data for the current row.
 * @param {function} props.handleInputChange - The function to call when an input value changes.
 * @param {function} props.handleRemoveClick - The function to call when the remove button is clicked.
 * @param {Array} props.relationshipOptions - The options for the relationship select dropdown.
 * @param {boolean} props.showRemoveButton - Whether to show the remove button.
 * @param {string} props.errorMessage - The error message to display if any.
 * @example
 * const references = [
 *   { name: '', email: '', relationship: '' }
 * ];
 * const handleInputChange = (index, field, value) => {
 *   // handle input change
 * };
 * const handleRemoveClick = (index) => {
 *   // handle remove click
 * };
 * return (
 *   <OnboardingReferenceRow
 *     index={0}
 *     reference={references[0]}
 *     handleInputChange={handleInputChange}
 *     handleRemoveClick={handleRemoveClick}
 *     relationshipOptions={['Colleague', 'Manager', 'Friend']}
 *     showRemoveButton={true}
 *     errorMessage="Error message"
 *   />
 * )
 */
const OnboardingReferenceRow = ({
  index,
  reference,
  handleInputChange,
  handleRemoveClick,
  relationshipOptions,
  showRemoveButton,
  errorMessage,
}) => (
  <>
    <div className="onboarding-reference-input-group">
      <InputField
        value={reference.name}
        onChange={(e) => handleInputChange(index, "name", e.target.value)}
        placeholder="First and Last Name"
      />
      <InputField
        value={reference.email}
        onChange={(e) => handleInputChange(index, "email", e.target.value)}
        placeholder="Email"
        errorMessage={errorMessage}
      />
      <Select
        name="relationship"
        value={reference.relationship}
        onChange={(e) =>
          handleInputChange(index, "relationship", e.target.value)
        }
        options={relationshipOptions}
        defaultOption="Select Relationship"
      />
      {showRemoveButton && (
        <button
          className="onboarding-remove"
          onClick={() => handleRemoveClick(index)}
        >
          ×
        </button>
      )}
    </div>
    {errorMessage && <div className="error-message">{errorMessage}</div>}
  </>
);

export default OnboardingReferenceRow;
