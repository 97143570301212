import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { updateMilestone } from "../../../../../apiControllers/milestoneApiController";
import "../../../../../components/Styles/EditMilestoneModal.css";

const EditMilestoneModal = ({ isOpen, onClose, milestone, onSave }) => {
    const modalRef = useRef(null);
    const [milestone_id, setMilestoneId] = useState(milestone?.milestone_id || "");
    const [title, setTitle] = useState(milestone?.title || "");
    const [description, setDescription] = useState(milestone?.description || "");
    const [start_date, setStartDate] = useState(milestone?.start_date || "");
    const [deadline, setDeadline] = useState(milestone?.deadline || "");
    const [status, setStatus] = useState(milestone?.status || "")
    const [error, setError] = useState("");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.body.style.overflow = "hidden";
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.body.style.overflow = "auto";
        };
    }, [isOpen, onClose]);

    const handleUpdateMilestone = async (e) => {
        e.preventDefault();
        if (!title.trim() || !description.trim() || !start_date.trim() || !deadline.trim()) {
            setError("Please fill out all fields.");
            return;
        }

        const milestoneData = {
            milestone_id,
            title,
            description,
            start_date,
            deadline,
            status
        };
        console.log("Payload being sent:", milestoneData);

        try {
            await updateMilestone(milestone_id, milestoneData);
            onSave(milestoneData);
            onClose();
        } catch (error) {
            console.error("Error updating milestone:", error);
            setError("Failed to update milestone. Please try again.");
        }
    };

    const handleFocus = (e) => {
        e.target.type = "date";
    };

    const handleBlur = (e) => {
        if (!e.target.value) {
            e.target.type = "text"; 
        }
    };

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="milestone-modal-overlay">
            <div
                className="milestone-modal-content"
                ref={modalRef}
                role="dialog"
                aria-modal="true"
            >
                <h2>Edit Milestone</h2>
                <form className="milestone-form" onSubmit={handleUpdateMilestone}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="title"
                            placeholder="Milestone Title"
                            className="milestone-input"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                            id="description"
                            placeholder="Milestone Description"
                            className="milestone-input"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="startDate"
                            placeholder="Start Date (mm/dd/yy)"
                            className="milestone-input"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={start_date}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="deadline"
                            placeholder="End Date (mm/dd/yy)"
                            className="milestone-input"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={deadline}
                            onChange={(e) => setDeadline(e.target.value)}
                        />
                    </div>
                    <div className="button-container" style={{ textAlign: "right" }}>
                        <button type="submit" className="milestone-save-button">
                            Save
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                </form>
            </div>
        </div>,
        document.getElementById("modal-root")
    );
};

export default EditMilestoneModal;
