import React from "react";

/**
 * Button component for rendering a customizable button.
 *
 * @component
 * @param {Object} props - The props for the Button component.
 * @param {function} props.onClick - The click handler function for the button.
 * @param {boolean} [props.disabled] - Indicates if the button is disabled.
 * @param {string} props.label - The label to display on the button.
 * @example
 * return (
 *   <Button
 *     onClick={() => console.log('Button clicked')}
 *     disabled={false}
 *     label="Click Me"
 *   />
 * )
 */
const Button = (props) => {
  return (
    <button
      className="onboarding-button"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};

export default Button;
