import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

/**
 * Renders a component that displays a message indicating that a password reset email has been sent to the user's email address.
 *
 * @return {JSX.Element} The rendered component.
 */
const LinkSent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [targetEmail, setTargetEmail] = useState("");

  useEffect(() => {
    setTargetEmail(searchParams.get("email"));
  }, []);

  return (
    <>
      <div className="password-reset-container">
        <div className="password-reset-form-box">
          <span className="password-reset-header">Email Sent</span>
          <form
            className="password-reset-form"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="password-reset-message">
              <span>
                Password reset email has been sent to {targetEmail}. Please
                check your inbox for the reset link.
              </span>
            </div>
            <div className="password-reset-link-login-link">
              <Link className="password-reset-login-link-text" to="/login">
                <span>Back to login</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LinkSent;
