import React, {useEffect, useState} from "react";
import "../Styles/Insights/Insights.css";
import "../Styles/Insights/Attributes.css";
import {getGoalsByUserId} from "../../apiControllers/goalsApiController";
import {getAllReviewsByEmail, getReviewAnalysisByUserId,} from "../../apiControllers/reviewApiController";
import InsightsDashboardIcon from "./Insights-Dashboard-Icon";
import StrengthsGrowthCard from "./StrengthsGrowthCard";
import CompetencySummaryCard from "./CompetencySummaryCard";
import SuggestedResources from "./SuggestedResources";
import FeedbackSummaryCard from "./FeedbackSummaryCard";
import AttributesCard from "./AttributesCard";
import GeneralFeedbackCard from "./GeneralFeedback";
import GrowthPlanCard from "./GrowthPlanCard";
import Quote from "./Quote";
import {getFeedbackGraphData} from "../../apiControllers/feedbackApiController";

/**
 * Image Component
 *
 * A reusable image component with a default image fallback.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} [props.image] - The URL of the image to be displayed.
 *
 * @returns {JSX.Element} The rendered Image component.
 */
const Image = (props) => {
    const defaultImage =
        "https://assets.api.uizard.io/api/cdn/stream/972048db-bb83-4fed-90d8-5199eb7a435c.png";
    return (
        <div
            style={{backgroundImage: `url(${props.image ?? defaultImage})`}}
            className="insights-image-container"
        />
    );
};

/**
 * Get the graph data from each review for a specified user and add relationship and name key / value pairs
 *
 * @param reviews all of the reviews associated with a user
 * @return array containing the relationship and graph data of each review
 */
const getGraphData = async (reviews) => {
    try {
        const filtered = reviews.filter(review => review.status === "Completed");

        // Map over filtered reviews and create an array of Promises
        const enrichedDataPromises = filtered.map(async (review) => {
            // Add relationship and name to the object
            const relationship = review.type;
            const name = review.name;
            const reviewUser = await getFeedbackGraphData(review.reviewId);

            reviewUser.relationship = relationship;
            reviewUser.name = name;

            return reviewUser;
        });

        return await Promise.all(enrichedDataPromises);

    } catch (error) {
        console.error("An error occurred in getGraphData:", error);
        return [];
    }
};

/**
 * Insights Component
 *
 * This component fetches and displays user insights based on goals and reviews data.
 */
const Insights = () => {
    const [dataAvailable, setDataAvailable] = useState(false);
    const [reviewResponse, setReviewResponse] = useState(null);
    const [reviewList, setReviewList] = useState([]);
    const [hasCalledApi, setHasCalledApi] = useState(false);
    const [loading, setLoading] = useState(true);
    const [feedbackGraphData, setFeedbackGraphData] = useState([]);

    const userId = localStorage.getItem("userId");
    const userEmail = localStorage.getItem("userEmail");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Call APIs
                const response = await getReviewAnalysisByUserId(userId);
                // Remove ?
                const goals = await getGoalsByUserId(userId);
                const reviews = await getAllReviewsByEmail(userEmail);
                const data = await getGraphData(reviews);

                // Set the refined Feedback Graph data
                setFeedbackGraphData(data);

                // Set the review response for the feedback summary
                setReviewResponse(response);

                setReviewList(reviews);

                // All data retrieved
                setDataAvailable(true);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                //console.log("Fetching finished, setting loading to false");
                setLoading(false);
            }
        };

        if (!hasCalledApi) {
            fetchData()
            setHasCalledApi(true);
        }
    }, [userId, userEmail, hasCalledApi]);

    if (!userId || !userEmail) {
        console.log("User ID or Email is missing from local storage");
        return <div className="insights-loading">User information is missing</div>;
    }

    return (
        <div className="insights-container">
            {loading && (
                <div className="insights-loading">Loading...</div>
            )}

            {!loading && dataAvailable && reviewResponse && (

                <div className="insights-container">
                    <div className="insights-title-container">
                        <h1 className="insights-title">Insights Dashboard</h1>
                        <InsightsDashboardIcon/>
                    </div>

                    {/* First Row - Feedback Summary */}
                    <div className="feedback-summary-row">
                        <div className="feedback-summary-card">
                            <FeedbackSummaryCard reviewResponse={reviewResponse}/>
                        </div>
                    </div>

                    {/* Second Row - Competency Summary and StrengthsGrowthCard */}
                    <div className="second-row">
                        <div className="second-row-left-side">
                            <StrengthsGrowthCard
                                strengths={reviewResponse?.strengths || []}
                                growth_areas={reviewResponse?.growth_areas || []}
                            />
                            <Quote graphData={feedbackGraphData}/>
                        </div>

                        {feedbackGraphData.length > 0 ? (
                            <CompetencySummaryCard competencyData={feedbackGraphData}/>
                        ) : (
                            <div>Generating Competency Summary...</div>
                        )}

                    </div>

                    <div className="third-row">
                        {/* First column: Attributes Card */}
                        <div className="third-row-column-1">
                            <AttributesCard competencyData={feedbackGraphData}/>
                        </div>

                        {/* Second column: Two smaller cards in two rows */}
                        <div className="third-row-column-2">
                            <GrowthPlanCard reviewResponse={reviewList} />
                            <SuggestedResources
                                growth_areas={reviewResponse?.growth_areas || []}
                            />
                        </div>
                    </div>
                    {/* Fourth Row - General Feedback Card */}
                    <div className="fourth-row">
                        <GeneralFeedbackCard graphData={feedbackGraphData}/>
                    </div>

                </div>
            )}

            {!loading && (!dataAvailable || !reviewResponse) && (
                <div>
                    <div className="insights-text">
                        <div className="insights-text-large">Building the best insights for you!</div>
                        <div
                            className="insights-text-small">
                            Your insights will be ready soon. We need just a bit more data (1 goal & 5 reviews or at
                            least 10 reviews) to make sure it’s as helpful as possible. We'll let you know when it's
                            available!
                        </div>
                    </div>
                    <Image/>
                </div>
            )}
        </div>
    );
};

export default Insights;
