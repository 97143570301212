import React, { useState } from "react";
import "../Styles/AskStaffy/ChatComponent.css";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");

  const formatDate = (date) => {
    return date.toLocaleDateString(undefined, {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
  };

  const handleSendMessage = () => {
    if (!currentMessage.trim()) return;

    // Add the user's message to the chat
    setMessages((prev) => [
      ...prev,
      { sender: "user", content: currentMessage, timestamp: new Date() },
    ]);

    // Clear input
    setCurrentMessage("");

    // Simulate bot response
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", content: "I'm here to assist you!", timestamp: new Date() },
      ]);
    }, 1000);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const renderDateSeparator = (message, index) => {
    const previousMessage = index > 0 ? messages[index - 1] : null;
    const isNewDate =
      !previousMessage ||
      new Date(message.timestamp).toDateString() !==
      new Date(previousMessage.timestamp).toDateString();

    if (isNewDate) {
      return (
        <div className="date-separator">
          {formatDate(new Date(message.timestamp))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chat-container">
      <div className="chat-container">
        {/* Chat History */}
        <div className="chat-history">
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              {/* Render date separator if necessary */}
              {renderDateSeparator(message, index)}

              <div
                className={`chat-message ${message.sender === "user" ? "user-message" : "bot-message"
                  }`}
              >
                <div className="message-container">
                  {/* Profile picture */}
                  <img
                    src={
                      message.sender === "user"
                        ? "https://assets.api.uizard.io/api/cdn/stream/068a1949-ca93-4983-ba8f-ad98414e3090.png" //Currently hard-coded image for user
                        : "/logo.png"
                    }
                    alt={`${message.sender} profile`}
                    className="profile-pic"
                  />
                  {/* Message content */}
                  <div className="message-content-container">
                    {/* Name and timestamp */}
                    <div className="message-header">
                      <span className="message-sender">
                        {message.sender === "user" ? "You" : "Staffy"}
                      </span>
                      <span className="message-timestamp">
                        {new Intl.DateTimeFormat(undefined, {
                          hour: 'numeric',
                          minute: '2-digit',
                          hourCycle: 'h12',
                        }).format(new Date(message.timestamp))}
                      </span>
                    </div>
                    <p className="message-content">{message.content}</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Chat Input Area */}
      <div className="chat-input-container">
        {/* Message Input */}
        <textarea
          className="chat-input"
          placeholder="Type your message here..."
          value={currentMessage}
          onChange={(e) => setCurrentMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <hr className="separator-line " />

        <div className="chat-actions">
          <button className="attachment-button">
            <span className="material-symbols-outlined rotated-icon">attach_file</span>
          </button>
          <button className="send-button" onClick={handleSendMessage}>
            <span className="material-symbols-outlined rotated-icon-rev">send</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
