import React, { useCallback, useEffect, useState } from "react";
import { useSelectedOptions } from "./SelectedOptionsProvider";

/**
 * OrdinalRankingQuestion component renders a set of buttons for ordinal ranking questions.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.question - The question object.
 * @param {number} props.questionIndex - The index of the question in the payload array.
 * @param {number} props.displayIndex - The index to display for the question.
 * @param {Array} props.options - The available options for the question.
 * @param {Array} props.payload - The current payload array.
 * @param {function} props.setPayload - The function to update the payload array.
 * @param {function} props.checkQuestionOrder - The function to determine the question order type.
 *
 * @returns {JSX.Element} The OrdinalRankingQuestion component.
 */
const OrdinalRankingQuestion = ({
  question,
  questionIndex,
  displayIndex,
  options,
  payload,
  setPayload,
  checkQuestionOrder,
}) => {
  const { selectedOptions, setSelectedOptions } = useSelectedOptions();
  const [selectedOptionIndexes, setSelectedOptionIndexes] = useState([]);
  const [payloadOptions, setPayloadOptions] = useState([]);

  /**
   * Updates the payload array with the selected options for the ordinal ranking question.
   *
   * @param {Array} payloadOptions - The selected options for the question.
   */
  const ordinalUpdatePayload = (payloadOptions) => {
    if (payload.length <= 0) {
      return;
    }

    if (!payloadOptions[questionIndex]) {
      payloadOptions[questionIndex] = [];
    }
    // Do not add if more than three options selected
    if (payloadOptions[questionIndex].length > 3) return;

    let updatedPayload = [...payload];
    let updatedPayloadElement = updatedPayload[questionIndex];
    let updatedPayloadElementCopy = [...updatedPayloadElement];

    let optionsCopy = [...payloadOptions[questionIndex]];

    switch (checkQuestionOrder(question)) {
      case "order":
        // [0*, 1*, 2*, 3, 4, 5, 6, 7 8]
        for (let i = 0; i < 3; i++) {
          updatedPayloadElementCopy[i] = optionsCopy[i];
        }
        break;
      default:
        break;
    }

    updatedPayload[questionIndex] = [...updatedPayloadElementCopy];
    setPayload(updatedPayload);
  };

  useEffect(() => {
    // API Payload Handler
    // Note: Adding the function to the dependency array causes an infinite render loop.
    ordinalUpdatePayload(payloadOptions);
  }, [payloadOptions]);

  /**
   * Updates the selected options for the question.
   *
   * @param {string} option - The selected option.
   */
  const updatePayloadOptions = (option) => {
    setPayloadOptions((prevSelectedOptions) => {
      const newSelectedOptions = { ...prevSelectedOptions };
      newSelectedOptions[questionIndex] =
        newSelectedOptions[questionIndex] || [];
      if (newSelectedOptions[questionIndex].includes(option)) {
        newSelectedOptions[questionIndex] = newSelectedOptions[
          questionIndex
        ].filter((selectedOption) => selectedOption !== option);
      } else {
        if (newSelectedOptions[questionIndex].length < 3) {  // If option not selected, can add only if <3 selected
          newSelectedOptions[questionIndex].push(option);
        }
      }
      return newSelectedOptions;
    });
  };

  /**
   * Handles the click event for an option button.
   *
   * @param {string} option - The selected option.
   * @param {number} index - The index of the selected option.
   */
  const handleOptionClick = (option, index) => {
    setSelectedOptionIndexes((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(index)) {
        return prevSelectedOptions.filter(
          (optionIndex) => optionIndex !== index
        );
      } else {
         if (prevSelectedOptions.length < 3) {  // If selecting new option, cannot add >3 options.
            return [...prevSelectedOptions, index];
         }
         return prevSelectedOptions;
      }
    });

    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = { ...prevSelectedOptions };
      newSelectedOptions[questionIndex] = newSelectedOptions[questionIndex] || [];

      if (newSelectedOptions[questionIndex].includes(option)) {
        newSelectedOptions[questionIndex] = newSelectedOptions[questionIndex].filter(
            (selectedOption) => selectedOption !== option
        );
      } else {
          if (newSelectedOptions[questionIndex].length < 3) {  // If option not already selected, can add if <3 options.
            newSelectedOptions[questionIndex].push(option);
          }
      }
      return newSelectedOptions;
    });

    updatePayloadOptions(option, index);
  };

  const displayText =
    question.sub_sub_question || question.sub_question || question.question;

  return (
    <div
      className={`${
        displayIndex === null ? "sub-question" : "survey-question"
      }`}
    >
      <p>
        {displayIndex !== null ? `${displayIndex}. ` : ""}
        {displayText}
      </p>
      <div className="survey-button-container">
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleOptionClick(option, index)}
            className={
              selectedOptionIndexes.includes(index)
                ? "answer-selected"
                : "answer-unselected"
            }
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default OrdinalRankingQuestion;
