import React from "react";

/**
 * Card component for rendering a customizable card container.
 *
 * @component
 * @param {Object} props - The props for the Card component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the card.
 * @example
 * return (
 *   <Card>
 *     <p>This is some content inside the card.</p>
 *   </Card>
 * )
 */
const Card = (props) => {
  return <div className="onboarding-card">{props.children}</div>;
};

export default Card;
