import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../apiControllers/authController";
import { getUserData } from "../../apiControllers/userApiController";
import "../Styles/sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    image:
      "https://assets.api.uizard.io/api/cdn/stream/012d78e6-a892-4b09-b86c-1dae217de3bd.png",
  });

  const [hoverText, setHoverText] = useState('');


  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserData();
        // Storing email in localStorage
        localStorage.setItem("userEmail", userData.email_address);
        setUser(userData);
      } catch (error) {
        // handle errors here if needed
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-profile-card">
        <img
          className="sidebar-profile-picture"
          src={
            user.image ||
            "https://assets.api.uizard.io/api/cdn/stream/012d78e6-a892-4b09-b86c-1dae217de3bd.png"
          }
          alt="profile"
        />
        <div className="sidebar-text">
          <span className="sidebar-name">
            {user.first_name} {user.last_name}
          </span>
          <span className="sidebar-email">{user.email_address}</span>
        </div>
      </div>
      <div className="sidebar-menu-list">
        <Link
          to="/dashboard"
          className={`sidebar-menu-item ${activeItem === "/dashboard" ? "active" : ""
            }`}
        >
          <span className="material-symbols-outlined">view_cozy</span>
          <span className="sidebar-menu-label">Dashboard</span>
        </Link>
        <Link
          to="/growth-library"
          className={`sidebar-menu-item ${activeItem === "/growth-library" ? "active" : ""
            }`}
        >
          <span className="material-symbols-outlined">school</span>
          <span className="sidebar-menu-label">Growth Library</span>
        </Link>
        <Link
          to="/goals"
          className={`sidebar-menu-item ${activeItem === "/goals" ? "active" : ""
            }`}
        >
          <span className="material-symbols-outlined">verified</span>
          <span className="sidebar-menu-label">Goals</span>
        </Link>
        <Link
          to="/feedback"
          className={`sidebar-menu-item ${activeItem === "/feedback" ||
              activeItem === "/self-assessment-survey" ||
              activeItem === "/feedback/report" ||
              activeItem === "/self-assessment"
              ? "active"
              : ""
            }`}
        >
          <span className="material-symbols-outlined">group</span>
          <span className="sidebar-menu-label">Feedback</span>
        </Link>
        <Link
          to="/insights"
          className={`sidebar-menu-item ${activeItem === "/insights" ? "active" : ""
            }`}
        >
          <span className="material-symbols-outlined">search_insights</span>
          <span className="sidebar-menu-label">Insights</span>
        </Link>
        <Link
          //to="/ask-staffy"
          className={`sidebar-menu-item ${activeItem === "/ask-staffy" ? "active" : ""
            }`}
          onMouseEnter={() => { setHoverText('Coming Soon') }}
          onMouseLeave={() => { setHoverText('') }}
        >
          {hoverText !== "" ? (
            <>
              <span className="material-symbols-outlined">schedule</span>
              <span className="sidebar-menu-label">{hoverText}</span>
            </>
          ) : (
            <>
              <span className="material-symbols-outlined">psychology_alt</span>
              <span className="sidebar-menu-label">Ask Staffy</span>
            </>
          )}
        </Link>

      </div>
      <div className="sidebar-bottom-list">
        <Link
          to="/account-settings"
          className={`sidebar-menu-item ${activeItem === "/account-settings" ? "active" : ""
            }`}
        >
          <span className="material-symbols-outlined">account_circle</span>
          <span className="sidebar-menu-label">Account</span>
        </Link>
        {/* <Link
          to="/settings"
          className={`sidebar-menu-item ${
            activeItem === "/settings" ? "active" : ""
          }`}
        >
          <span className="material-symbols-outlined">settings</span>
          <span className="sidebar-menu-label">Settings</span>
        </Link> */}
        <Link to="#" className="sidebar-menu-item" onClick={logout}>
          <span className="material-symbols-outlined">logout</span>
          <span className="sidebar-menu-label">Log Out</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
