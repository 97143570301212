import axios from "axios";
import Cookies from "js-cookie";

// Create an axios instance with a base URL from environment variables
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Axios request interceptor to add Authorization header if a token is present in cookies.
 *
 * @param {Object} config - The axios request configuration.
 * @returns {Object} - The modified axios request configuration.
 * @throws {Promise} - Rejects the promise if an error occurs.
 */
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("userToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Axios response interceptor to handle 401 Unauthorized responses.
 *
 * @param {Object} response - The axios response.
 * @returns {Object} - The axios response.
 * @throws {Promise} - Rejects the promise if an error occurs.
 */
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("userToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
