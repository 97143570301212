/**
 * This module is for managing API calls to the server's reflections endpoints.
 */

import api from "./axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const createReflections = async (data) => {
  try {
    const response = await api.post(`${apiUrl}/v1/reflections/single`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReflectionsByGoalId = async (goalId) => {
  try {
    const response = await api.get(`${apiUrl}/v1/reflections/goal/${goalId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateReflections = async (data) => {
  try {
    const response = await api.put(`${apiUrl}/v1/reflections/single`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
