import React, { useEffect, useState } from "react";
import mockData from "./Table/reviewer-mock-data.json";
import TableContainer from "./Table/TableContainer";
import "../Styles/PeerReview.css";
import ReviewerBanner from "./ReviewerBanner";
import { useSearchParams } from "react-router-dom";
import { getAllReviewerReviewsByEmail } from "../../apiControllers/reviewApiController";

/**
 * Renders the PeerReview component which displays the reviewer's reviews.
 *
 * @return {JSX.Element} The rendered PeerReview component.
 */
const PeerReview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    let getApiData = async () => {
      let reviewer = searchParams.get("reviewer");
      let response = await getAllReviewerReviewsByEmail(reviewer);
      setData(response);
    };

    getApiData();
  }, []);
  return (
    <>
      <ReviewerBanner></ReviewerBanner>
      <div className="reviewer-bg">
        <div className="reviewer-table-img-container">
          <img
            src="https://assets.api.uizard.io/api/cdn/stream/4cf508e1-8415-4f52-a4dd-bdc5533986c3.png"
            alt="request-img"
          />
        </div>
        <div className="reviewer-table">
          {data && <TableContainer data={data}></TableContainer>}
        </div>
      </div>
    </>
  );
};

export default PeerReview;
