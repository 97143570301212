import React from "react";
import "../Styles/FBReport.css";

/**
 * Renders the intro image component for the FBReport.
 *
 * @return {JSX.Element} The JSX element representing the FBReport intro image.
 */
const FBReportIntroImage = () => {
  return (
    <>
      <div className="fb-report-intro-image-container">
        <img
          src="https://assets.api.uizard.io/api/cdn/stream/334bb55b-8cce-40cb-8c79-c4204245586a.png"
          alt="intro"
        />
      </div>
    </>
  );
};

export default FBReportIntroImage;
