import React from "react";
import FBRAGeneral from "./FBRAGeneral";
import FBRAGoals from "./FBRAGoals";

/**
 * Renders the FBReportAnalysis component.
 *
 * @param {Object} props - The props object containing the following property:
 *   - generalData: The general data for the analysis.
 * @return {JSX.Element} The rendered FBReportAnalysis component.
 */
const FBReportAnalysis = ({ generalData }) => {
  return (
    <>
      <div className="fb-report-analysis-container">
        <FBRAGeneral generalData={generalData}></FBRAGeneral>
        <FBRAGoals generalData={generalData}></FBRAGoals>
      </div>
    </>
  );
};

export default FBReportAnalysis;
