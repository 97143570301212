import React from "react";
import InputField from "../../OnBoarding/InputField.jsx";
import Select from "../../OnBoarding/Select.jsx";

/**
 * ReviewRequestRow component renders a row for requesting a review with input fields for name, email, and relationship.
 *
 * @param {number} index - The index of the current review request row.
 * @param {Object} reference - The reference object containing name, email, and relationship.
 * @param {function} handleInputChange - Function to handle input changes.
 * @param {function} handleRemoveClick - Function to handle removing the current row.
 * @param {Array} relationshipOptions - The options for the relationship select dropdown.
 * @param {boolean} showRemoveButton - Flag to show or hide the remove button.
 * @param {string} errorMessage - The error message to be displayed.
 * @returns {JSX.Element} The ReviewRequestRow component.
 */
const ReviewRequestRow = ({ index, reference, handleInputChange, handleRemoveClick, relationshipOptions, showRemoveButton, errorMessage }) => (
  <>
  <div className="review-request-row">
    <InputField
      value={reference.name}
      onChange={(e) => handleInputChange(index, "name", e.target.value)}
      placeholder="First and Last Name"
    />
    <InputField
      value={reference.email}
      onChange={(e) => handleInputChange(index, "email", e.target.value)}
      placeholder="Email"
    />
    <Select
      name="relationship"
      value={reference.relationship}
      onChange={(e) => handleInputChange(index, "relationship", e.target.value)}
      options={relationshipOptions}
      defaultOption="Select Relationship"
    />
    {showRemoveButton && (
      <button className="review-request-remove" onClick={() => handleRemoveClick(index)}>×</button>
    )}
    </div>
    {errorMessage && (
      <div className="error-message">{errorMessage}</div>
    )}
  </>
);

export default ReviewRequestRow;