/**
 * SecurityPrivacySettings Component
 *
 * This component renders the security and privacy settings page, allowing the user to change their password.
 * It includes form validation to ensure all fields are filled out correctly and that the new password meets security requirements.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered SecurityPrivacySettings component.
 *
 * @example
 * return <SecurityPrivacySettings />;
 */

import React, { useState } from "react";
import "../Styles/SecurityPrivacy.css";
import HorizontalDivider from "./HorizontalDivider";
import { changePassword } from "../../apiControllers/userApiController";

const SecurityPrivacySettings = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [emptyFields, setEmptyFields] = useState([]);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength && hasLetter && hasNumber && hasSpecialChar
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = [];
    if (!currentPassword) fields.push("currentPassword");
    if (!newPassword) fields.push("newPassword");
    if (!confirmPassword) fields.push("confirmPassword");

    if (fields.length > 0) {
      setEmptyFields(fields);
      setError("All fields must be filled out.");
      setSuccess("");
      return;
    } else {
      setEmptyFields([]);
    }
    if (currentPassword === newPassword) {
      setError("Current password and new password cannot be the same.");
      setSuccess("");
      return;
    }
    if (!validatePassword(newPassword)) {
      setError(
        "New password must be at least 8 characters long and contain at least one letter, one number, and one special character."
      );
      setSuccess("");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirm new password do not match.");
      setSuccess("");
      return;
    }

    try {
      await changePassword({
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      setError("");
      setSuccess("Password changed successfully!");
    } catch (error) {
      setError("Error changing password: " + error.message);
      setSuccess("");
    }
  };

  return (
    <div className="security-privacy-settings">
      <HorizontalDivider />
      <h2>Security & Privacy Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="security-privacy-form-group">
          <label
            className={`security-privacy-form-label ${
              emptyFields.includes("currentPassword") ? "label-error" : ""
            }`}
          >
            Current Password*
          </label>
          <input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="security-privacy-form-group">
          <label
            className={`security-privacy-form-label ${
              emptyFields.includes("newPassword") ? "label-error" : ""
            }`}
          >
            New Password*
          </label>
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="security-privacy-form-group">
          <label
            className={`security-privacy-form-label ${
              emptyFields.includes("confirmPassword") ? "label-error" : ""
            }`}
          >
            Confirm New Password*
          </label>
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <button className="security-privacy-button" type="submit">
          Change Password
        </button>
      </form>
    </div>
  );
};

export default SecurityPrivacySettings;
