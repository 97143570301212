import React from "react";
import "../Styles/FBReport.css";
import FBReportIntroImage from "./FBReportIntroImage";
import FBReportTitle from "./FBReportTitle";
import { toTitleCase } from "../../apiControllers/reviewApiController";

/**
 * Renders the intro component for the FBReport.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.elementData - The data for the FBReport element.
 * @param {Object} props.elementData.review - The review object.
 * @param {string} props.elementData.review.role - The role of the reviewer.
 * @param {string} props.elementData.review.relationship - The relationship of the reviewer.
 * @param {string} props.elementData.ai.summary - The summary provided by the AI.
 * @return {JSX.Element} The JSX element representing the FBReport intro.
 */
const FBReportIntro = ({ elementData }) => {
  return (
    <>
      <div className="fb-report-intro">
        <div className="fb-report-intro-text">
          <FBReportTitle elementData={elementData}></FBReportTitle>
          <div className="fb-report-reviewer-info">
            <span>Role: {elementData.review.role} </span>

            <span>
              Relationship: {toTitleCase(elementData.review.relationship)}
            </span>
          </div>

          <div className="fb-report-reviewer-intro">
            <span>{elementData.ai.summary}</span>
          </div>
        </div>
        <FBReportIntroImage></FBReportIntroImage>
      </div>
    </>
  );
};

export default FBReportIntro;
