import React from "react";
import ReactDOM from "react-dom";

const GoalModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="goal-modal-overlay">
      <div className="goal-close-button-wrapper">
        <button onClick={onClose} className="goal-modal-close-button">
          Close
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default GoalModal;
