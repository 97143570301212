import React from 'react';
import '../Styles/Insights/FeedbackSummary.css';

const FeedbackSummaryCard = ({ reviewResponse }) => {

  return (
    <div>
      <div className="insights-card-text">
        <div className="insights-text-ai-large">Feedback Summary</div>
        {reviewResponse?.summary ? (
          <div className="insights-text-ai-small">
            {reviewResponse.summary.replace(/(?:\r\n|\r|\n)/g, "<br />")}
          </div>
        ) : (
          <p className="insights-text-ai-small">No summary available.</p>
        )}
      </div>
    </div>
  );
};

export default FeedbackSummaryCard;
