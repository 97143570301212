import React from "react";
import "../Styles/SelfAssessment.css";
import "../Styles/SurveyForm.css";
import ReflectiveEvaluation from "./ReflectiveEvaluation";
import ImageContainer from "./Assets/ImageContainer";

/**
 * SelfAssessmentInProgress component renders the self-assessment page which includes
 * an image and the reflective evaluation form.
 *
 * @returns {JSX.Element} The SelfAssessmentInProgress component.
 */
const SelfAssessmentInProgress = () => {
  return (
    <div className="parent">
      <div className="container">
        <div className="self-assessment-container">
          <ImageContainer />
          <ReflectiveEvaluation type={"self"} />
        </div>
      </div>
    </div>
  );
};

export default SelfAssessmentInProgress;
