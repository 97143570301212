import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import GoalsCreation from "./GoalsCreation";
import GoalsImage from "./GoalsImage";
import GoalsTracking from "./GoalsTracking";
import Milestones from "./GoalModal/GoalModalContent";
import GoalModal from "./GoalModal/GoalModal";

const GoalsBackground = () => {
  const [user, setUser] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const { goal, openModal } = location.state || {};
  
  useEffect(() => {
    console.log("Goal Object:", goal); // Log the entire goal object
    console.log("Goal ID:", goal?.goal_id); // Log goal ID if available
    console.log("Goal Title:", goal?.title); // Log goal title if available
    // Get user id from local storage
    const user_id = localStorage.getItem("userId");
    setUser(user_id);

    if (openModal) {
      console.log("Opening modal for goal:", goal?.goal_id);
      setModalOpen(true);
    }
  }, [openModal, goal ]);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {user && (
        <div className="goals-bg">
          <div className="goals-content-container">
            <GoalsImage />
            <h1 className="goals-header">Goal Creation</h1>
            <GoalsCreation user={user} />
            <h1 className="goals-header">Goal Tracking</h1>
            <GoalsTracking user={user} />

            {/* Modal for goal-specific details */}
            <GoalModal isOpen={isModalOpen} onClose={closeModal}>
              {/* Pass the goalId to the modal for specific goal actions */}
              <Milestones goalId={goal?.goal_id}/>
            </GoalModal>
          </div>
        </div>
      )}
    </>
  );
};

export default GoalsBackground;
