import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  resetPasswordWithToken,
  validateToken,
} from "../../apiControllers/userApiController";

/**
 * Renders the page from the password reset email link.
 *
 * @return {JSX.Element} The password reset form component.
 */
const PasswordResetEmailLink = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const navigate = useNavigate();

  const handleOnPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOnConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    const newErrors = [...errors];
    console.log("pw", password);
    console.log("pwc", confirmPassword);

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password === confirmPassword && passwordRegex.test(password)) {
      const resetToken = searchParams.get("reset_token");

      console.log(resetToken);
      console.log(password);

      try {
        let response = await resetPasswordWithToken({
          token: resetToken,
          password: password,
        });
        console.log(response);
        navigate("/reset-password/email-link/success");
      } catch (error) {
        newErrors[0] = "Server Error";
        setErrors(newErrors);
      }

      // Else case on invalid password input
    } else if (!passwordRegex.test(password)) {
      newErrors[0] =
        "Password must be at least 8 characters long, contain at least one letter, one number, and one special character";
    } else {
      newErrors[0] = "Passwords do not match";
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    const apiCall = async () => {
      try {
        let response = await validateToken(searchParams.get("reset_token"));
        console.log(response);
        setTokenIsValid(true);
      } catch (error) {
        setTokenIsValid(false);
      }
    };

    apiCall();
  }, []);

  return (
    <>
      {!tokenIsValid && (
        <div className="password-reset-container">
          <div className="password-reset-form-box">
            <span className="password-reset-header">Error</span>
            <form
              className="password-reset-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="password-reset-message">
                <span>Your token is expired/not valid</span>
              </div>
              <div className="password-reset-link-login-link">
                <Link className="password-reset-login-link-text" to="/login">
                  <span>Back to login</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
      {tokenIsValid && (
        <div className="password-reset-container">
          <div className="password-reset-form-box">
            <span className="password-reset-header">Password reset</span>
            <form
              className="password-reset-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="password-reset-input-field"
                onChange={handleOnPasswordChange}
              />

              <input
                type="password"
                name="password"
                placeholder="Confirm password"
                className="password-reset-input-field"
                onChange={handleOnConfirmPasswordChange}
              />

              {errors[0] && (
                <p className="password-reset-error-text">{errors[0]}</p>
              )}

              <div className="password-reset-button-group">
                <button
                  type="button"
                  className="password-reset-btn"
                  onClick={handleSubmit}
                >
                  Reset password
                </button>
              </div>

              <div className="password-reset-login-link">
                <Link className="password-reset-login-link-text" to="/login">
                  <span>Back to login</span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordResetEmailLink;
