// Configuration of the table's columns
const COLUMNS = [
  { Header: "Date Sent", accessor: "dateSent" },
  { Header: "Name", accessor: "name" },
  { Header: "Company", accessor: "company" },
  { Header: "Email", accessor: "email" },
  { Header: "Status", accessor: "status" },
];

export default COLUMNS;
