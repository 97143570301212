import React, { useRef } from "react";

const styles = {
  Container: {
    position: "relative",
    width: "102px",
    height: "101px",
  },
  ImageContainer: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    border: "1px solid #ededef",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
    backgroundImage: "url(./image.png)",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  Button: {
    position: "absolute",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "64px",
    left: "71px",
    width: "31px",
    height: "30px",
    border: "1px solid #ededef",
    borderRadius: "50%",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.12)",
    color: "#7b7b7b",
    backgroundColor: "#ffffff",
    outline: "none",
  },
  Icon: {
    color: "#7b7b7b",
    fill: "#7b7b7b",
    width: "14px",
    height: "14px",
    fontSize: "14px",
  },
  FileInput: {
    display: "none",
  },
};

const IconComponent = () => (
  <svg style={styles.Icon} viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z"></path>
  </svg>
);

const ProfileImage = ({ image, onImageChange }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={styles.Container}>
      <div
        style={{ ...styles.ImageContainer, backgroundImage: `url(${image})` }}
      ></div>
      <button style={styles.Button} onClick={handleButtonClick}>
        <IconComponent />
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={styles.FileInput}
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
};

export default ProfileImage;
