import React from 'react';
import '../Styles/Insights/StrengthsGrowth.css';


const StrengthsGrowthCard = ({ strengths, growth_areas }) => {

  const formatStrengths = (strengths) => {
    if (!strengths || strengths.length === 0) {
      return "No strengths data available.";
    }
    return strengths.map((strength, idx) => (
      <div key={idx} className="strengths-item">
        {strength.keyword}
      </div>
    ));
  };

  const formatGrowthAreas = (growth_areas) => {
    if (!growth_areas || growth_areas.length === 0) {
      return "No growth areas data available.";
    }
    return growth_areas.map((area, idx) => (
      <div key={idx} className="growth-item">
        {area.keyword}
      </div>
    ));
  };

  return (
    <div className="strengths-growth-card">
      <div className="strengths-growth-inner-container">
        {/* Strengths Section */}
        <div className="strengths-card-inner">
          <div className="strengths-title">Strengths</div>
          <div className="strengths-list">{formatStrengths(strengths)}</div>
        </div>

        {/* Growth Areas Section */}
        <div className="growth-card-inner">
          <div className="growth-title">Growth Areas</div>
          <div className="growth-list">{formatGrowthAreas(growth_areas)}</div>
        </div>
      </div>
    </div>
  );
};

export default StrengthsGrowthCard;
