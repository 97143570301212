import React from "react";
import SingleCard from "./SingleCard";
import "../../Styles/GrowthLibrary.css";
import strings from "../assets/strings.json";

/**
 * CardsContainer component renders a section of cards and a "View More" button.
 *
 * @param {Object[]} cardData - Array of card data objects.
 * @param {number} visibleCards - Number of cards to be displayed initially.
 * @param {function} onViewMore - Function to handle the "View More" button click.
 *
 * @returns {JSX.Element} The CardsContainer component.
 */
const CardsContainer = ({ 
  cardData, 
  visibleCards, 
  onViewMore, 
  onMarkUnwanted,
  tempMarkedCards,
  onUndo,
  onSubmitFeedback
}) => {
  return (
    <div>
      <div className="growth-library-card-section">
        {cardData.slice(0, visibleCards).map((card) => (
          <SingleCard
            key={card.course_id} // Use course_id for unique key
            title={card.title}
            price={card.price}
            link={card.link}
            source={card.source}
            imgSrc={card.image_url}
            courseId={card.course_id}
            onMarkUnwanted={onMarkUnwanted}
            isMarked={tempMarkedCards.has(card.course_id)}
            onUndo={onUndo}
            onSubmitFeedback={onSubmitFeedback}
          />
        ))}
      </div>
      {visibleCards < cardData.length && (
        <div className="growth-view-more-container">
          <button className="growth-view-more-button" onClick={onViewMore}>
            {strings.buttonLabel}
          </button>
        </div>
      )}
    </div>
  );
};

export default CardsContainer;
