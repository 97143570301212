import React from 'react';
import image from "./self-assessment-image.png";

/**
 * ImageContainer component renders an image for the self-assessment.
 *
 * @returns {JSX.Element} The ImageContainer component.
 */
const ImageContainer = () => {
  return (
    <div className="self-assessment-img-container-comp">
        <img src={image} alt="Self-Assessment" />
    </div>
  );
};

export default ImageContainer;