import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReviewerBanner from "./ReviewerBanner";
const text = require("./instructions.json");

/**
 * PeerFeedbackInstructions component handles the creation of a self-assessment review.
 *
 * @returns {JSX.Element} The PeerFeedbackInstructions component.
 */
const PeerFeedbackInstructions = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [textData, setTextData] = useState(null);

  const startFeedback = () => {
    let userName = searchParams.get("reviewee");
    let reviewId = searchParams.get("reviewId");
    navigate(`/peer-review/feedback?reviewId=${reviewId}&reviewee=${userName}`);
  };

  const updateText = (userName) => {
    let textCopy = JSON.parse(JSON.stringify(text));
    console.log("Updating...", textCopy);
    console.log("og", text);
    // Update the textCopy to set the data for the page components
    textCopy.introduction = text.introduction.replace(
      "[User's name]",
      userName
    );
    textCopy.sections[1].content = text.sections[1].content.replace(
      "[User's name]",
      userName
    );

    setTextData(textCopy);
    setDataLoaded(true);
  };

  useEffect(() => {
    let userName = searchParams.get("reviewee");

    if (!dataLoaded) {
      updateText(userName);
    }
  }, [searchParams, dataLoaded]);
  return (
    <>
      {dataLoaded && (
        <>
          {" "}
          <ReviewerBanner />
          <div className="peer-feedback-instructions-container">
            <div className="peer-feedback-instructions-image-container">
              <img
                src="https://assets.api.uizard.io/api/cdn/stream/f5128f45-4c13-4c35-b405-141541435108.png"
                alt="peer-feedback"
              />
            </div>
            <div className="instructions-container">
              <div className="self-assessment-alignment">
                <div className="self-assessment-instructions">
                  <h1 className="instructions-title">{textData.title}:</h1>
                  <p>{textData.introduction}</p>
                  {textData.sections.map((section, index) => (
                    <div key={index} className="instructions-text">
                      <p>
                        {section.title}: {section.content}
                      </p>
                      {section.scale && (
                        <ul className="self-assessment-rating-list">
                          {section.scale.map((item, idx) => (
                            <li key={idx}>
                              {item.rating} - {item.description}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                  <p className="instructions-text">{textData.closing}</p>
                </div>

                <div className="self-assessment-buttons">
                  <button
                    className="self-assessment-btn btn-secondary"
                    onClick={startFeedback}
                  >
                    {textData.button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PeerFeedbackInstructions;
