import React from "react";
import "../Styles/FBReport.css";

/**
 * Renders the title component for the FBReport.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.elementData - The data for the FBReport element.
 * @param {Object} props.elementData.review - The review object.
 * @param {string} props.elementData.review.reviewer - The name of the reviewer.
 * @returns {JSX.Element} - The rendered FBReportTitle component.
 */
const FBReportTitle = ({ elementData }) => {
  return (
    <>
      <div className="fb-report-title-container">
        <div className="fb-report-title">
          {elementData.review.reviewer + "'s Feedback"}
        </div>
      </div>
    </>
  );
};

export default FBReportTitle;
