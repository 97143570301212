import React from "react";

/**
 * Renders the FBRAGeneral component, which displays general feedback and observations for strengths, growth areas, and proposed growth plan.
 *
 * @param {Object} props - The props object containing the following property:
 *   - generalData: The general data for the analysis.
 * @return {JSX.Element} The rendered FBRAGeneral component.
 */
const FBRAGeneral = ({ generalData }) => {
  return (
    <>
      <div className="fb-report-analysis-general-container">
        {/* Strengths */}
        <div className="fb-report-analysis-general-label">
          Strengths - General Feedback and Observations
        </div>

        <div className="fb-report-analysis-general-content">
          {generalData.observations.strengths.map((element, index) => {
            return (
              <>
                <span>{element}</span>
                <br />
                <br />
              </>
            );
          })}
        </div>
      </div>
      {/* Growth Areas */}
      <div className="fb-report-analysis-general-container">
        <div className="fb-report-analysis-general-label">
          Growth Areas - General Feedback and Observations
        </div>
        <div className="fb-report-analysis-general-content">
          {generalData.observations.growth_areas.map((element, index) => {
            return (
              <>
                <span>{element}</span>
                <br />
                <br />
              </>
            );
          })}
        </div>
      </div>
      {/* Proposed Growth Plan */}
      {generalData.ai.proposed_growth_plan && (
        <>
          {" "}
          <div className="fb-report-analysis-general-container">
            <div className="fb-report-analysis-general-label">
              Proposed Growth Plan
            </div>

            {generalData.ai.proposed_growth_plan.details}
            <br />
            <br />
            {generalData.ai.proposed_growth_plan.summary}
            <br />
            <br />
            {generalData.ai.goals.map((goal, index) => (
              <React.Fragment key={index}>
                <div>
                  {goal.title}: {goal.summary}
                </div>
                <br />
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default FBRAGeneral;
