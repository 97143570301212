import React from "react";
import "../Styles/SelfAssessment.css";
import SelfAssessmentContainer from "./SelfAssessmentContainer";
import ImageContainer from "./Assets/ImageContainer";

/**
 * SelfAssessment component renders the self-assessment page which includes
 * an image and the self-assessment form.
 *
 * @returns {JSX.Element} The SelfAssessment component.
 */
const SelfAssessment = () => {
  return (
    <div className="parent">
      <div className="container">
        <div className="self-assessment-container">
          <ImageContainer />
          <SelfAssessmentContainer />
        </div>
      </div>
    </div>
  );
};

export default SelfAssessment;
