import React, { createContext, useContext, useState } from 'react';

// Create a context to store selected options
const SelectedOptionsContext = createContext();

/**
 * Custom hook to use the selected options context.
 *
 * @returns {Object} The selected options context.
 */
export const useSelectedOptions = () => useContext(SelectedOptionsContext);

/**
 * SelectedOptionsProvider component provides the selected options context to its children.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child components that will have access to the selected options context.
 *
 * @returns {JSX.Element} The SelectedOptionsProvider component.
 */
export const SelectedOptionsProvider = ({ children }) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  return (
    <SelectedOptionsContext.Provider value={{ selectedOptions, setSelectedOptions }}>
      {children}
    </SelectedOptionsContext.Provider>
  );
};