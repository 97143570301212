import React from "react";

/**
 * Renders the FBRCTopThree component.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - topThree: An object with two properties:
 *     - strengths: An object with an array of ordinals, each containing an option and a likert_number.
 *     - growth_areas: An object with an array of ordinals, each containing an option and a likert_number.
 * @return {JSX.Element} The rendered FBRCTopThree component.
 */
const FBRCTopThree = ({ topThree }) => {
  return (
    <>
      <div className="fb-report-competency-top-three-container">
        {/* Strengths */}
        <div className="fb-report-competency-top-three-categories-container">
          <div className="fb-report-competency-top-three-title">Strengths</div>
          <div className="fb-report-competency-top-three-strengths">
            <div className="fb-report-competency-attributes">
              {topThree.strengths.ordinals.map((element, index) => {
                return (
                  <>
                    <div className="fb-report-competency-attribute">
                      <div className="fb-report-competency-strength-name">
                        {element.option}
                      </div>
                      <div className="fb-report-competency-level">
                        {element.likert_number}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {/* Growth Areas */}
        <div className="fb-report-competency-top-three-categories-container">
          <div className="fb-report-competency-top-three-title">
            Growth Areas
          </div>
          <div className="fb-report-competency-top-three-growth-areas">
            <div className="fb-report-competency-attributes">
              {topThree.growth_areas.ordinals.map((element, index) => {
                return (
                  <>
                    <div className="fb-report-competency-attribute">
                      <div className="fb-report-competency-growth-area-name">
                        {element.option}
                      </div>
                      <div className="fb-report-competency-level">
                        {element.likert_number}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FBRCTopThree;
