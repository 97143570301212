import React from "react";
import "../../Styles/FBReport.css";
import FBRCTopThree from "./FBRCTopThree";
import FBRCOverlooked from "./FBRCOverlooked";

/**
 * Renders the FBReportCompetency component.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - overlooked: The data for the overlooked component.
 *   - topThree: The data for the topThree component.
 * @return {JSX.Element} The rendered FBReportCompetency component.
 */
const FBReportCompetency = ({ overlooked, topThree }) => {
  return (
    <>
      <div className="fb-report-competency-container">
        <FBRCTopThree topThree={topThree}></FBRCTopThree>
        <FBRCOverlooked overlooked={overlooked}></FBRCOverlooked>
      </div>
    </>
  );
};

export default FBReportCompetency;
