import React from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "../Styles/FBReport.css";
import FBReportBackground from "./FBReportBackground";

/**
 * Renders the FBReport component.
 *
 * @return {JSX.Element} The JSX element representing the FBReport component.
 */
const FBReport = () => {
  const generatePDF = () => {
   const element = document.getElementById('pdf-content');

   html2canvas(element, { scale: 1, useCORS: true }).then(canvas => {
     const imgData = canvas.toDataURL('image/jpg');
     const pdf = new jsPDF('p', 'in', 'letter');
     const imgWidth = 11.3;
     const imgHeight = canvas.height * imgWidth / canvas.width;
     const pdfPageHeight = pdf.internal.pageSize.height;
     let heightLeft = imgHeight;
     let position = 0;

     pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');  // Adds 1st page.
     heightLeft -= pdfPageHeight;

     while (heightLeft > 0) {  // Adds remaining pages.
       position = heightLeft - imgHeight;
       pdf.addPage();
       pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
       heightLeft -= pdfPageHeight;
     }

     pdf.save('Ofstaff-feedback-report.pdf');
   });
  };

  return (
      <>
        <button onClick={generatePDF}>Download PDF</button>
        <div id="pdf-content">
          <FBReportBackground />
        </div>
      </>
    );
};

export default FBReport;
