import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import COLUMNS from "./reviewer-columns";
import Pagination from "../../Feedback/feedback-components/Pagination";

/**
 * Renders a table component for displaying reviewer data.
 *
 * @param {Object} props - The props object containing the data for the table.
 * @param {Array} props.data - The array of data to be displayed in the table.
 * @return {JSX.Element} The rendered table component.
 */
function ReviewerTable({ data = [] }) {
  const emailInLocalStorage = localStorage.getItem("userEmail");

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        id: "survey",
        Header: "Feedback Survey",
        accessor: "status",
        Cell: ({ value, row }) => {
          // Decide which icon to render based on the status
          switch (value) {
            case "Completed":
              return (
                <div className="reviewer-survey-requested">
                  <svg viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zm-9.18-6.95L7.4 14.46 10.94 18l5.66-5.66-1.41-1.41-4.24 4.24-2.13-2.12z"></path>
                  </svg>
                </div>
              );
            case "Requested":
              return (
                <Link
                  to={`/peer-review/instructions?reviewId=${row.original.reviewId}&reviewee=${row.original.name}`}
                >
                  <div className="reviewer-survey-requested">
                    <svg viewBox="0 0 24 24">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M15 22H6c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h8l6 6v6h-2V9h-5V4H6v16h9v2zm4-.34v-2.24l2.95 2.95 1.41-1.41L20.41 18h2.24v-2H17v5.66h2z"></path>
                    </svg>
                  </div>
                </Link>
              );
            default:
              return null;
          }
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="reviewer-tr tr"
            >
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="reviewer-th th"
                >
                  {column.render("Header")}
                  {column.id !== "selection" && (
                    <span className="review-sort-icons">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "arrow_upward"
                          : "arrow_downward"
                        : ""}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            // Check if the email matches the one in localStorage
            const isEmailMatch = row.original.email === emailInLocalStorage;
            return (
              <tr
                key={row.id}
                {...row.getRowProps()}
                // Apply the bold-row class if emails match
                className={`reviewer-tr tr ${
                  isEmailMatch ? "feedback-bold-row" : ""
                }`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      key={cell.column.id}
                      {...cell.getCellProps()}
                      className={`reviewer-td td ${
                        cell.column.id === "report" ? "report-cell" : ""
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        setPageSize={setPageSize}
        totalRows={data.length}
      />
    </>
  );
}

export default ReviewerTable;
