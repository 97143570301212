import React from "react";
import "../Styles/Insights/Insights.css";
import "../Styles/Insights/SuggestedResources.css";

/**
 * SuggestedResourcesCard Component
 * Displays the matched resources for each growth area in a single card.
 */
const SuggestedResourcesCard = ({ resourcesByCategory }) => {
  const seenLinks = new Set();  // To track the links already displayed

  return (
    <div className="suggested-resources-card">
      <h2 className="suggested-resources-title">Growth Library Picks</h2>  {/* Title Added */}
      <div className="suggested-resources-content">
        {/* Loop through each category (growth area) and its resources */}
        {Object.entries(resourcesByCategory).map(([category, resources], index) => (
          <div key={index} className="suggested-resources-category-block">
            {/* Category Title */}
            <h3 className="suggested-resources-category">{category}</h3>
            
            {/* Display each resource for this category, avoiding duplicates */}
            {resources.length > 0 ? (
              resources.map((resource, i) => {
                if (seenLinks.has(resource.link)) {
                  return null;  // Skip if this resource has already been displayed
                }
                seenLinks.add(resource.link);  // Add the resource link to the set
                
                return (
                  <div key={i} className="suggested-resource-item">
                    <strong>{resource.source === "Spotify" ? "Podcast" : "Course"}:</strong> {resource.title}
                    <br />
                    Link for more information: 
                    <a href={resource.link} target="_blank" rel="noopener noreferrer">
                      {resource.link}
                    </a>
                  </div>
                );
              })
            ) : (
              <p>No resources available for this category.</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestedResourcesCard;
