import React from "react";

/**
 * Renders the FBRAGoals component.
 *
 * @param {Object} props - The props object containing the following property:
 *   - generalData: The general data for the goals.
 * @return {JSX.Element} The rendered FBRAGoals component.
 */
const FBRAGoals = ({ generalData }) => {
  const removeInts = (string) => {
    return string.replace(/\d+\.\s*/g, "");
  };

  return (
    <>
      <div className="fb-report-analysis-goals-container">
        {generalData.ai.goals.map((element, index) => {
          // Check if the current index is even
          if (index % 2 === 0) {
            // If it's even, start a new row
            return (
              <div className="fb-report-analysis-goals-row" key={index}>
                {/* Add the first card */}
                <div className="fb-report-analysis-goals-card">
                  <div className="fb-report-analysis-goals-topic">
                    {element.title} Goals
                  </div>
                  <div className="fb-report-analysis-goals-content">
                    <span>Goal: {element.goal}</span>
                    <br />
                    <br />
                    <span>
                      Action Steps: {removeInts(element.action_steps)}
                    </span>
                  </div>
                </div>
                {/* Check if there's a next element */}
                {index + 1 < generalData.ai.goals.length && (
                  // Add the second card if it exists
                  <div className="fb-report-analysis-goals-card">
                    <div className="fb-report-analysis-goals-topic">
                      {generalData.ai.goals[index + 1].title} Goals
                    </div>
                    <div className="fb-report-analysis-goals-content">
                      <span>Goal: {generalData.ai.goals[index + 1].goal}</span>
                      <br />
                      <br />
                      <span>
                        Action Steps:{" "}
                        {removeInts(
                          generalData.ai.goals[index + 1].action_steps
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            // If the index is odd, return null (no new row)
            return null;
          }
        })}
      </div>
    </>
  );
};

export default FBRAGoals;
