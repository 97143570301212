import React from "react";

/**
 * ReviewRequestCard component serves as a container for review request elements.
 *
 * @param {Object} props - The props passed to the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the card.
 * @returns {JSX.Element} The ReviewRequestCard component.
 */
const ReviewRequestCard = (props) => {
  return <div className="review-request-card">{props.children}</div>;
};

export default ReviewRequestCard;