import React, { useState } from "react";
import "../../../Styles/NotesSection.css";

const NotesSection = () => {
    const [newNote, setNewNote] = useState("");
    const [notes, setNotes] = useState([
        {
            note_id: "1",
            content: "Test note 1!",
            created_at: new Date().toISOString(),
        },
        {
            note_id: "2",
            content: "Test note 2. ABCDE wfwefwemweklf wfwefwejfoiwe fweoifjwejf weojfijlsdjfoijs",
            created_at: new Date().toISOString(),
        },
    ]);

    console.log("NotesSection rendered. Notes:", notes);

    const handleChange = (e) => {
        setNewNote(e.target.value);
        console.log("New note content:", e.target.value); // Debug log
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newNoteObj = {
            note_id: String(notes.length + 1),
            content: newNote,
            created_at: new Date().toISOString(),
        };

        setNotes((prevNotes) => [...prevNotes, newNoteObj]);
        console.log("New note added:", newNoteObj); // Debug log

        setNewNote("");
    };

    return (
        <div className="goal-notes-container">
            <div className="notes-section-header">
                <span className="material-symbols-outlined custom-symbol-size">
                    short_text
                </span>
                <span className="notes-title">Notes</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <textarea
                        id="new_note"
                        name="new_note"
                        value={newNote}
                        placeholder="Share your thoughts, progress, or challenges as you work towards your goals"
                        onChange={handleChange}
                        required
                        className="notes-textarea"
                    />
                </div>
                <div className="submit-container">
                    <button type="submit" className="submit-button">
                        Add a Note
                    </button>
                </div>
            </form>

            <hr className="separator-line" />

            <div className="saved-notes-container">
                {notes.length === 0 ? (
                    <p>No notes added yet.</p>
                ) : (
                    <div>
                        {notes.map((note) => (
                            <div className="note-entry" key={note.note_id}>
                                <p className="note-content">{note.content}</p>
                                <span className="note-date">
                                    {new Date(note.created_at).toLocaleDateString()} at 8:45pm PT
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotesSection;
